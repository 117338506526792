import React, { useEffect, useState } from "react";
import { useAuthState, useAuthDispatch, logout } from "../../Context";
import { useParams } from "react-router-dom";
import Layout from "../../Components/Layout/Layout";
import ROOT_URL from "../../Config/rootUrl";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import Styles from "./QuizDetail.module.scss";
import { useTranslation } from "react-i18next";

const QuizDetail = () => {
  const { quizId } = useParams();
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();
  const [quiz, setQuiz] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    const fetchQuiz = async () => {
      try {
        const token = userDetails?.token;
        if (!token) return;

        const response = await fetch(
          `${ROOT_URL}/api/v1/quiz/result/${quizId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        if (data?.error?.name === "TokenExpiredError") {
          logout(dispatch);
        }

        setQuiz(data);
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch quiz ");
        setLoading(false);
      }
    };

    fetchQuiz();
  }, [quizId, dispatch, userDetails?.token]);

  if (loading)
    return (
      <Layout headline={decodeURIComponent(quizId.slice(0, -14))}>
        <LoadingSpinner />
      </Layout>
    );

  if (error)
    return (
      <Layout headline={decodeURIComponent(quizId.slice(0, -14))}>
        <p style={{ textAlign: "center" }}>{error}</p>
      </Layout>
    );

  if (!quiz)
    return (
      <Layout headline={decodeURIComponent(quizId.slice(0, -14))}>
        <p style={{ textAlign: "center" }}>No quiz found</p>
      </Layout>
    );

  return (
    <Layout headline={decodeURIComponent(quizId.slice(0, -14))} subheadline={new Intl.DateTimeFormat(i18n.resolvedLanguage, {
        weekday: "long",
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      }).format(new Date(quiz.creationDate))}>
      {quiz.questions.map((question) => (
        <div
          key={question.id + "-" + question.category}
          className={Styles.question}
        >
          <h5>
            {question.category} – Frage {question.id}
          </h5>
          <h3>{question.question}</h3>

          <ul>
            {question.answers.map((answer) => (
              <li
                key={answer}
                className={`${Styles.answer} ${
                  answer === question.selectedAnswer ? Styles.selected : ""
                } ${answer === question.correctAnswer ? Styles.correct : ""}`}
              >
                {answer}
              </li>
            ))}
          </ul>
          <p className={Styles.trivia}>{question.trivia}</p>
        </div>
      ))}
    </Layout>
  );
};

export default QuizDetail;
