import React from "react";
import { scaleLinear, line, curveMonotoneX, extent } from "d3";
import { Seconds2MinutesAndSeconds } from "../../Utils/Seconds2Time";
import { TimelineMarkerCircle } from "./TimelineMarkerCircle";

import Styles from "./WorkoutDetailChart.module.scss";

const WorkoutDetailChart = ({ data }) => {
  if (!data?.timelineMarkers || !data?.timelineMarkers?.length > 0) return null;

  const margin = { top: 20, right: 20, bottom: 20, left: 20 };
  const width =
    data.timelineMarkers[data.timelineMarkers.length - 1].time * 40 +
    margin.right +
    margin.left;
  const height = 100;
  const innerHeight = height - margin.top - margin.bottom;
  const innerWidth = width - margin.right - margin.left;

  const xTimelinemarkerValue = (d) => d.time;
  const xHrValue = (d) => d.time;
  const yHrValue = (d) => d.value;

  const xScale = scaleLinear()
    .domain([0, data.timelineMarkers[data.timelineMarkers.length - 1].time])
    .range([0, innerWidth]);

  const yHrScale =
    data.hrValues &&
    data?.hrValues.length > 0 &&
    scaleLinear()
      .domain(extent(data.hrValues, yHrValue))
      .range([innerHeight, 0])
      .nice();





  const wtf = data.timelineMarkers;

  const filteredWtf = wtf
    // .slice(1, -1) // filter start and ending away
    .filter((d) => !d.name.includes("pitstop")) // filter pitstop away
    
    // .filter((d) => d.time !== 0 || !d.name.includes("pitstop"));

  const renderWtf = filteredWtf.map((d, index) => {
    // console.log(d);
    return (
      <g key={index + d.time + d.name}>
        
        




        <g transform={`translate(${0},${38.5})`}>
          {d.tier <= filteredWtf[index + 1]?.tier ? (
            <>
            <line
              x1={filteredWtf[index + 1] && xScale(xTimelinemarkerValue(d))}
              y1={0}
              x2={
                filteredWtf[index + 1] &&
                xScale(xTimelinemarkerValue(filteredWtf[index + 1]))
              }
              y2={0}
              stroke={`${
                filteredWtf[index + 1]?.tier === 0
                  ? "#00A0B5"
                  : filteredWtf[index + 1]?.tier === 1
                  ? "#0071ED"
                  : filteredWtf[index + 1]?.tier === 2
                  ? "#6ECE41"
                  : filteredWtf[index + 1]?.tier === 3
                  ? "#E2AC00"
                  : "#C600DB"
              }`}
              strokeWidth={0.5}
            />
            <line
              x1={filteredWtf[index + 1] && xScale(xTimelinemarkerValue(d))}
              y1={3}
              x2={
                filteredWtf[index + 1] &&
                xScale(xTimelinemarkerValue(filteredWtf[index + 1]))
              }
              y2={3}
              stroke={`${
                filteredWtf[index + 1]?.tier === 0
                  ? "#00A0B5"
                  : filteredWtf[index + 1]?.tier === 1
                  ? "#0071ED"
                  : filteredWtf[index + 1]?.tier === 2
                  ? "#6ECE41"
                  : filteredWtf[index + 1]?.tier === 3
                  ? "#E2AC00"
                  : "#C600DB"
              }`}
              strokeWidth={0.5}
            />
            </>
          ) : (
            <>
            <line
              x1={filteredWtf[index + 1] && xScale(xTimelinemarkerValue(d))}
              y1={0}
              x2={
                filteredWtf[index + 1] &&
                xScale(xTimelinemarkerValue(filteredWtf[index + 1]))
              }
              y2={0}
              stroke={`${
                filteredWtf[index]?.tier === 0
                  ? "#00A0B5"
                  : filteredWtf[index]?.tier === 1
                  ? "#0071ED"
                  : filteredWtf[index]?.tier === 2
                  ? "#6ECE41"
                  : filteredWtf[index]?.tier === 3
                  ? "#E2AC00"
                  : "#C600DB"
              }`}
              strokeWidth={0.5}
            />
            <line
              x1={filteredWtf[index + 1] && xScale(xTimelinemarkerValue(d))}
              y1={3}
              x2={
                filteredWtf[index + 1] &&
                xScale(xTimelinemarkerValue(filteredWtf[index + 1]))
              }
              y2={3}
              stroke={`${
                filteredWtf[index]?.tier === 0
                  ? "#00A0B5"
                  : filteredWtf[index]?.tier === 1
                  ? "#0071ED"
                  : filteredWtf[index]?.tier === 2
                  ? "#6ECE41"
                  : filteredWtf[index]?.tier === 3
                  ? "#E2AC00"
                  : "#C600DB"
              }`}
              strokeWidth={0.5}
            />
            </>
          )}


        </g>
        {d === filteredWtf[0] && (
          <g transform={`translate(-${7},${height / 4})`}>
          <rect
                x={0}
                y={0}
                width={14}
                height={30}
                fill="#101010"
                stroke={`${
                  d.tier === 0
                    ? "#00A0B5"
                    : d.tier === 1
                    ? "#0071ED"
                    : d.tier === 2
                    ? "#6ECE41"
                    : d.tier === 3
                    ? "#E2AC00"
                    : "#C600DB"
                }`}
                strokeWidth="0.5"
              />
              <g fill="none" transform={`translate(1,1)`}>
                <rect x={0} y={0} width={4} height={4} fill="#101010" />
                <rect x={4} y={0} width={4} height={4} fill="white" />
                <rect x={8} y={0} width={4} height={4} fill="#101010" />
                <rect x={0} y={4} width={4} height={4} fill="white" />
                <rect x={4} y={4} width={4} height={4} fill="#101010" />
                <rect x={8} y={4} width={4} height={4} fill="white" />
                <rect x={0} y={8} width={4} height={4} fill="#101010" />
                <rect x={4} y={8} width={4} height={4} fill="white" />
                <rect x={8} y={8} width={4} height={4} fill="#101010" />
                <rect x={0} y={12} width={4} height={4} fill="white" />
                <rect x={4} y={12} width={4} height={4} fill="#101010" />
                <rect x={8} y={12} width={4} height={4} fill="white" />
                <rect x={0} y={16} width={4} height={4} fill="#101010" />
                <rect x={4} y={16} width={4} height={4} fill="white" />
                <rect x={8} y={16} width={4} height={4} fill="#101010" />
                <rect x={0} y={20} width={4} height={4} fill="white" />
                <rect x={4} y={20} width={4} height={4} fill="#101010" />
                <rect x={8} y={20} width={4} height={4} fill="white" />
                <rect x={0} y={24} width={4} height={4} fill="#101010" />
                <rect x={4} y={24} width={4} height={4} fill="white" />
                <rect x={8} y={24} width={4} height={4} fill="#101010" />
              </g>
              <line
                  x1={xScale(xTimelinemarkerValue(d)) + 13.5}
                  y1={15}
                  x2={xScale(xTimelinemarkerValue(d)) + 15}
                  y2={15}
                  stroke="#101010"
                  strokeWidth="2.5"
                />
              </g>
        )}


        {d.name !== "workout_start" && d.name !== "workout_finish" && d.time !== 0 && (
          <>
          <TimelineMarkerCircle
            x={xScale(xTimelinemarkerValue(d))}
            y={40}
            r={24}
            physicalRating={d.physicalRating}
            cognitiveRating={d.cognitiveRating}
            tier={d.tier}
          />
          <line
                    x1={xScale(xTimelinemarkerValue(d)) - 25}
                    y1={40}
                    x2={xScale(xTimelinemarkerValue(d)) - 23}
                    y2={40}
                    stroke="#101010"
                    strokeWidth="2.5"
                  />
                  <line
                    x1={xScale(xTimelinemarkerValue(d)) + 23}
                    y1={40}
                    x2={xScale(xTimelinemarkerValue(d)) + 25}
                    y2={40}
                    stroke="#101010"
                    strokeWidth="2.5"
                  />
                  </>
        )}


        {filteredWtf[index]?.tier < filteredWtf[index + 1]?.tier &&
          index !== filteredWtf.length - 1 && (
            <g
              transform={`translate(${
                xScale(xTimelinemarkerValue(d)) + 30
              },40)`}
            >
              <circle
                cx={0}
                cy={0}
                r={8}
                fill="#101010"
                strokeWidth={0.5}
                stroke={`${
                  filteredWtf[index + 1]?.tier === 0
                    ? "#00A0B5"
                    : filteredWtf[index + 1]?.tier === 1
                    ? "#0071ED"
                    : filteredWtf[index + 1]?.tier === 2
                    ? "#6ECE41"
                    : filteredWtf[index + 1]?.tier === 3
                    ? "#E2AC00"
                    : "#C600DB"
                }`}
              />
              <text
                dy=".32em"
                y={0}
                x={0}
                fill="white"
                style={{ textAnchor: "middle", fontSize: "0.625em" }}
              >
                {filteredWtf[index + 1]?.tier + 1}
              </text>
            </g>
          )}
        {!d.physicalPrecision > 0 && filteredWtf[index - 1]?.tier > 0 && (
          <g
            transform={`translate(${xScale(xTimelinemarkerValue(d)) + 30},40)`}
          >
            <circle
              cx={0}
              cy={0}
              r={8}
              fill="#101010"
              strokeWidth={0.5}
              stroke={`${
                filteredWtf[index + 1]?.tier === 0
                  ? "#00A0B5"
                  : filteredWtf[index + 1]?.tier === 1
                  ? "#0071ED"
                  : filteredWtf[index + 1]?.tier === 2
                  ? "#6ECE41"
                  : filteredWtf[index + 1]?.tier === 3
                  ? "#E2AC00"
                  : "#C600DB"
              }`}
            />
            <text
              dy=".32em"
              y={0}
              x={0}
              fill="white"
              style={{ textAnchor: "middle", fontSize: "0.625em" }}
            >
              {filteredWtf[index + 1]?.tier + 1}
            </text>
          </g>
        )}

{d === filteredWtf[filteredWtf.length - 1] && (
          <g
          transform={`translate(${
            xScale(
              data?.timelineMarkers[data.timelineMarkers.length - 1].time
            ) - 3.5
          },${height / 4})`}
        >
          <rect
                x={0}
                y={0}
                width={14}
                height={30}
                fill="#101010"
                stroke={`${
                  d.tier === 0
                    ? "#00A0B5"
                    : d.tier === 1
                    ? "#0071ED"
                    : d.tier === 2
                    ? "#6ECE41"
                    : d.tier === 3
                    ? "#E2AC00"
                    : "#C600DB"
                }`}
                strokeWidth="0.5"
              />
              <g fill="none" transform={`translate(1,1)`}>
                <rect x={0} y={0} width={4} height={4} fill="#101010" />
                <rect x={4} y={0} width={4} height={4} fill="white" />
                <rect x={8} y={0} width={4} height={4} fill="#101010" />
                <rect x={0} y={4} width={4} height={4} fill="white" />
                <rect x={4} y={4} width={4} height={4} fill="#101010" />
                <rect x={8} y={4} width={4} height={4} fill="white" />
                <rect x={0} y={8} width={4} height={4} fill="#101010" />
                <rect x={4} y={8} width={4} height={4} fill="white" />
                <rect x={8} y={8} width={4} height={4} fill="#101010" />
                <rect x={0} y={12} width={4} height={4} fill="white" />
                <rect x={4} y={12} width={4} height={4} fill="#101010" />
                <rect x={8} y={12} width={4} height={4} fill="white" />
                <rect x={0} y={16} width={4} height={4} fill="#101010" />
                <rect x={4} y={16} width={4} height={4} fill="white" />
                <rect x={8} y={16} width={4} height={4} fill="#101010" />
                <rect x={0} y={20} width={4} height={4} fill="white" />
                <rect x={4} y={20} width={4} height={4} fill="#101010" />
                <rect x={8} y={20} width={4} height={4} fill="white" />
                <rect x={0} y={24} width={4} height={4} fill="#101010" />
                <rect x={4} y={24} width={4} height={4} fill="white" />
                <rect x={8} y={24} width={4} height={4} fill="#101010" />
              </g>
              <line
                  x1={  -1}
                  y1={15}
                  x2={  1}
                  y2={15}
                  stroke="#101010"
                  strokeWidth="2.5"
                />
                
              </g>
        )}
        
      </g>
    );
  });



  const renderWiyp = filteredWtf.map((d, index) => (
    <g
      key={index}
      transform={`translate(${xScale(xTimelinemarkerValue(d))}, 60)`}
    >
      {/* <circle cx={0} cy={0} r={10} fill="white" /> */}
      {/* <g transform={`translate(0, 20)`}>
        <text fill="white" textAnchor="middle" style={{ fontSize: 10 }}>
          {d.readableName}
        </text>
      </g>
      <g transform={`translate(0, 30)`}>
        <text fill="white" textAnchor="middle" style={{ fontSize: 10 }}>
          {d.time}
        </text>
      </g>
      <g transform={`translate(0, 40)`}>
        <text fill="white" textAnchor="middle" style={{ fontSize: 10 }}>
          {d.physicalPrecision > 0 ? "ok" : "failure"}
        </text>
      </g> */}
      <g transform={`translate(0, 20)`} >
        <text fill="white" textAnchor="middle" style={{ fontSize: 10 }}>
          {d.readableName}
        </text>
      </g>
      <g transform={`translate(0, 30)`}>
        <text fill="white" textAnchor="middle" style={{ fontSize: 10 }}>
          tier: {d.tier}
        </text>
      </g>
      <g transform={`translate(0, 40)`}>
        <text fill="white" textAnchor="middle" style={{ fontSize: 10 }}>
          {d.time}
        </text>
      </g>
      {/* <g transform={`translate(0, 30)`}>
        <text fill="white" textAnchor="middle" style={{ fontSize: 10 }}>
         {filteredWtf[index + 1]?.tier ? (filteredWtf[index + 1]?.tier > filteredWtf[index]?.tier) ? "next is bigger" : "next is smaller" : null}
        </text>
      </g> */}
    </g>
  ));






















  return (
    <div className={Styles.scrollContainer}>
      {data?.hrValues && data?.hrValues.length > 0 ? (
        <svg
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          xmlns="http://www.w3.org/2000/svg"
          style={{ display: "block" }}
        >
          {data?.hrValues && (
            <g transform={`translate(${margin.left},${margin.top})`}>
              {/* {xScale.ticks(60).map((tickValue) => (
              <g
                key={tickValue}
                transform={`translate(${xScale(tickValue)},0)`}
              >
                <line
                      x1={0}
                      y1={0}
                      x2={0}
                      y2={innerHeight}
                      stroke="white"
                      strokeWidth="0.5"
                      opacity="0.25"
                    />
                <text
                  dy=".71em"
                  y={innerHeight + 5}
                  x={0}
                  fill="white"
                  style={{ textAnchor: "middle", fontSize: "0.625em" }}
                >
                  {Seconds2MinutesAndSeconds(tickValue)}
                </text>
              </g>
            ))} */}
              {/* {yHrScale.ticks(5).map((tickValue) => (
                <g
                  key={tickValue}
                  transform={`translate(0,${yHrScale(tickValue)})`}
                >
                  <line
                    x1={0}
                    y1={0}
                    x2={innerWidth}
                    y2={0}
                    stroke="white"
                    strokeWidth="0.5"
                    opacity="0.25"
                  />
                  <text
                    dy=".32em"
                    y={0}
                    x={-5}
                    fill="white"
                    style={{ textAnchor: "end", fontSize: "0.625em" }}
                  >
                    {tickValue}
                  </text>
                </g>
              ))} */}
              <line
                x1={0}
                y1={yHrScale(data.hrAverage)}
                x2={innerWidth}
                y2={yHrScale(data.hrAverage)}
                stroke="white"
                strokeWidth={1}
                strokeLinejoin="round"
                strokeLinecap="round"
                opacity="0.25"
              />
              <text
                y={yHrScale(data.hrAverage) + 8}
                dy=".32em"
                style={{
                  textAnchor: "start",
                  fontSize: "0.625em",
                  fill: "white",
                  opacity: 0.25,
                }}
              >
                {Math.round(data.hrAverage)}
              </text>
              <path
                fill="none"
                stroke="#00d1ff"
                strokeWidth={1}
                strokeLinejoin="round"
                strokeLinecap="round"
                d={line()
                  .x((d) => xScale(xHrValue(d)))
                  .y((d) => yHrScale(yHrValue(d)))
                  .curve(curveMonotoneX)(data.hrValues)}
              />
              {/* {data.hrValues.map((d) => (
                  <circle
                    key={d.time}
                    cx={xScale(xHrValue(d))}
                    cy={yHrScale(yHrValue(d))}
                    r={6}
                    fill="white"
                  />
                ))} */}
            </g>
          )}
        </svg>
      ) : (
        <div style={{ marginTop: 32 }} />
      )}

      {data?.timelineMarkers && (
        <svg
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          xmlns="http://www.w3.org/2000/svg"
          style={{
            // backgroundColor: "red",
            display: "block",
          }}
        >
          <g transform={`translate(${margin.left},0)`}>
            {xScale.ticks(+data.timelineMarkers[data.timelineMarkers.length -1].time / 10).map((tickValue) => (
              <g
                key={tickValue}
                transform={`translate(${xScale(tickValue)},0)`}
              >
                <line
                  x1={0}
                  y1={12}
                  x2={0}
                  y2={38}
                  stroke="white"
                  strokeWidth="0.5"
                />
                <text
                  dy=".71em"
                  y={0}
                  x={0}
                  fill="white"
                  style={{ textAnchor: "middle", fontSize: "0.625em" }}
                >
                  {Seconds2MinutesAndSeconds(tickValue)}
                </text>
              </g>
            ))}

 

            {renderWtf}

            {renderWiyp}

          </g>
        </svg>
      )}
    </div>
  );
};

export default WorkoutDetailChart;
