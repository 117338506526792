import React from "react";
import { useAuthState, useAuthDispatch, logout } from "../../Context";
import { useQuery, useMutation, useQueryClient } from "react-query";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import ROOT_URL from "../../Config/rootUrl";
import Layout from "../../Components/Layout/Layout.js";
import Styles from "./ActivateLicenseKey.module.scss";

const ActivateLicenseKey = () => {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();
  const queryClient = useQueryClient();

  const [licenseKey, setLicenseKey] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const { data } = useQuery(["licenseKeys"], async () => {
    const token = userDetails?.token;
    if (!token) return;
    const response = await fetch(`${ROOT_URL}/api/v1/licensekeys`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const responseJson = await response.json();
    if (data?.error?.name === "TokenExpiredError") {
      logout(dispatch);
    }
    // console.log(responseJson);
    return responseJson;
  });

  const activateLicenseKeyMutation = useMutation(
    async (licenseKey) =>
      await fetch(`${ROOT_URL}/api/v1/licensekeys`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${userDetails?.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key: licenseKey }),
      }),
    {
      // After success or failure, refetch the query
      onSettled: (data, error, variables, context) => {
        if (!!data?.ok) {
          setErrorMessage("");
        } else {
          !!(data.status === 404) &&
            setErrorMessage("Supplied license key is invalid");
        }
        queryClient.invalidateQueries(["licenseKeys"]);
        setLoading(false);
      },
    }
  );

  const handleChange = (e) => {
    let targetValue = e.target.value.toUpperCase();
    setErrorMessage("");

    if (
      (!!(targetValue.length === 4) && !!(licenseKey.length === 3)) ||
      (!!(targetValue.length === 9) && !!(licenseKey.length === 8)) ||
      (!!(targetValue.length === 14) && !!(licenseKey.length === 13))
    ) {
      const keyWithDash = (targetValue += "-");
      setLicenseKey(keyWithDash);
    } else if (
      (!!(targetValue.length === 5) && !!(licenseKey.length === 4)) ||
      (!!(targetValue.length === 10) && !!(licenseKey.length === 9)) ||
      (!!(targetValue.length === 15) && !!(licenseKey.length === 14))
    ) {
      const len = targetValue.length;
      const keyWithDash =
        targetValue.substring(0, len - 1) +
        "-" +
        targetValue.substring(len - 1);
      setLicenseKey(keyWithDash);
    } else {
      setLicenseKey(targetValue);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      // console.log("trying to submit");
      activateLicenseKeyMutation.mutate(licenseKey);
    } catch (error) {
      console.log(error);
    }
  };

  if (!data)
    return (
      <Layout headline="Activate License Key">
        <LoadingSpinner />
      </Layout>
    );

  return (
    <Layout headline="Activate License Key">
      <div className={Styles.container}>
        {/* {!!data && (
          <pre style={{ fontSize: 8 }}>{JSON.stringify(data, null, 4)}</pre>
        )} */}

        <form className={Styles.form} onSubmit={handleSubmit}>
          <label htmlFor="license-keay">
            Please enter the purchased license key*
            <input
              className={Styles.licenseKeyInput}
              id="license-key"
              type="text"
              name="license-key"
              value={licenseKey}
              placeholder="XXXX-XXXX-XXXX-XXXX"
              onChange={handleChange}
              disabled={loading || !!data.length}
              autoComplete="off"
              maxLength="19"
              required
            />
          </label>
          {!!errorMessage && (
            <span className={Styles.error}>{errorMessage}</span>
          )}
          <input
            type="submit"
            disabled={loading || !!(licenseKey.length < 19) || !!data?.length}
            value="Activate License Key"
          />

          {!!data.length && (
            <div className={Styles.success}>
              Thank you! You have successfully activated your license key. Once
              you have completed the included missions, you can purchase a new
              license key and activate it here.
            </div>
          )}
        </form>
      </div>
    </Layout>
  );
};

export default ActivateLicenseKey;
