import React from "react";
import CRUDUser from "../CRUDUser/CRUDUser";
import Styles from "./CRUDUserList.module.scss";

const CRUDUserList = ({
  userData,
  setModalDeleteUser,
  verifyUserMutation,
  changeRoleUserMutation,
  // select,
  // selectedUsers,
  // setSelectedUsers,
}) => {
  return (
    <div className={Styles.marginBottom}>
      {userData &&
        userData.map((user) => (
          <div key={user.id}>
            <CRUDUser
              user={user}
              setModalDeleteUser={setModalDeleteUser}
              verifyUserMutation={verifyUserMutation}
              changeRoleUserMutation={changeRoleUserMutation}
              // select={select}
              // selectedUsers={selectedUsers}
              // setSelectedUsers={setSelectedUsers}
            />
          </div>
        ))}
    </div>
  );
};

export default CRUDUserList;
