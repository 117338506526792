const Progress = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6666 15.3333H0.333343C0.149343 15.3333 0 15.4826 0 15.6666C0 15.8506 0.149343 15.9999 0.333343 15.9999H15.6667C15.8507 15.9999 16 15.8506 16 15.6666C16 15.4826 15.8506 15.3333 15.6666 15.3333Z"
        fill="white"
      />
      <path
        d="M3.00009 11.3333H1.00009C0.816091 11.3333 0.666748 11.4826 0.666748 11.6666V15.6666C0.666748 15.8506 0.816091 15.9999 1.00009 15.9999H3.00009C3.18409 15.9999 3.33343 15.8506 3.33343 15.6666V11.6666C3.33343 11.4826 3.18409 11.3333 3.00009 11.3333ZM2.66674 15.3332H1.33343V11.9999H2.66678V15.3332H2.66674Z"
        fill="white"
      />
      <path
        d="M7.00009 8H5.00009C4.81609 8 4.66675 8.14934 4.66675 8.33334V15.6667C4.66675 15.8507 4.81609 16 5.00009 16H7.00009C7.18409 16 7.33343 15.8507 7.33343 15.6667V8.33334C7.33343 8.14934 7.18409 8 7.00009 8ZM6.66674 15.3333H5.3334V8.66666H6.66674V15.3333Z"
        fill="white"
      />
      <path
        d="M11.0001 9.33325H9.00009C8.81609 9.33325 8.66675 9.4826 8.66675 9.6666V15.6666C8.66675 15.8506 8.81609 15.9999 9.00009 15.9999H11.0001C11.1841 15.9999 11.3334 15.8506 11.3334 15.6666V9.6666C11.3334 9.48256 11.1841 9.33325 11.0001 9.33325ZM10.6667 15.3332H9.3334V9.99991H10.6667V15.3332Z"
        fill="white"
      />
      <path
        d="M15.0001 5.33325H13.0001C12.8161 5.33325 12.6667 5.4826 12.6667 5.66659V15.6666C12.6667 15.8506 12.8161 15.9999 13.0001 15.9999H15.0001C15.1841 15.9999 15.3334 15.8506 15.3334 15.6666V5.66659C15.3334 5.48256 15.1841 5.33325 15.0001 5.33325ZM14.6667 15.3332H13.3334V5.99991H14.6667V15.3332Z"
        fill="white"
      />
      <path
        d="M2.00009 6C1.26475 6 0.666748 6.598 0.666748 7.33334C0.666748 8.06868 1.26475 8.66668 2.00009 8.66668C2.73543 8.66668 3.33343 8.06868 3.33343 7.33334C3.33343 6.598 2.73543 6 2.00009 6ZM2.00009 8C1.63209 8 1.33343 7.70065 1.33343 7.33334C1.33343 6.966 1.63209 6.66669 2.00009 6.66669C2.36809 6.66669 2.66674 6.96603 2.66674 7.33334C2.66674 7.70065 2.36809 8 2.00009 8Z"
        fill="white"
      />
      <path
        d="M6.00009 2.66675C5.26475 2.66675 4.66675 3.26475 4.66675 4.00009C4.66675 4.73543 5.26475 5.33343 6.00009 5.33343C6.73543 5.33343 7.33343 4.73543 7.33343 4.00009C7.33343 3.26475 6.73543 2.66675 6.00009 2.66675ZM6.00009 4.66674C5.63209 4.66674 5.33343 4.3674 5.33343 4.00009C5.33343 3.63275 5.63209 3.33343 6.00009 3.33343C6.36809 3.33343 6.66674 3.63278 6.66674 4.00009C6.66674 4.36743 6.36809 4.66674 6.00009 4.66674Z"
        fill="white"
      />
      <path
        d="M10.0001 4C9.26475 4 8.66675 4.598 8.66675 5.33334C8.66675 6.06868 9.26475 6.66668 10.0001 6.66668C10.7354 6.66668 11.3334 6.06868 11.3334 5.33334C11.3334 4.598 10.7354 4 10.0001 4ZM10.0001 6C9.63209 6 9.33343 5.70065 9.33343 5.33334C9.33343 4.966 9.63209 4.66669 10.0001 4.66669C10.3681 4.66669 10.6667 4.96603 10.6667 5.33334C10.6667 5.70065 10.3681 6 10.0001 6Z"
        fill="white"
      />
      <path
        d="M14.0001 0C13.2647 0 12.6667 0.597999 12.6667 1.33334C12.6667 2.06868 13.2647 2.66668 14.0001 2.66668C14.7354 2.66668 15.3334 2.06868 15.3334 1.33334C15.3334 0.597999 14.7354 0 14.0001 0ZM14.0001 2C13.6321 2 13.3334 1.70065 13.3334 1.33334C13.3334 0.965998 13.6321 0.666686 14.0001 0.666686C14.3681 0.666686 14.6667 0.966029 14.6667 1.33334C14.6667 1.70065 14.3681 2 14.0001 2Z"
        fill="white"
      />
      <path
        d="M13.5293 1.80404C13.3993 1.67404 13.1879 1.67404 13.0579 1.80404L10.4713 4.39069C10.3413 4.52069 10.3413 4.73204 10.4713 4.86204C10.5366 4.92669 10.6219 4.95938 10.7073 4.95938C10.7919 4.95938 10.8773 4.92738 10.9426 4.86204L13.5293 2.27539C13.6593 2.14539 13.6593 1.93404 13.5293 1.80404Z"
        fill="white"
      />
      <path
        d="M9.19404 4.56401L7.0527 3.95335C6.87335 3.90135 6.69135 4.00535 6.64135 4.18269C6.5907 4.36004 6.69335 4.54404 6.87001 4.59469L9.01135 5.20535C9.04201 5.21401 9.07269 5.21801 9.10269 5.21801C9.24804 5.21801 9.38135 5.12266 9.42335 4.97601C9.47404 4.79935 9.37138 4.61466 9.19404 4.56401Z"
        fill="white"
      />
      <path
        d="M5.47984 4.41535C5.36518 4.27135 5.15584 4.248 5.01184 4.362L2.4985 6.366C2.3545 6.48134 2.3305 6.69065 2.44584 6.83465C2.51184 6.91731 2.60919 6.96 2.7065 6.96C2.77916 6.96 2.8525 6.936 2.91384 6.888L5.42718 4.884C5.57118 4.76866 5.59518 4.55935 5.47984 4.41535Z"
        fill="white"
      />
    </svg>
  );
};
export default Progress;
