const Challenge = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.48051 13.588C1.66405 12.7716 0.696278 12.5073 0.229684 12.9742C-0.236911 13.4408 0.02699 14.4082 0.84353 15.2249C1.20246 15.584 1.6148 15.8532 2.00442 15.983C2.17702 16.0406 2.33962 16.069 2.48822 16.069C2.73356 16.069 2.94134 15.9915 3.09419 15.8387C3.56087 15.3721 3.29714 14.4046 2.48051 13.588ZM2.68498 15.4295C2.60631 15.5082 2.41574 15.5101 2.18762 15.434C1.88599 15.3335 1.5452 15.108 1.25282 14.8157C0.628375 14.1912 0.472306 13.5502 0.638971 13.3835C0.679917 13.3425 0.749092 13.3211 0.839631 13.3211C1.11854 13.3211 1.59963 13.5256 2.07131 13.9972C2.69558 14.6218 2.85182 15.2628 2.68498 15.4295Z"
        fill="white"
      />
      <path
        d="M5.54953 11.7464L4.32192 10.5188C4.20892 10.4058 4.02573 10.4058 3.91281 10.5188L1.41605 13.0154C1.30305 13.1284 1.30305 13.3116 1.41605 13.4245C1.52905 13.5375 1.71225 13.5375 1.82517 13.4245L4.11737 11.1325L4.93586 11.951L2.64366 14.243C2.53066 14.356 2.53066 14.5392 2.64366 14.6521C2.7002 14.7087 2.77413 14.7369 2.84822 14.7369C2.92231 14.7369 2.9964 14.7087 3.05278 14.6521L5.54953 12.1556C5.60379 12.1013 5.63431 12.0276 5.63431 11.951C5.63431 11.8744 5.60379 11.8007 5.54953 11.7464Z"
        fill="white"
      />
      <path
        d="M7.59555 12.1557L3.91272 8.47269C3.79972 8.35969 3.61652 8.35969 3.50361 8.47269L2.68512 9.29118C2.63086 9.34544 2.60034 9.41911 2.60034 9.49574C2.60034 9.57238 2.63086 9.64605 2.68512 9.7003L6.36811 13.3833C6.42236 13.4375 6.49603 13.4681 6.57267 13.4681C6.6493 13.4681 6.72297 13.4375 6.77723 13.3833L7.59555 12.5648C7.70855 12.4518 7.70855 12.2687 7.59555 12.1557ZM6.57267 12.7694L3.29879 9.49574L3.70808 9.08662L6.98179 12.3603L6.57267 12.7694Z"
        fill="white"
      />
      <path
        d="M15.9118 0.0815206C15.8501 0.0215856 15.7643 -0.00791564 15.6797 0.00183334L13.4632 0.240471C13.3975 0.247592 13.3362 0.276754 13.2895 0.32355L4.32183 9.29117C4.26758 9.34542 4.23706 9.41909 4.23706 9.49573C4.23706 9.57236 4.26758 9.64603 4.32183 9.70028L6.36802 11.7464C6.42456 11.8029 6.49848 11.8312 6.57258 11.8312C6.6465 11.8312 6.72059 11.8029 6.77697 11.7464L15.7447 2.77877C15.7938 2.72985 15.8235 2.66491 15.8286 2.59574L15.9992 0.310918C16.0055 0.225297 15.9736 0.141286 15.9118 0.0815206ZM15.2595 2.44544L6.57249 11.1325L4.9356 9.49573L13.6265 0.804725L15.3962 0.614154L15.2595 2.44544Z"
        fill="white"
      />
      <path
        d="M14.3126 1.75565C14.1996 1.64265 14.0164 1.64265 13.9035 1.75565L5.5496 10.1095C5.43659 10.2225 5.43659 10.4057 5.5496 10.5186C5.60614 10.5752 5.68006 10.6034 5.75415 10.6034C5.82825 10.6034 5.90234 10.575 5.95871 10.5186L14.3126 2.16477C14.4256 2.05185 14.4256 1.86865 14.3126 1.75565Z"
        fill="white"
      />
    </svg>
  );
};
export default Challenge;
