import React from "react";
import Line from "./Line";
// import LineCorrect from "./LineCorrect";
import Axis from "./Axis";
import GridLine from "./GridLine";
// import Area from "./Area";
// import Overlay from "./Overlay";
// import Tooltip from "./Tooltip";
import useController from "./ProgressChart.controller";
import useDimensions from "../../Utils/useDimensions";

import Styles from "./ProgressChart.module.scss";

const ProgressChart = ({ data, margin, timeFrame, HrYScale, lineVertical }) => {
  // console.log(data)
  // const overlayRef = React.useRef(null);
  const [containerRef, { svgWidth, svgHeight, width, height }] = useDimensions({
    maxHeight: 400,
    margin,
  });
  const controller = useController({ data, width, height, timeFrame, HrYScale });
  const { yTickFormat, xTickFormat, xScale, yScale, yScaleForAxis, xScaleForAxis } =
    controller;

  return (
    <div ref={containerRef}>
      <svg width={svgWidth} height={svgHeight}>
        <g transform={`translate(${margin.left},${margin.top})`}>
          <GridLine
            type="vertical"
            scale={xScaleForAxis}
            // ticks={timeFrame === "weekly" ? 7 : 12}
            // ticks={8}
            size={height}
            transform={`translate(0, ${height})`}
            strokeDasharray={"5 2"}
            disableAnimation={true}
          />

          {/* <GridLine
            type="vertical"
            scale={xScale}
            ticks={1}
            size={height}
            transform={`translate(${timeFrame === "weekly" ? (width / 7 ) : width }, ${height})`}
            strokeDasharray={"5 2"}
            disableAnimation={true}
          /> */}

          <GridLine
            type="horizontal"
            scale={yScaleForAxis}
            ticks={6}
            size={width}
            disableAnimation={true}
          />

          {/* <GridLine
            type="horizontal"
            className="baseGridLine"
            scale={yScale}
            ticks={1}
            size={width}
            disableAnimation
          /> */}

          {/* {data.map(({ name, items, color }) => (
            <LineCorrect
              key={name}
              data={items}
              xScale={xScale}
              yScale={yScale}
              color={color}
              transform={`translate(${width / 14}, 0)`}
              strokeLinecap="round"
            />
          ))} */}

          {/* {data[0].items.map((item, i) => (
            <Line
              key={item.date}
              data={item}
              xScale={xScale}
              yScale={yScale}
              color={data[0].color}
              transform={`translate(${width / 14}, 0)`}
              strokeLinecap="round"
              animation="fadeIn"
            />
          ))} */}

          {data.map(({ name, items, color }) =>
            items.map((item) =>
              item.value !== null ? (
                <Line
                  key={item.date}
                  data={item}
                  xScale={xScale}
                  yScale={yScale}
                  color={color}
                  width={width}
                  lineVertical={lineVertical}
                  // transform={`translate(${timeFrame === "weekly" ? (width / 14) : (width / 24)}, 0)`}
                  strokeWidth={3}
                  strokeLinecap="round"
                  animation="fadeIn"
                />
              ) : null
            )
          )}

          {/* <Area
            data={data[0].items}
            xScale={xScale}
            yScale={yScale}
            color="white"
          /> */}

          <Axis
            type="left"
            className={Styles.axisY}
            scale={yScaleForAxis}
            transform="translate(-20, 0)"
            ticks={6}
            tickFormat={yTickFormat}
            disableAnimation={true}
          />

          {/* <Overlay ref={overlayRef} width={width} height={height}> */}

          <Axis
            type="bottom"
            className={Styles.axisX}
            // anchorEl={overlayRef.current}
            scale={xScale}
            transform={`translate(0, ${height + 10})`}
            // ticks={timeFrame === "weekly" ? 7 : 12}
            tickFormat={xTickFormat}
            disableAnimation={true}
          />

          {/* <Tooltip
              className="tooltip"
              anchorEl={overlayRef.current}
              width={width}
              height={height}
              margin={margin}
              xScale={xScale}
              yScale={yScale}
              data={data}
            />
          </Overlay> */}
        </g>
      </svg>
    </div>
  );
};

export default ProgressChart;
