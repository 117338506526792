import React from "react";
import { Link } from "react-router-dom";
import Styles from "./Workout.module.scss";
import DumbbellIcon from "../../Assets/DumbbellIcon";
import DurationStat from "../WorkoutStat/DurationStat";
import DualflowScoreStat from "../WorkoutStat/DualflowScoreStat";
import ScoreStat from "../WorkoutStat/ScoreStat";
import Row from "../Row/Row";
import Challenge from "../../Assets/Challenge.js";

const Workout = ({ workout, select, selectedWorkout, setSelectedWorkout, crud, setModalDeleteWorkout }) => {
  return (
    <>
      {!select ? (
        <div className={Styles.outerContainer}>
          <div className={Styles.innerContainer}>
            {!crud ? (
              <Link
                to={`/workout/${workout.id}`}
                key={workout.id}
                className={Styles.workout}
              >
                <h3>
                  {workout.workoutPreset?.name}
                  <DumbbellIcon />
                </h3>
                <Row>
                  <DurationStat value={workout.measuredDuration} />
                  <DualflowScoreStat value={workout.dualflowScore} />
                  <ScoreStat value={workout.score} />
                </Row>
              </Link>
            ) : (
              <div
                key={workout.id}
                className={`${Styles.workout} ${Styles.crud}`}
              >
                <h3>
                  {workout.workoutPreset?.name}
                  <DumbbellIcon />
                </h3>
                <Row>
                  <DurationStat value={workout.measuredDuration} />
                  <DualflowScoreStat value={workout.dualflowScore} />
                  <ScoreStat value={workout.score} />
                  <button
                    className={`${"small"} ${Styles.delete}`}
                    onClick={() =>
                      setModalDeleteWorkout({
                        workoutId: workout.id,
                        workoutPresetName: workout.workoutPreset.name,
                        measuredDuration: workout.measuredDuration,
                        dualflowScore: workout.dualflowScore,
                        score: workout.score,
                      })
                    }
                  >
                    Delete
                  </button>
                </Row>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          className={Styles.outerContainer}
          onClick={() => setSelectedWorkout(workout.id)}
        >
          <div className={Styles.innerContainer}>
            <div className={Styles.workout}>
              <h3>
                {workout.workoutPreset?.name}
                <DumbbellIcon />
              </h3>
              <Row>
                <DurationStat value={workout.measuredDuration} />
                <DualflowScoreStat value={workout.dualflowScore} />
                <ScoreStat value={workout.score} />
              </Row>
            </div>
          </div>
          <div
            className={`${Styles.selector} ${
              selectedWorkout === workout.id ? Styles.active : ""
            }`}
          >
            <div
              className={Styles.circle}
              // onClick={() => setSelectedWorkout(workout.id)}
            >
              <Challenge className={Styles.placeholder} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Workout;
