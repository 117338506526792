import React from "react";
import { useAuthState, useAuthDispatch, logout } from "../../Context";
import { useInfiniteQuery, useMutation } from "react-query";
import { Link, useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Layout from "../../Components/Layout/Layout";
import WorkoutList from "../../Components/WorkoutList/WorkoutList";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import { BsTrash } from "react-icons/bs";

import Styles from "./CRUDUsersWorkouts.module.scss";

import ROOT_URL from "../../Config/rootUrl";

const CRUDUsersWorkouts = () => {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();
  const [loaded, setLoaded] = React.useState(false);
  const [workoutData, setWorkoutData] = React.useState();
  const [modalDeleteWorkout, setModalDeleteWorkout] = React.useState(null);

  const { id } = useParams();

  const { data, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery(
    ["crud-workouts", id],
    async ({ pageParam = 1 }) => {
      const token = userDetails?.token;
      if (!token) return;
      const response = await fetch(
        `${ROOT_URL}/api/v1/crud/users/${id}/workouts?page=${pageParam}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseJson = await response.json();
      if (data?.error?.name === "TokenExpiredError") {
        logout(dispatch);
      }
      return responseJson;
    },
    {
      getNextPageParam: (page) =>
        page.currentPage === page.totalPages ? undefined : page.currentPage + 1,
    }
  );

  React.useEffect(() => {
    try {
      const fetchedWorkoutData = data?.pages
        ?.map((entries) => entries.entries)
        .flat();

      // this gives an object with dates as keys
      const groups = fetchedWorkoutData?.reduce((groups, workout) => {
        const updatedAt = workout.updatedAt.split("T")[0];
        if (!groups[updatedAt]) {
          groups[updatedAt] = [];
        }
        groups[updatedAt].push(workout);
        return groups;
      }, {});

      // add it in the array format instead
      const groupArrays =
        groups &&
        Object.keys(groups).map((updatedAt) => {
          return {
            updatedAt,
            workouts: groups[updatedAt],
          };
        });

      setLoaded(true);
      setWorkoutData(groupArrays);
    } catch (err) {
      console.log(err);
      return;
    }
  }, [data]);

  const dataLength = data?.pages?.reduce(
    (counter, page) => counter + page.entries?.length,
    0
  );



  const deleteWorkoutMutation = useMutation(
    async (workoutId) =>
      await fetch(`${ROOT_URL}/api/v1/crud/users/${id}/workouts/${workoutId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${userDetails?.token}`,
        },
      }),
    {
      // After success or failure, refetch the query and set Modal to null
      onSettled: () => {
        setModalDeleteWorkout(null);
        refetch();
      },
    }
  );





  if (!data || dataLength === undefined || !loaded || !workoutData)
    return (
      <Layout headline={`CRUD USER ID ${id}`}>
        <LoadingSpinner />
      </Layout>
    );

  return (
    <Layout headline={`CRUD USER ID ${id}`}>
      {workoutData.length > 0 ? (
        <InfiniteScroll
          dataLength={dataLength}
          next={fetchNextPage}
          hasMore={hasNextPage}
          loader={dataLength > 0 && <LoadingSpinner positionRelative={true} />}
          className={Styles.container}
        >
          {data.pages.map((group, i, array) => (
            <React.Fragment key={i}></React.Fragment>
          ))}
          <WorkoutList
            workoutData={workoutData}
            crud={true}
            setModalDeleteWorkout={setModalDeleteWorkout}
          />
        </InfiniteScroll>
      ) : (
        <div className={Styles.noWorkoutContainer}>
          <h1>No Workouts</h1>
          <p>
            <Link to="../crud/users">&lt; Go back to overview</Link>
          </p>
        </div>
      )}

      {modalDeleteWorkout && (
        <div className={Styles.overlay}>
          <div className={Styles.circle}>
            <BsTrash className={Styles.placeholder} />
          </div>
          <h2>You are about to delete a workout</h2>
          <p>The following workout will be permanently removed:</p>
          <p>
            <span>
              workoutpreset name: {modalDeleteWorkout.workoutPresetName} <br />
              measured duration: {modalDeleteWorkout.measuredDuration} <br />
              dual flow: {modalDeleteWorkout.dualflowScore} <br />
              points: {modalDeleteWorkout.score} <br />
              id: {modalDeleteWorkout.workoutId} <br />
            </span>
          </p>
          <div className={Styles.buttonGroup}>
            <button onClick={() => setModalDeleteWorkout(null)}>Cancel</button>
            <button
              className={Styles.deleteButton}
                onClick={() => deleteWorkoutMutation.mutate(modalDeleteWorkout.workoutId)}
            >
              Delete
            </button>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default CRUDUsersWorkouts;
