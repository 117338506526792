const Logo = () => {
  return (
    <svg
      width="64"
      height="16"
      viewBox="0 0 64 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.61429 0.132107C8.32586 0.0997591 8.03342 0.0811501 7.73621 0.0811501C3.46361 0.0811501 0 3.54439 0 7.81727C0 12.0899 3.46352 15.5537 7.73621 15.5537C8.03342 15.5537 8.32586 15.5346 8.61429 15.5022C4.75549 15.0663 1.75644 11.7929 1.75644 7.81727C1.75644 3.84188 4.75549 0.56853 8.61429 0.132107Z"
        fill="white"
      />
      <path
        d="M12.6638 9.44674C12.6638 11.4355 10.8979 12.3785 8.92625 12.3785C7.09174 12.3785 5.12018 11.5898 4.96582 9.36101H7.10894C7.26321 10.2525 8.03471 10.6297 9.02918 10.6297C9.93783 10.6297 10.5722 10.3382 10.5722 9.66954C10.5722 9.12092 10.1607 8.98377 8.94345 8.77799C7.21188 8.48648 5.24022 8.19507 5.24022 6.01773C5.24022 4.01176 6.92037 3.20594 8.75488 3.20594C10.8122 3.20594 12.3381 4.21743 12.4581 6.18909H10.3836C10.2979 5.31466 9.68064 4.97183 8.77199 4.97183C7.72618 4.97183 7.31472 5.43471 7.31472 5.9491C7.31472 6.63487 8.08622 6.77202 9.16634 6.92638C10.7436 7.16647 12.6638 7.38935 12.6638 9.44674Z"
        fill="white"
      />
      <path
        d="M22.93 7.66374C22.93 10.8184 20.9583 12.2585 18.8153 12.2585C17.6323 12.2585 16.7408 11.7613 16.2436 11.127V15.4818H14.1177V3.36039H16.1922V4.76623C16.638 3.78896 17.7352 3.20603 19.0211 3.20603C21.1641 3.20603 22.93 4.6805 22.93 7.66374ZM20.7697 7.76659C20.7697 5.96639 19.7239 5.14347 18.5067 5.14347C17.3408 5.14347 16.1751 5.94929 16.1751 7.76659C16.1751 9.56678 17.3409 10.3726 18.5067 10.3726C19.7239 10.3725 20.7697 9.54958 20.7697 7.76659Z"
        fill="white"
      />
      <path
        d="M32.2669 6.49781V12.1898H30.141V6.68638C30.141 5.692 29.541 5.10907 28.4952 5.10907C27.2265 5.10907 26.5578 5.98342 26.5578 7.42357V12.1897H24.4319V0H26.5578V4.66331C27.055 3.82328 27.7408 3.20604 29.2323 3.20604C31.2382 3.20604 32.2669 4.38909 32.2669 6.49781Z"
        fill="white"
      />
      <path
        d="M35.7474 8.41804C35.7988 9.85819 36.8275 10.544 38.0276 10.544C38.7991 10.544 39.5365 10.2353 39.845 9.53247H41.8337C41.3879 11.4012 39.6906 12.3785 37.9247 12.3785C35.7988 12.3785 33.6899 10.9383 33.6899 7.8009C33.6899 4.76633 35.6959 3.20613 37.9419 3.20613C40.0849 3.20613 41.9023 4.62917 41.9023 7.5438V8.41813H35.7474V8.41804ZM35.7988 6.85784H39.8107C39.8107 5.69199 39.0049 5.04054 37.9077 5.04054C36.9304 5.04054 35.9874 5.57204 35.7988 6.85784Z"
        fill="white"
      />
      <path
        d="M48.4136 5.33205H47.8479C46.3562 5.33205 45.5504 6.13787 45.5504 7.86944V12.1899H43.4246V3.3604H45.5335V4.6634C45.9792 3.70333 46.802 3.25756 47.8308 3.25756H48.4136V5.33205Z"
        fill="white"
      />
      <path
        d="M57.7627 3.36039L54.1793 12.8242C53.5965 14.3672 52.3106 15.0016 50.8019 15.0016H49.9276V13.1157H50.5789C51.4535 13.1157 52.1221 12.8586 52.362 12.1384L52.3792 12.0699L48.9846 3.36039H51.2476L53.4249 9.51527L55.5682 3.36039H57.7627Z"
        fill="white"
      />
      <path
        d="M54.4746 0.132107C54.763 0.0997591 55.0558 0.0811501 55.3528 0.0811501C59.6246 0.0811501 63.0889 3.54439 63.0889 7.81727C63.0889 12.0899 59.6246 15.5537 55.3528 15.5537C55.0558 15.5537 54.763 15.5346 54.4746 15.5022C58.3334 15.0663 61.3323 11.7929 61.3323 7.81727C61.3322 3.84188 58.3334 0.56853 54.4746 0.132107Z"
        fill="white"
      />
    </svg>
  );
};

export default Logo;
