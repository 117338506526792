import React from "react";
import { useAuthState, useAuthDispatch, logout } from "../../Context";
import { useInfiniteQuery } from "react-query";
import InfiniteScroll from "react-infinite-scroll-component";
import Layout from "../../Components/Layout/Layout";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import FixedButton from "../../Components/FixedButton/FixedButton";
import Challenge from "../../Assets/Challenge.js";
import { Link, useLocation } from "react-router-dom";
import ROOT_URL from "../../Config/rootUrl";
import Styles from "./ChallengesChooseOpponents.module.scss";
import UserList from "../../Components/UserList/UserList";
import { useTranslation } from "react-i18next";

const ChallengesChooseOpponents = () => {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();
  const [loaded, setLoaded] = React.useState(false);
  const [userData, setUserData] = React.useState();
  const [overlayChallengeSent, setOverlayChallengeSent] = React.useState(false);
  const [selectedUsers, setSelectedUsers] = React.useState([]);

  const {t} = useTranslation();

  const location = useLocation();
  const { workoutId } = location.state;

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    "getChallengeUsers",
    async ({ pageParam = 1 }) => {
      const token = userDetails?.token;
      if (!token) return;
      const response = await fetch(
        `${ROOT_URL}/api/v1/challenge/users/?page=${pageParam}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseJson = await response.json();
      if (data?.error?.name === "TokenExpiredError") {
        logout(dispatch);
      }
      return responseJson;
    },
    {
      getNextPageParam: (page) =>
        page.currentPage === page.totalPages ? undefined : page.currentPage + 1,
    }
  );

  React.useEffect(() => {
    try {
      const fetchedUserData = data?.pages
        ?.map((entries) => entries.entries)
        .flat();

      setLoaded(true);
      setUserData(fetchedUserData);
    } catch (err) {
      console.log(err);
      return;
    }
  }, [data]);

  const dataLength = data?.pages?.reduce(
    (counter, page) => counter + page.entries?.length,
    0
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = userDetails?.token;
      if (!token) return;

      if (!workoutId || selectedUsers.length < 1) return;

      const payload = {
        opponentIds: selectedUsers,
        challengeBestOf: 3,
        rounds: [
          {
            initiatorWorkoutId: workoutId,
          },
        ],
      };

      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      };

      const response = await fetch(
        `${ROOT_URL}/api/v1/challenge/create`,
        requestOptions
      );

      const data = await response.json();

      if (!response.ok) {
        console.log(response);
        console.log(data?.error?.message);
        return;
      }

      setOverlayChallengeSent(true);

      // setSentChallenge(data);
      // setSelectedUsers([]);
      // setSelectedWorkout(0);
    } catch (err) {
      console.error(err);
      return;
    }
  };

  if (!data || dataLength === undefined || !loaded)
    return (
      <Layout headline={t("challenges.headline")}>
        <LoadingSpinner />
      </Layout>
    );

  return (
    <Layout headline={t("challenges.headline")} fixedButtonAtBottom={true}>
      <div className={Styles.challengeSelector}>
        <p className={Styles.subtitle}>{t("challenges.chooseOpponents")}</p>
      </div>

      {userData && userData.length > 0 && (
        <InfiniteScroll
          dataLength={dataLength}
          next={fetchNextPage}
          hasMore={hasNextPage}
          loader={dataLength > 0 && <LoadingSpinner positionRelative={true} />}
          className={Styles.container}
        >
          {data.pages.map((group, i, array) => (
            <React.Fragment key={i}></React.Fragment>
          ))}
          <UserList
            userData={userData}
            select={true}
            setSelectedUsers={setSelectedUsers}
            selectedUsers={selectedUsers}
          />
        </InfiniteScroll>
      )}

      <FixedButton
        text={t("common.buttons.sendChallenge")}
        Icon={Challenge}
        disabled={selectedUsers.length < 1}
        handleSubmit={handleSubmit}
      />
      {overlayChallengeSent && (
        <div className={Styles.overlay}>
          <div className={Styles.circle}>
            <Challenge className={Styles.placeholder} />
          </div>
          <p>{t("challenges.challengeSentOverlay.challengeSent")}</p>
          <Link to="/challenges">{t("challenges.challengeSentOverlay.goBack")}</Link>
        </div>
      )}
    </Layout>
  );
};

export default ChallengesChooseOpponents;
