import React from "react";
import * as d3 from "d3";

const Line = ({
  xScale,
  yScale,
  color,
  data,
  isSmooth,
  animation,
  width,
  lineVertical,
  strokeWidth,
  strokeLinecap,
  ...props
}) => {
  const ref = React.useRef(null);
  // Define different types of animation that we can use
  const animateLeft = React.useCallback(() => {
    // NOTE: for some reason getTotalLength() doesn't work in tests
    // in this codesandbox so we added default value just for tests
    const totalLength = ref.current.getTotalLength
      ? ref.current.getTotalLength()
      : 500;
    d3.select(ref.current)
      .attr("opacity", 1)
      .attr("stroke-dasharray", `${totalLength},${totalLength}`)
      .attr("stroke-dashoffset", totalLength)
      .transition()
      .duration(250)
      .ease(d3.easeLinear)
      .attr("stroke-dashoffset", 0);
  }, []);
  const animateFadeIn = React.useCallback(() => {
    d3.select(ref.current)
      .transition()
      .duration(250)
      .ease(d3.easeLinear)
      .attr("opacity", 1);
  }, []);
  const noneAnimation = React.useCallback(() => {
    d3.select(ref.current).attr("opacity", 1);
  }, []);

  React.useEffect(() => {
    switch (animation) {
      case "left":
        animateLeft();
        break;
      case "fadeIn":
        animateFadeIn();
        break;
      case "none":
      default:
        noneAnimation();
        break;
    }
  }, [animateLeft, animateFadeIn, noneAnimation, animation]);

  // Recalculate line length if scale has changed
  React.useEffect(() => {
    if (animation === "left") {
      const totalLength = ref.current.getTotalLength
        ? ref.current.getTotalLength()
        : 500;
      d3.select(ref.current).attr(
        "stroke-dasharray",
        `${totalLength},${totalLength}`
      );
    }
  }, [xScale, yScale, animation]);

  // console.log("data.value: ");
  // console.log(data.value);
  // console.log("data.value2: ");
  // console.log(data.value2);

  return (
    <>
      <g ref={ref} opacity={0}>
        <line
          x1={lineVertical ? xScale(data.date) : xScale(data.date) - width / 40}
          y1={
            lineVertical
              ? data.value
                ? yScale(data.value) + strokeWidth
                : yScale(data.value)
              : yScale(data.value)
          }
          x2={lineVertical ? xScale(data.date) : xScale(data.date) + width / 40}
          y2={
            lineVertical && data.value > data.value2
              ? yScale(data.value2) - strokeWidth
              : yScale(data.value)
          }
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={
            lineVertical && data.value > data.value2
              ? strokeLinecap
              : !lineVertical
              ? strokeLinecap
              : "butt"
          }
          fill="none"
          // opacity={0}
          {...props}
        />
        {lineVertical &&
          data.circle &&
          data.value &&
          (data.value2 || data.value2 === 0) && (
            <>
              <circle
                cx={xScale(data.date)}
                cy={yScale(data.value / 2 + data.value2 / 2)}
                r="6"
                fill={color}
                stroke="none"
              />
              <text
                x={xScale(data.date)}
                y={yScale(data.value / 2 + data.value2 / 2) + 3}
                fill="#101010"
                textAnchor="middle"
                fontSize={8}
                fontWeight={700}
              >
                {data.circle}
              </text>
            </>
          )}

        {lineVertical &&
          data.star === 0 &&
          data.value &&
          (data.value2 || data.value2 === 0) && (
            <g
              transform={`translate(${xScale(data.date) - 4.12135}, ${
                yScale(data.value / 2 + data.value2 / 2) - 4.12135
              })`}
            >
              <path
                fill="#101010"
                fillOpacity=".8"
                d="m6.99 4.12.96-.96a.996.996 0 0 0 0-1.41L6.5.29C6.31.11 6.06 0 5.79 0s-.52.11-.71.29l-.96.97-.96-.97a.996.996 0 0 0-1.41 0L.29 1.74c-.18.19-.29.45-.29.71s.11.52.29.71l.96.96-.96.96a.996.996 0 0 0 0 1.41l1.45 1.45c.19.19.44.29.71.29s.52-.11.71-.29l.96-.96.96.96c.39.39 1.02.39 1.41 0L7.95 6.5c.19-.19.29-.44.29-.71s-.1-.52-.29-.71l-.96-.96zm-1.2 3.12L4.12 5.57 2.45 7.24 1 5.79l1.67-1.67L1 2.45 2.45 1l1.67 1.67L5.79 1l1.45 1.45-1.67 1.67 1.67 1.67-1.45 1.45z"
              />
              <path
                fill="#fb004b"
                fillRule="evenodd"
                d="m4.12 5.57 1.67 1.67 1.45-1.45-1.67-1.67 1.67-1.67L5.79 1 4.12 2.67 2.45 1 1 2.45l1.67 1.67L1 5.79l1.45 1.45 1.67-1.67z"
                clipRule="evenodd"
              />
            </g>
          )}

        {lineVertical &&
          data.star === 1 &&
          data.value &&
          (data.value2 || data.value2 === 0) && (
            <g
              transform={`translate(${xScale(data.date) - 4.04595}, ${
                yScale(data.value / 2 + data.value2 / 2) - 4.01075
              })`}
            >
              <path
                fill="#101010"
                d="M8.15 3.01c-.16-.48-.56-.82-1.05-.89l-1.2-.18L5.35.76C5.14.3 4.68 0 4.17 0c-.51 0-.98.3-1.19.76l-.55 1.18-1.19.18c-.49.07-.9.41-1.05.89-.16.47-.03.98.33 1.33l.88.86-.21 1.3c-.09.5.12.98.52 1.28a1.317 1.317 0 0 0 1.37.09l1.08-.57 1.08.57c.19.1.4.15.61.15.27 0 .53-.08.76-.25.42-.29.62-.77.54-1.26L6.94 5.2l.88-.86c.36-.35.48-.86.33-1.33zm-1.03.62L5.86 4.85l.3 1.82a.32.32 0 0 1-.12.3c-.1.06-.22.07-.32.02l-1.55-.82-1.56.82c-.1.05-.22.04-.32-.02a.32.32 0 0 1-.12-.3l.3-1.82-1.26-1.22a.317.317 0 0 1-.07-.32c.03-.11.13-.19.24-.2l1.73-.25.78-1.68c.05-.11.16-.18.27-.18.13 0 .24.07.29.18l.78 1.68 1.73.25c.11.01.2.09.24.2.03.11 0 .23-.08.32z"
              />
              <path
                fill="#ffc700"
                d="M7.19 3.31a.29.29 0 0 0-.25-.2l-1.72-.25-.78-1.68A.308.308 0 0 0 4.16 1c-.12 0-.22.07-.27.18l-.78 1.68-1.73.25c-.11.01-.21.09-.24.2-.04.11-.01.23.07.32l1.26 1.22-.3 1.82c-.02.11.03.23.12.29.09.07.22.08.32.02l1.55-.82 1.55.82a.32.32 0 0 0 .32-.03.31.31 0 0 0 .12-.3l-.3-1.82 1.26-1.22c.09-.07.11-.19.08-.3z"
              />
            </g>
          )}

        {lineVertical &&
          data.star === 2 &&
          data.value &&
          (data.value2 || data.value2 === 0) && (
            <g
              transform={`translate(${xScale(data.date) - 4.04595}, ${
                yScale(data.value / 2 + data.value2 / 2) - 7.01075
              })`}
            >
              <g>
                <path
                  fill="#101010"
                  d="M8.15 3.01c-.16-.48-.56-.82-1.05-.89l-1.2-.18L5.35.76C5.14.3 4.68 0 4.17 0c-.51 0-.98.3-1.19.76l-.55 1.18-1.19.18c-.49.07-.9.41-1.05.89-.16.47-.03.98.33 1.33l.88.86-.21 1.3c-.09.5.12.98.52 1.28a1.317 1.317 0 0 0 1.37.09l1.08-.57 1.08.57c.19.1.4.15.61.15.27 0 .53-.08.76-.25.42-.29.62-.77.54-1.26L6.94 5.2l.88-.86c.36-.35.48-.86.33-1.33zm-1.03.62L5.86 4.85l.3 1.82a.32.32 0 0 1-.12.3c-.1.06-.22.07-.32.02l-1.55-.82-1.56.82c-.1.05-.22.04-.32-.02a.32.32 0 0 1-.12-.3l.3-1.82-1.26-1.22a.317.317 0 0 1-.07-.32c.03-.11.13-.19.24-.2l1.73-.25.78-1.68c.05-.11.16-.18.27-.18.13 0 .24.07.29.18l.78 1.68 1.73.25c.11.01.2.09.24.2.03.11 0 .23-.08.32z"
                />
                <path
                  fill="#ffc700"
                  d="M7.19 3.31a.29.29 0 0 0-.25-.2l-1.72-.25-.78-1.68A.308.308 0 0 0 4.16 1c-.12 0-.22.07-.27.18l-.78 1.68-1.73.25c-.11.01-.21.09-.24.2-.04.11-.01.23.07.32l1.26 1.22-.3 1.82c-.02.11.03.23.12.29.09.07.22.08.32.02l1.55-.82 1.55.82a.32.32 0 0 0 .32-.03.31.31 0 0 0 .12-.3l-.3-1.82 1.26-1.22c.09-.07.11-.19.08-.3z"
                />
              </g>
              <g transform={`translate(0, ${6})`}>
                <path
                  fill="#101010"
                  d="M8.15 3.01c-.16-.48-.56-.82-1.05-.89l-1.2-.18L5.35.76C5.14.3 4.68 0 4.17 0c-.51 0-.98.3-1.19.76l-.55 1.18-1.19.18c-.49.07-.9.41-1.05.89-.16.47-.03.98.33 1.33l.88.86-.21 1.3c-.09.5.12.98.52 1.28a1.317 1.317 0 0 0 1.37.09l1.08-.57 1.08.57c.19.1.4.15.61.15.27 0 .53-.08.76-.25.42-.29.62-.77.54-1.26L6.94 5.2l.88-.86c.36-.35.48-.86.33-1.33zm-1.03.62L5.86 4.85l.3 1.82a.32.32 0 0 1-.12.3c-.1.06-.22.07-.32.02l-1.55-.82-1.56.82c-.1.05-.22.04-.32-.02a.32.32 0 0 1-.12-.3l.3-1.82-1.26-1.22a.317.317 0 0 1-.07-.32c.03-.11.13-.19.24-.2l1.73-.25.78-1.68c.05-.11.16-.18.27-.18.13 0 .24.07.29.18l.78 1.68 1.73.25c.11.01.2.09.24.2.03.11 0 .23-.08.32z"
                />
                <path
                  fill="#ffc700"
                  d="M7.19 3.31a.29.29 0 0 0-.25-.2l-1.72-.25-.78-1.68A.308.308 0 0 0 4.16 1c-.12 0-.22.07-.27.18l-.78 1.68-1.73.25c-.11.01-.21.09-.24.2-.04.11-.01.23.07.32l1.26 1.22-.3 1.82c-.02.11.03.23.12.29.09.07.22.08.32.02l1.55-.82 1.55.82a.32.32 0 0 0 .32-.03.31.31 0 0 0 .12-.3l-.3-1.82 1.26-1.22c.09-.07.11-.19.08-.3z"
                />
              </g>
            </g>
          )}

        {lineVertical &&
          data.star === 3 &&
          data.value &&
          (data.value2 || data.value2 === 0) && (
            <g
              transform={`translate(${xScale(data.date) - 4.04595}, ${
                yScale(data.value / 2 + data.value2 / 2) - 10.01075
              })`}
            >
              <g>
                <path
                  fill="#101010"
                  d="M8.15 3.01c-.16-.48-.56-.82-1.05-.89l-1.2-.18L5.35.76C5.14.3 4.68 0 4.17 0c-.51 0-.98.3-1.19.76l-.55 1.18-1.19.18c-.49.07-.9.41-1.05.89-.16.47-.03.98.33 1.33l.88.86-.21 1.3c-.09.5.12.98.52 1.28a1.317 1.317 0 0 0 1.37.09l1.08-.57 1.08.57c.19.1.4.15.61.15.27 0 .53-.08.76-.25.42-.29.62-.77.54-1.26L6.94 5.2l.88-.86c.36-.35.48-.86.33-1.33zm-1.03.62L5.86 4.85l.3 1.82a.32.32 0 0 1-.12.3c-.1.06-.22.07-.32.02l-1.55-.82-1.56.82c-.1.05-.22.04-.32-.02a.32.32 0 0 1-.12-.3l.3-1.82-1.26-1.22a.317.317 0 0 1-.07-.32c.03-.11.13-.19.24-.2l1.73-.25.78-1.68c.05-.11.16-.18.27-.18.13 0 .24.07.29.18l.78 1.68 1.73.25c.11.01.2.09.24.2.03.11 0 .23-.08.32z"
                />
                <path
                  fill="#ffc700"
                  d="M7.19 3.31a.29.29 0 0 0-.25-.2l-1.72-.25-.78-1.68A.308.308 0 0 0 4.16 1c-.12 0-.22.07-.27.18l-.78 1.68-1.73.25c-.11.01-.21.09-.24.2-.04.11-.01.23.07.32l1.26 1.22-.3 1.82c-.02.11.03.23.12.29.09.07.22.08.32.02l1.55-.82 1.55.82a.32.32 0 0 0 .32-.03.31.31 0 0 0 .12-.3l-.3-1.82 1.26-1.22c.09-.07.11-.19.08-.3z"
                />
              </g>
              <g transform={`translate(0, ${6})`}>
                <path
                  fill="#101010"
                  d="M8.15 3.01c-.16-.48-.56-.82-1.05-.89l-1.2-.18L5.35.76C5.14.3 4.68 0 4.17 0c-.51 0-.98.3-1.19.76l-.55 1.18-1.19.18c-.49.07-.9.41-1.05.89-.16.47-.03.98.33 1.33l.88.86-.21 1.3c-.09.5.12.98.52 1.28a1.317 1.317 0 0 0 1.37.09l1.08-.57 1.08.57c.19.1.4.15.61.15.27 0 .53-.08.76-.25.42-.29.62-.77.54-1.26L6.94 5.2l.88-.86c.36-.35.48-.86.33-1.33zm-1.03.62L5.86 4.85l.3 1.82a.32.32 0 0 1-.12.3c-.1.06-.22.07-.32.02l-1.55-.82-1.56.82c-.1.05-.22.04-.32-.02a.32.32 0 0 1-.12-.3l.3-1.82-1.26-1.22a.317.317 0 0 1-.07-.32c.03-.11.13-.19.24-.2l1.73-.25.78-1.68c.05-.11.16-.18.27-.18.13 0 .24.07.29.18l.78 1.68 1.73.25c.11.01.2.09.24.2.03.11 0 .23-.08.32z"
                />
                <path
                  fill="#ffc700"
                  d="M7.19 3.31a.29.29 0 0 0-.25-.2l-1.72-.25-.78-1.68A.308.308 0 0 0 4.16 1c-.12 0-.22.07-.27.18l-.78 1.68-1.73.25c-.11.01-.21.09-.24.2-.04.11-.01.23.07.32l1.26 1.22-.3 1.82c-.02.11.03.23.12.29.09.07.22.08.32.02l1.55-.82 1.55.82a.32.32 0 0 0 .32-.03.31.31 0 0 0 .12-.3l-.3-1.82 1.26-1.22c.09-.07.11-.19.08-.3z"
                />
              </g>
              <g transform={`translate(0, ${12})`}>
                <path
                  fill="#101010"
                  d="M8.15 3.01c-.16-.48-.56-.82-1.05-.89l-1.2-.18L5.35.76C5.14.3 4.68 0 4.17 0c-.51 0-.98.3-1.19.76l-.55 1.18-1.19.18c-.49.07-.9.41-1.05.89-.16.47-.03.98.33 1.33l.88.86-.21 1.3c-.09.5.12.98.52 1.28a1.317 1.317 0 0 0 1.37.09l1.08-.57 1.08.57c.19.1.4.15.61.15.27 0 .53-.08.76-.25.42-.29.62-.77.54-1.26L6.94 5.2l.88-.86c.36-.35.48-.86.33-1.33zm-1.03.62L5.86 4.85l.3 1.82a.32.32 0 0 1-.12.3c-.1.06-.22.07-.32.02l-1.55-.82-1.56.82c-.1.05-.22.04-.32-.02a.32.32 0 0 1-.12-.3l.3-1.82-1.26-1.22a.317.317 0 0 1-.07-.32c.03-.11.13-.19.24-.2l1.73-.25.78-1.68c.05-.11.16-.18.27-.18.13 0 .24.07.29.18l.78 1.68 1.73.25c.11.01.2.09.24.2.03.11 0 .23-.08.32z"
                />
                <path
                  fill="#ffc700"
                  d="M7.19 3.31a.29.29 0 0 0-.25-.2l-1.72-.25-.78-1.68A.308.308 0 0 0 4.16 1c-.12 0-.22.07-.27.18l-.78 1.68-1.73.25c-.11.01-.21.09-.24.2-.04.11-.01.23.07.32l1.26 1.22-.3 1.82c-.02.11.03.23.12.29.09.07.22.08.32.02l1.55-.82 1.55.82a.32.32 0 0 0 .32-.03.31.31 0 0 0 .12-.3l-.3-1.82 1.26-1.22c.09-.07.11-.19.08-.3z"
                />
              </g>
            </g>
          )}
      </g>
    </>
  );
};

export default Line;
