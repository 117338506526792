import React from "react";
import { useAuthState, useAuthDispatch, logout } from "../../Context";
import Layout from "../../Components/Layout/Layout";
import ROOT_URL from "../../Config/rootUrl";
import Styles from "./Settings.module.scss";
import { useQuery, useQueryClient, useMutation } from "react-query";
import SpheryAppDefaultImageMale from "../../Assets/SpheryAppDefaultImageMale.jpg";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import { useTranslation } from "react-i18next";

const Settings = () => {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();

  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const [avatar, setAvatar] = React.useState(null);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [height, setHeight] = React.useState("");
  const [weight, setWeight] = React.useState("");
  const [dd, setDd] = React.useState("");
  const [mm, setMm] = React.useState("");
  const [yyyy, setYyyy] = React.useState("");

  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [avatarErrorMessage, setAvatarErrorMessage] = React.useState("");
  const [avatarSuccessMessage, setAvatarSuccessMessage] = React.useState("");

  const { data } = useQuery(["userDetails"], async () => {
    const token = userDetails?.token;
    if (!token) return;
    const response = await fetch(`${ROOT_URL}/api/v1/user`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const responseJson = await response.json();
    if (data?.error?.name === "TokenExpiredError") {
      logout(dispatch);
    }
    !!responseJson?.username && setUsername(responseJson?.username);

    (!!responseJson?.healthData?.height ||
      responseJson?.healthData?.height === 0) &&
      setHeight(responseJson.healthData.height);
    (!!responseJson?.healthData?.weight ||
      responseJson?.healthData?.weight === 0) &&
      setWeight(responseJson.healthData.weight);
    if (!!responseJson.healthData?.dob) {
      setDd(responseJson.healthData.dob.split("-")[2]);
      setMm(responseJson.healthData.dob.split("-")[1]);
      setYyyy(responseJson.healthData.dob.split("-")[0]);
    } else {
      setDd("");
      setMm("");
      setYyyy("");
    }

    return responseJson;
  });

  const updateUserMutation = useMutation(
    async () => {
      const dob = yyyy && mm && dd ? String(yyyy + "-" + mm + "-" + dd) : null;
      const payload = {
        username,
        password,
        height: height ? +height : null,
        weight: weight ? +weight : null,
        birthdate: dob,
      };
      return await fetch(`${ROOT_URL}/api/v1/user`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${userDetails?.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
    },
    {
      onSettled: (data, error, variables, context) => {
        if (!!data?.ok) {
          setSuccessMessage(t("settings.accountDetailsUpdateSuccess"));
          setErrorMessage("");
        } else {
          setSuccessMessage("");
          setErrorMessage(t("settings.accountDetailsUpdateError"));
        }
        queryClient.invalidateQueries(["user"]);
      },
    }
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateUserMutation.mutate();
  };

  const handleImageSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("avatar", avatar);

      const token = userDetails?.token;
      if (!token) return;

      const response = await fetch(`${ROOT_URL}/api/v1/profile`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const data = await response.json();

      if (!response.ok) {
        !!data?.error?.message && setAvatarErrorMessage(t("settings.avatarUpdateError"));
        !!data?.error?.message && setAvatarSuccessMessage("");
        return;
      }

      !!data?.success?.message && setAvatarSuccessMessage(t("settings.avatarUpdateSuccess"));
      !!data?.success?.message && setAvatarErrorMessage("");
    } catch (err) {
      console.error(err);
      return;
    }
  };

  if (!data)
    return (
      <Layout headline={t("settings.headline")}>
        <LoadingSpinner />
      </Layout>
    );

  return (
    <Layout headline={t("settings.headline")}>
      <div className={Styles.container}>
        <form className={Styles.form} onSubmit={handleImageSubmit}>
          <h3>{t("settings.profileImage")}</h3>
          <picture>
            {!avatar && !!data?.profileImage && (
              <img
                srcSet={`${ROOT_URL}/public/images/${data.profileImage}-320x320.jpg 320w, ${ROOT_URL}/public/images/${data.profileImage}-640x640.jpg 640w, ${ROOT_URL}/public/images/${data.profileImage}-960x960.jpg 960w, ${ROOT_URL}/public/images/${data.profileImage}-1200x1200.jpg 1200w`}
                sizes="320px"
                src={`${ROOT_URL}/public/images/${data.profileImage}-320x320.jpg`}
                alt={data.username}
              />
            )}
            {avatar && (
              <img
                src={URL.createObjectURL(avatar)}
                className={Styles.previewImage}
                alt=""
              />
            )}

            {!avatar && !data?.profileImage && (
              <img src={SpheryAppDefaultImageMale} alt="" />
            )}
          </picture>

          <label htmlFor="avatar" className={Styles.button}>
            {t("common.buttons.browseImages")}
            <input
              id="avatar"
              type="file"
              name="avatar"
              onChange={(e) => setAvatar(e.target.files[0])}
            />
          </label>

          <input
            type="submit"
            value={t("common.buttons.uploadImage")}
            disabled={!avatar}
            accept="image/jpeg, image/png, image/jpg, image/webp"
          />

          {avatarErrorMessage && (
            <p className={Styles.error}>{avatarErrorMessage}</p>
          )}
          {avatarSuccessMessage && (
            <p className={Styles.success}>{avatarSuccessMessage}</p>
          )}
        </form>

        <form className={Styles.form} onSubmit={handleSubmit}>
          <h3>{t("settings.accountDetails")}</h3>

          <label>
            {t("common.inputPlaceholders.username")}
            <input
              id="username"
              type="text"
              name="username"
              value={username}
              placeholder={t("common.inputPlaceholders.username")}
              onChange={(e) => setUsername(e.target.value)}
              autoComplete="off"
            />
          </label>

          <label>
          {t("common.inputPlaceholders.newPassword")}
            <input
              id="password"
              type="password"
              name="password"
              value={password}
              placeholder={t("common.inputPlaceholders.newPassword")}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="off"
              minLength="4"
              pattern="[a-zA-Z0-9!@#$%^*_|]{4,100}"
            />
          </label>

          <p className={Styles.warning}>
          {t("common.passwordInstructions")}
          </p>

          <label>
          {t("common.inputPlaceholders.height")}
            <input
              type="number"
              name="height"
              value={height}
              placeholder={t("common.inputPlaceholders.height")}
              onChange={(e) => setHeight(e.target.value)}
              autoComplete="off"
            />
          </label>
          <label>
          {t("common.inputPlaceholders.weight")}
            <input
              type="number"
              name="weight"
              value={weight}
              placeholder={t("common.inputPlaceholders.weight")}
              onChange={(e) => setWeight(e.target.value)}
              autoComplete="off"
            />
          </label>
          <label>
          {t("common.inputPlaceholders.dateOfBirth")}
            <div className={Styles.formRow}>
              <input
                type="text"
                inputMode="numeric"
                name="dd"
                placeholder={t("common.inputPlaceholders.day")}
                value={dd}
                onChange={(e) => setDd(e.target.value)}
                autoComplete="off"
                minLength="2"
                maxLength="2"
              />
              <input
                type="text"
                inputMode="numeric"
                name="mm"
                placeholder={t("common.inputPlaceholders.month")}
                value={mm}
                onChange={(e) => setMm(e.target.value)}
                autoComplete="off"
                minLength="2"
                maxLength="2"
              />
              <input
                type="text"
                inputMode="numeric"
                name="yyyy"
                placeholder={t("common.inputPlaceholders.year")}
                value={yyyy}
                onChange={(e) => setYyyy(e.target.value)}
                autoComplete="off"
                minLength="4"
                maxLength="4"
              />
            </div>
          </label>

          <input type="submit" value={t("common.buttons.saveAccountDetails")} />

          {errorMessage && <p className={Styles.error}>{errorMessage}</p>}
          {successMessage && (
            <p className={Styles.success}>{successMessage}</p>
          )}
        </form>
      </div>
    </Layout>
  );
};

export default Settings;
