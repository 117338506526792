import React from "react";
import Styles from "./Alert.module.scss";

const Alert = ({ message, color, dismissible, onClick }) => {
  return (
    <div
      className={`${Styles.alert} ${Styles[color]} ${
        dismissible && Styles.dismissible
      }`}
      role="alert"
    >
      {message}
      {dismissible && (
        <button
          type="button"
          data-dismiss="alert"
          aria-label="Close"
          onClick={() => onClick(false)}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      )}
    </div>
  );
};

export default Alert;
