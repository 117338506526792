const Community = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6562 7.01316H12.0901C11.8769 7.01316 11.6705 7.04229 11.4741 7.09585C11.0867 6.33423 10.2957 5.81091 9.38422 5.81091H6.61578C5.70431 5.81091 4.91331 6.33423 4.52588 7.09585C4.32947 7.04229 4.12309 7.01316 3.90994 7.01316H2.34375C1.05141 7.01316 0 8.06457 0 9.35691V14.8606C0 15.636 0.630844 16.2668 1.40625 16.2668H14.5938C15.3692 16.2668 16 15.636 16 14.8606V9.35691C16 8.06457 14.9486 7.01316 13.6562 7.01316ZM4.27203 8.15473V15.3294H1.40625C1.14778 15.3294 0.9375 15.1191 0.9375 14.8606V9.35694C0.9375 8.58154 1.56834 7.95069 2.34375 7.95069H3.90994C4.03719 7.95069 4.16041 7.96798 4.27772 7.99982C4.27434 8.0511 4.27203 8.10266 4.27203 8.15473ZM10.7905 15.3294H5.20953V8.15469C5.20953 7.37929 5.84037 6.74844 6.61578 6.74844H9.38422C10.1596 6.74844 10.7905 7.37929 10.7905 8.15469V15.3294ZM15.0625 14.8606C15.0625 15.1191 14.8522 15.3294 14.5938 15.3294H11.728V8.15469C11.728 8.1026 11.7257 8.05104 11.7223 7.99979C11.8396 7.96798 11.9628 7.95066 12.0901 7.95066H13.6562C14.4317 7.95066 15.0625 8.58151 15.0625 9.35691V14.8606Z"
        fill="white"
      />
      <path
        d="M3.12688 2.58667C1.97847 2.58667 1.04419 3.52095 1.04419 4.66936C1.04416 5.81776 1.97847 6.75204 3.12688 6.75204C4.27525 6.75204 5.20956 5.81776 5.20956 4.66936C5.20956 3.52095 4.27528 2.58667 3.12688 2.58667ZM3.12685 5.81455C2.49538 5.81455 1.98166 5.30083 1.98166 4.66936C1.98166 4.03789 2.49538 3.52417 3.12685 3.52417C3.75831 3.52417 4.27203 4.03789 4.27203 4.66936C4.27203 5.30083 3.75831 5.81455 3.12685 5.81455Z"
        fill="white"
      />
      <path
        d="M7.99997 0C6.46572 0 5.21753 1.24819 5.21753 2.78244C5.21753 4.31669 6.46572 5.56487 7.99997 5.56487C9.53422 5.56487 10.7824 4.31669 10.7824 2.78244C10.7824 1.24822 9.53422 0 7.99997 0ZM7.99997 4.62737C6.98265 4.62737 6.15503 3.79975 6.15503 2.78244C6.15503 1.76516 6.98265 0.9375 7.99997 0.9375C9.01728 0.9375 9.8449 1.76512 9.8449 2.78244C9.8449 3.79975 9.01728 4.62737 7.99997 4.62737Z"
        fill="white"
      />
      <path
        d="M12.8732 2.58667C11.7248 2.58667 10.7905 3.52095 10.7905 4.66936C10.7906 5.81776 11.7248 6.75204 12.8732 6.75204C14.0216 6.75204 14.9559 5.81776 14.9559 4.66936C14.9559 3.52095 14.0216 2.58667 12.8732 2.58667ZM12.8732 5.81455C12.2418 5.81455 11.728 5.30083 11.728 4.66936C11.7281 4.03789 12.2418 3.52417 12.8732 3.52417C13.5047 3.52417 14.0184 4.03789 14.0184 4.66936C14.0184 5.30083 13.5047 5.81455 12.8732 5.81455Z"
        fill="white"
      />
    </svg>
  );
};
export default Community;
