import React from "react";
import Styles from "./WorkoutStat.module.scss";
import { CountUp } from "use-count-up";
import { useTranslation } from "react-i18next";

const BrainScoreStat = ({ value }) => {
  const { t } = useTranslation();

  return (
    <div className={Styles.workoutStat}>
      <h4>{t("common.stats.brainScore")}</h4>
      <p>
        {value && (
          <CountUp
            isCounting
            end={value * 100}
            duration={0.25}
            decimalPlaces={2}
            decimalSeparator={t("common.decimalSeparator")}
            thousandsSeparator={t("common.thousandsSeparator")}
          />
        )}
        {value ? "%" : "–"}
      </p>
    </div>
  );
};

export default BrainScoreStat;
