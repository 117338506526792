import React from "react";
import { FaUserAlt } from "react-icons/fa";
import Challenge from "../../Assets/Challenge";
import ROOT_URL from "../../Config/rootUrl";
import Styles from "./User.module.scss";

const User = ({ user, select, selectedUsers, setSelectedUsers }) => {
  const handleClick = () => {
    if (selectedUsers.includes(user.id)) {
      setSelectedUsers(selectedUsers.filter((item) => item !== user.id));
    }

    if (selectedUsers.length < 5 && !selectedUsers.includes(user.id)) {
      setSelectedUsers([...selectedUsers, user.id]);
    }
  };

  return (
    <div className={Styles.outerContainer} onClick={handleClick}>
      <div className={Styles.innerContainer}>
        <div className={Styles.userEntry}>
          <div className={Styles.user}>
            <div className={Styles.circle}>
              {user.profileImage ? (
                <img
                  srcSet={`${ROOT_URL}/public/images/${user.profileImage}-40x40.jpg 40w, ${ROOT_URL}/public/images/${user.profileImage}-80x80.jpg 80w, ${ROOT_URL}/public/images/${user.profileImage}-120x120.jpg 120w, ${ROOT_URL}/public/images/${user.profileImage}-160x160.jpg 160w`}
                  sizes="40px"
                  src={`${ROOT_URL}/public/images/${user.profileImage}-40x40.jpg`}
                  alt={user.username}
                />
              ) : (
                <FaUserAlt className={Styles.placeholder} />
              )}
            </div>
            <h3>{user.username}&nbsp;</h3>
          </div>
        </div>
      </div>
      <div
        className={`${Styles.selector} ${
          selectedUsers.includes(user.id) ? Styles.active : ""
        }`}
      >
        <div
          className={`${Styles.circle} ${
            selectedUsers.length >= 5 ? Styles.disabled : ""
          }`}
        >
          <Challenge className={Styles.placeholder} />
        </div>
      </div>
    </div>
  );
};

export default User;
