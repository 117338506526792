import React from "react";
import Container from "../Container/Container";
import EllipseIcon from "../../Assets/EllipseIcon.js";
import Styles from "./Layout.module.scss";
import Menu from "./Menu";
import Hamburger from "./Hamburger";

const Header = ({
  headline,
  HeadlineIcon,
  subheadline,
  headlinePositionAbsolute,
}) => {
  const [menuOpen, setMenuOpen] = React.useState(false);

  React.useEffect(() => {
    if (menuOpen) {
      document.body.classList.add("menu-open");
    } else {
      document.body.classList.remove("menu-open");
    }
  }, [menuOpen]);

  return (
    <div className={Styles.header}>
      <Container>
        <div className={Styles.headerInner}>
          {headline && (
            <h1
              className={`${headlinePositionAbsolute ? Styles.absolute : ""}`}
            >
              {headline}
              {HeadlineIcon && <HeadlineIcon />}
            </h1>
          )}
          {subheadline && (
            <p>
              <EllipseIcon />
              {subheadline}
            </p>
          )}
          <Hamburger setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
          {menuOpen && <Menu setMenuOpen={setMenuOpen} />}
        </div>
      </Container>
    </div>
  );
};

export default Header;
