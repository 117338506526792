import React from "react";
import { Link } from "react-router-dom";
import { FaUserAlt } from "react-icons/fa";
import { CountUp } from "use-count-up";
import ROOT_URL from "../../Config/rootUrl";

import Styles from "./Challenge.module.scss";
import { Trans, useTranslation } from "react-i18next";

const Challenge = ({ challenge, username }) => {
  const [isInitiator, setIsInitiator] = React.useState();
  const [isPastChallenge, setIsPastChallenge] = React.useState();
  const [hasChallengeWinner, setHasChallengeWinner] = React.useState();
  const { t } = useTranslation();

  React.useEffect(() => {
    challenge.initiator.username === username && setIsInitiator(true);
    challenge.completedChallenge && setIsPastChallenge(true);
    challenge.winner && setHasChallengeWinner(true);
  }, [challenge, username]);

  return (
    <Link to={`/challenge/${challenge.id}`} className={Styles.challenge}>
      <div className={Styles.info}>
        <p className={Styles.action}>
          {isPastChallenge ? (
            <>
              {hasChallengeWinner ? (
                challenge.winner?.username === username ? (
                  <Trans i18nKey={"challenges.challengesPast.youWonVersus"}>
                    <span className={Styles.won}></span>
                    <span>
                      {{
                        username: isInitiator
                          ? challenge.opponent.username
                          : challenge.initiator.username,
                      }}
                    </span>
                  </Trans>
                ) : (
                  <Trans i18nKey={"challenges.challengesPast.youLostVersus"}>
                    <span className={Styles.lost}></span>
                    <span>
                      {{
                        username: isInitiator
                          ? challenge.opponent.username
                          : challenge.initiator.username,
                      }}
                    </span>
                  </Trans>
                )
              ) : (
                <span className={Styles.draw}>
                  {t("challenges.challengesPast.itsADraw")}
                </span>
              )}
            </>
          ) : (
            <Trans i18nKey={"challenges.challengesOngoing.youVersus"}>
              <span>
                {{
                  username: isInitiator
                    ? challenge.opponent.username
                    : challenge.initiator.username,
                }}
              </span>
            </Trans>
          )}
        </p>
      </div>

      <div
        className={`${Styles.challengers} ${
          isInitiator ? Styles.initiator : Styles.opponent
        }`}
      >
        <div className={Styles.user}>
          <div className={Styles.circle}>
            {challenge.initiator.profileImage ? (
              <img
                srcSet={`${ROOT_URL}/public/images/${challenge.initiator.profileImage}-40x40.jpg 40w, ${ROOT_URL}/public/images/${challenge.initiator.profileImage}-80x80.jpg 80w, ${ROOT_URL}/public/images/${challenge.initiator.profileImage}-120x120.jpg 120w, ${ROOT_URL}/public/images/${challenge.initiator.profileImage}-160x160.jpg 160w`}
                sizes="40px"
                src={`${ROOT_URL}/public/images/${challenge.initiator.profileImage}-40x40.jpg`}
                alt={challenge.initiator.username}
              />
            ) : (
              <FaUserAlt className={Styles.placeholder} />
            )}
          </div>
        </div>

        <div className={Styles.details}>
          <h3>{challenge.rounds[0]?.initiatorWorkout?.workoutPreset.name}</h3>
          <p className={Styles.duration}>
            <CountUp
              isCounting
              end={
                challenge.rounds[0]?.initiatorWorkout?.raceConfig.duration / 60
              }
              duration={0.25}
              decimalPlaces={0}
            />{" "}
            {t("common.minutes", {
              count:
                challenge.rounds[0]?.initiatorWorkout?.raceConfig.duration / 60,
            })}
          </p>
          <div className={Styles.scoreContainer}>
            <div className={Styles.leftScores}>
              <div>
                <span
                  className={`${
                    challenge.rounds[0]?.winner
                      ? challenge.rounds[0]?.winner?.username === username
                        ? Styles.won
                        : Styles.lost
                      : (isInitiator &&
                          challenge.rounds[0]?.initiatorWorkout &&
                          !challenge.rounds[0]?.opponentWorkout) ||
                        (!isInitiator &&
                          !challenge.rounds[0]?.initiatorWorkout &&
                          challenge.rounds[0]?.opponentWorkout)
                      ? Styles.played
                      : challenge.rounds[0] &&
                        challenge.rounds[0]?.initiatorWorkout?.score ===
                          challenge.rounds[0]?.opponentWorkout?.score
                      ? Styles.won
                      : ""
                  }`}
                ></span>
              </div>
              <div>
                <span
                  className={`${
                    challenge.rounds[1]?.winner
                      ? challenge.rounds[1]?.winner?.username === username
                        ? Styles.won
                        : Styles.lost
                      : (isInitiator &&
                          challenge.rounds[1]?.initiatorWorkout &&
                          !challenge.rounds[1]?.opponentWorkout) ||
                        (!isInitiator &&
                          !challenge.rounds[1]?.initiatorWorkout &&
                          challenge.rounds[1]?.opponentWorkout)
                      ? Styles.played
                      : challenge.rounds[1] &&
                        challenge.rounds[1]?.initiatorWorkout?.score ===
                          challenge.rounds[1]?.opponentWorkout?.score
                      ? Styles.won
                      : ""
                  }`}
                ></span>
              </div>
              {challenge.rounds.length >= 3 && (
                <div>
                  <span
                    className={`${
                      challenge.rounds[2]?.winner
                        ? challenge.rounds[2]?.winner?.username === username
                          ? Styles.won
                          : Styles.lost
                        : (isInitiator &&
                            challenge.rounds[2]?.initiatorWorkout &&
                            !challenge.rounds[2]?.opponentWorkout) ||
                          (!isInitiator &&
                            !challenge.rounds[2]?.initiatorWorkout &&
                            challenge.rounds[2]?.opponentWorkout)
                        ? Styles.played
                        : challenge.rounds[2] &&
                          challenge.rounds[2]?.initiatorWorkout?.score ===
                            challenge.rounds[2]?.opponentWorkout?.score
                        ? Styles.won
                        : ""
                    }`}
                  ></span>
                </div>
              )}
            </div>

            <span className={Styles.score}>
              <>
                {isPastChallenge && !hasChallengeWinner ? (
                  <span className={Styles.draw}>{t("challenges.challengesPast.draw")}</span>
                ) : (
                  <>
                    <CountUp
                      isCounting
                      end={
                        isInitiator
                          ? challenge.rounds.filter(
                              (round) =>
                                round.winner?.username ===
                                challenge.initiator?.username
                            ).length
                          : challenge.rounds.filter(
                              (round) =>
                                round.winner?.username ===
                                challenge.opponent?.username
                            ).length
                      }
                      duration={0.25}
                      decimalPlaces={0}
                    />
                    :
                    <CountUp
                      isCounting
                      end={
                        isInitiator
                          ? challenge.rounds.filter(
                              (round) =>
                                round.winner &&
                                round.winner?.username ===
                                  challenge.opponent?.username
                            ).length
                          : challenge.rounds.filter(
                              (round) =>
                                round.winner?.username ===
                                challenge.initiator?.username
                            ).length
                      }
                      duration={0.25}
                      decimalPlaces={0}
                    />
                  </>
                )}
              </>
            </span>

            <div className={Styles.rightScores}>
              {challenge.rounds.length >= 3 && (
                <div>
                  <span
                    className={`${
                      challenge.rounds[2]?.winner
                        ? challenge.rounds[2]?.winner?.username !== username
                          ? Styles.won
                          : Styles.lost
                        : (!isInitiator &&
                            challenge.rounds[2]?.initiatorWorkout &&
                            !challenge.rounds[2]?.opponentWorkout) ||
                          (isInitiator &&
                            !challenge.rounds[2]?.initiatorWorkout &&
                            challenge.rounds[2]?.opponentWorkout)
                        ? Styles.played
                        : challenge.rounds[2] &&
                          challenge.rounds[2]?.initiatorWorkout?.score ===
                            challenge.rounds[2]?.opponentWorkout?.score
                        ? Styles.won
                        : ""
                    }`}
                  ></span>
                </div>
              )}

              <div>
                <span
                  className={`${
                    challenge.rounds[1]?.winner
                      ? challenge.rounds[1]?.winner?.username !== username
                        ? Styles.won
                        : Styles.lost
                      : (!isInitiator &&
                          challenge.rounds[1]?.initiatorWorkout &&
                          !challenge.rounds[1]?.opponentWorkout) ||
                        (isInitiator &&
                          !challenge.rounds[1]?.initiatorWorkout &&
                          challenge.rounds[1]?.opponentWorkout)
                      ? Styles.played
                      : challenge.rounds[1] &&
                        challenge.rounds[1]?.initiatorWorkout?.score ===
                          challenge.rounds[1]?.opponentWorkout?.score
                      ? Styles.won
                      : ""
                  }`}
                ></span>
              </div>
              <div>
                <span
                  className={`${
                    challenge.rounds[0]?.winner
                      ? challenge.rounds[0]?.winner?.username !== username
                        ? Styles.won
                        : Styles.lost
                      : (!isInitiator &&
                          challenge.rounds[0]?.initiatorWorkout &&
                          !challenge.rounds[0]?.opponentWorkout) ||
                        (isInitiator &&
                          !challenge.rounds[0]?.initiatorWorkout &&
                          challenge.rounds[0]?.opponentWorkout)
                      ? Styles.played
                      : challenge.rounds[0] &&
                        challenge.rounds[0]?.initiatorWorkout?.score ===
                          challenge.rounds[0]?.opponentWorkout?.score
                      ? Styles.won
                      : ""
                  }`}
                ></span>
              </div>
            </div>
          </div>
        </div>

        <div className={Styles.user}>
          <div className={Styles.circle}>
            {challenge.opponent.profileImage ? (
              <img
                srcSet={`${ROOT_URL}/public/images/${challenge.opponent.profileImage}-40x40.jpg 40w, ${ROOT_URL}/public/images/${challenge.opponent.profileImage}-80x80.jpg 80w, ${ROOT_URL}/public/images/${challenge.opponent.profileImage}-120x120.jpg 120w, ${ROOT_URL}/public/images/${challenge.opponent.profileImage}-160x160.jpg 160w`}
                sizes="40px"
                src={`${ROOT_URL}/public/images/${challenge.opponent.profileImage}-40x40.jpg`}
                alt={challenge.opponent.username}
              />
            ) : (
              <FaUserAlt className={Styles.placeholder} />
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Challenge;
