const Home = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5703 6.95923C15.5698 6.95886 15.5695 6.95837 15.5691 6.95801L9.0417 0.431519C8.76348 0.153198 8.39357 0 8.00009 0C7.60662 0 7.23671 0.153198 6.95836 0.431519L0.434387 6.95471C0.432189 6.95691 0.429869 6.95923 0.427794 6.96143C-0.143553 7.53601 -0.142576 8.46826 0.430602 9.04138C0.692469 9.30334 1.03821 9.45496 1.408 9.47095C1.42313 9.47241 1.43827 9.47314 1.45353 9.47314H1.71357V14.2761C1.71357 15.2267 2.48708 16 3.43774 16H5.99147C6.2504 16 6.46026 15.79 6.46026 15.5312V11.7656C6.46026 11.3319 6.8132 10.9791 7.24696 10.9791H8.75322C9.18698 10.9791 9.5398 11.3319 9.5398 11.7656V15.5312C9.5398 15.79 9.74966 16 10.0086 16H12.5623C13.5131 16 14.2865 15.2267 14.2865 14.2761V9.47314H14.5277C14.9211 9.47314 15.291 9.31995 15.5695 9.0415C16.1432 8.46753 16.1435 7.53357 15.5703 6.95923ZM14.9064 8.37866C14.8052 8.47986 14.6707 8.53564 14.5277 8.53564H13.8177C13.5588 8.53564 13.3489 8.74548 13.3489 9.00439V14.2761C13.3489 14.7097 12.9961 15.0625 12.5623 15.0625H10.4774V11.7656C10.4774 10.8151 9.704 10.0416 8.75322 10.0416H7.24696C6.29618 10.0416 5.52267 10.8151 5.52267 11.7656V15.0625H3.43774C3.0041 15.0625 2.65116 14.7097 2.65116 14.2761V9.00439C2.65116 8.74548 2.4413 8.53564 2.18237 8.53564H1.48454C1.47722 8.53516 1.47001 8.53479 1.46257 8.53467C1.3229 8.53223 1.19191 8.47681 1.09375 8.37854C0.884994 8.1698 0.884994 7.83008 1.09375 7.62122C1.09388 7.62122 1.09388 7.62109 1.094 7.62097L1.09437 7.6206L7.62151 1.09436C7.7226 0.993164 7.85701 0.9375 8.00009 0.9375C8.14305 0.9375 8.27746 0.993164 8.37867 1.09436L14.9044 7.61926C14.9053 7.62024 14.9064 7.62122 14.9074 7.62219C15.1151 7.8313 15.1147 8.17029 14.9064 8.37866Z"
        fill="white"
      />
    </svg>
  );
};

export default Home;
