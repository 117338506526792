let ROOT_URL;

if (process.env.NODE_ENV === "development") {
  ROOT_URL = process.env.REACT_APP_ROOT_URL || "";
} else {
  ROOT_URL = "";
}

// ROOT_URL = "https://devapp.sphery.ch"

export default ROOT_URL;
