import React from "react";
import { FaUserAlt, FaTrashAlt } from "react-icons/fa";
// import moment from "moment";
import { useNavigate } from "react-router-dom";

import {
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";

import Styles from "./NewsList.module.scss";

import ROOT_URL from "../../Config/rootUrl";
import { useTranslation } from "react-i18next";

const trailingActions = (markMessageAsReadMutation, messageId) => (
  <TrailingActions>
    <SwipeAction
      destructive={true}
      onClick={() => markMessageAsReadMutation.mutate(messageId)}
    >
      <div className={Styles.deleteAction}>
        <FaTrashAlt />
      </div>
    </SwipeAction>
  </TrailingActions>
);

const NewsList = ({ messages, markMessageAsReadMutation, newsHeadline }) => {
  const navigate = useNavigate();
  const [swiping, setSwiping] = React.useState(false);
  const { i18n } = useTranslation();

  return (
    <div className={Styles.newsContainer}>
      <svg viewBox="0 0 100 15" className={Styles.arrowTop}>
        <polygon points="0,0 0,15 100,0" />
      </svg>

      <div className={Styles.news}>
        <div className={Styles.newsInner}>
          <h2>{newsHeadline}</h2>

          <div className={Styles.messageList}>
            <SwipeableList>
              {messages.map((message) => (
                <SwipeableListItem
                  trailingActions={trailingActions(
                    markMessageAsReadMutation,
                    message.id
                  )}
                  onSwipeStart={() => setSwiping(true)}
                  onSwipeEnd={() =>
                    setTimeout(() => {
                      setSwiping(false);
                    }, 250)
                  }
                  key={message.id}
                >
                  <div
                    className={`${Styles.message} ${
                      swiping ? Styles.swiping : ""
                    } ${!!message.link ? Styles.link : ""}`}
                    onClick={() =>
                      !!message.link && !swiping && navigate(message.link)
                    }
                  >
                    <div className={Styles.circle}>
                      {message.sender.profileImage ? (
                        <img
                          srcSet={`${ROOT_URL}/public/images/${message.sender.profileImage}-40x40.jpg 40w, ${ROOT_URL}/public/images/${message.sender.profileImage}-80x80.jpg 80w, ${ROOT_URL}/public/images/${message.sender.profileImage}-120x120.jpg 120w, ${ROOT_URL}/public/images/${message.sender.profileImage}-160x160.jpg 160w`}
                          sizes="40px"
                          src={`${ROOT_URL}/public/images/${message.sender.profileImage}-40x40.jpg`}
                          alt={message.sender.username}
                        />
                      ) : (
                        <FaUserAlt className={Styles.placeholder} />
                      )}
                    </div>
                    <div className={Styles.messageContent}>
                      <h3>{message.title}&nbsp;</h3>
                      {!!message.body && (
                        <p dangerouslySetInnerHTML={{ __html: message.body }} />
                      )}
                      <p className={Styles.dateSeparator}>
                        {/* {moment(message.createdAt).format("dddd, DD.MM.YYYY")} */}
                        {new Intl.DateTimeFormat(i18n.resolvedLanguage === "en" ? "en-GB" : i18n.resolvedLanguage, {
                          weekday: "long",
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        }).format(new Date(message.createdAt))}
                      </p>
                    </div>
                  </div>
                </SwipeableListItem>
              ))}
            </SwipeableList>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsList;
