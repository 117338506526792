import React from "react";
import { useAuthState, useAuthDispatch, logout } from "../../Context";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Layout from "../../Components/Layout/Layout";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import ROOT_URL from "../../Config/rootUrl";
import Styles from "./ChallengesPast.module.scss";
import Challenge from "../../Components/Challenge/Challenge";
import { useTranslation } from "react-i18next";

const ChallengesPast = () => {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();
  const {t} = useTranslation();

  const navigate = useNavigate();
  const [selected, setSelected] = React.useState("past");

  React.useEffect(() => {
    selected === "overview" && navigate("/challenges");
    selected !== "past" &&
      selected !== "overview" &&
      navigate(`/challenges-${selected}`);
  }, [selected, navigate]);

  const pastChallenge = useQuery(["getPastChallenges"], async () => {
    const response = await fetch(`${ROOT_URL}/api/v1/challenge/completed`, {
      headers: {
        Authorization: `Bearer ${userDetails?.token}`,
      },
    });

    const responseJson = await response.json();
    if (responseJson?.error?.name === "TokenExpiredError") {
      logout(dispatch);
    }
    return responseJson;
  });

  if (!pastChallenge?.data)
    return (
      <Layout headline={t("challenges.headline")}>
        <LoadingSpinner />
      </Layout>
    );

  return (
    <Layout headline={t("challenges.headline")}>
      <div className={Styles.challengeSelector}>
        <select
          className={Styles.select}
          multiple={false}
          value={selected}
          onChange={(e) => setSelected(e.target.value)}
        >
          <option value="overview">{t("challenges.overview")}</option>
          <option value="ongoing">{t("challenges.ongoing")}</option>
          <option value="past">{t("challenges.past")}</option>
        </select>
      </div>

      {pastChallenge?.data?.length > 0 && (
        <div className={Styles.section}>
          <div className={Styles.container}>
            {pastChallenge?.data.map((challenge) => {
              return (
                <Challenge
                  key={challenge.id}
                  challenge={challenge}
                  username={userDetails.user.username}
                />
              );
            })}
          </div>
        </div>
      )}

      {/* <div className={Styles.row}>
        <div className={Styles.column}>
          <h3>{pastChallenge?.success?.message}</h3>
          {pastChallenge?.data && (
            <pre style={{ fontSize: 8 }}>
              {JSON.stringify(pastChallenge?.data, null, 4)}
            </pre>
          )}
        </div>
      </div> */}
    </Layout>
  );
};
export default ChallengesPast;
