import React, { useState, useEffect, useCallback } from "react";
import {
  useAuthState,
  useAuthDispatch,
  logout,
  replaceUserAndToken,
} from "../../Context";
import { useQueryClient } from "react-query";
import Question from "../../Components/Question/Question";
import Styles from "./Quiz.module.scss";
import Container from "../../Components/Container/Container";
import ROOT_URL from "../../Config/rootUrl";
import { Link } from "react-router-dom";
import Layout from "../../Components/Layout/Layout";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";

const Quiz = () => {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();
  const queryClient = useQueryClient();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [timeLeft, setTimeLeft] = useState(0);
  const [instructions, setInstructions] = useState("");
  const [hasStarted, setHasStarted] = useState(false);
  const [quizSubmitted, setQuizSubmitted] = useState(false);
  const [quizStatus, setQuizStatus] = useState({});
  const [quizId, setQuizId] = useState(null);

  useEffect(() => {
    const fetchRecipe = async () => {
      try {
        const token = userDetails?.token;
        if (!token) return;

        const response = await fetch(`${ROOT_URL}/api/v1/quiz/recipe`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await response.json();

        if (data?.error?.name === "TokenExpiredError") {
          logout(dispatch);
        }

        if (response.ok) {
          setTimeLeft(data.quizTimeLimit);
          setInstructions(data.quizInstructions);
          setLoading(false);
        } else {
          setError(data.error?.message || "Failed to fetch recipe");
          setLoading(false);
        }
      } catch (error) {
        setError("Failed to fetch recipe");
        setLoading(false);
      }
    };

    fetchRecipe();
  }, [dispatch, userDetails?.token]);

  const fetchQuestions = async () => {
    try {
      const token = userDetails?.token;
      if (!token) return;

      const response = await fetch(`${ROOT_URL}/api/v1/quiz/create`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (data?.error?.name === "TokenExpiredError") {
        logout(dispatch);
      }

      if (response.ok) {
        setQuizId(data.quizId);
        setQuestions(data.questions || []);
        setUserAnswers(new Array(data.questions.length).fill(null));
      } else {
        setError(data.error?.message || "Failed to fetch questions");
      }
    } catch (error) {
      setError("Failed to fetch questions");
    }
  };

  const handleSubmit = useCallback(
    async (status = "completed") => {
      try {
        const requestBody = questions.map((question, index) => ({
          id: question.id,
          category: question.category || "unknown",
          selectedAnswer: userAnswers[index] || "",
        }));
  
        const token = userDetails?.token;
        if (!token) return;
  
        const response = await fetch(`${ROOT_URL}/api/v1/quiz/update`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            quizId,
            questions: requestBody,
          }),
        });
  
        const result = await response.json();
  
        if (result?.error?.name === "TokenExpiredError") {
          logout(dispatch);
        }
  
        if (response.ok) {
          setQuizStatus({
            status,
            passed: result.userHasPassedTest,
            percentageCorrect: result.percentageCorrect,
          });
          setQuizSubmitted(true);
          setHasStarted(false);
  
          // Check if the response includes a new token and user details
          if (result.token) {
            replaceUserAndToken(dispatch, {
              token: result.token,
              user: {
                id: result.user.id,
                email: result.user.email,
                username: result.user.username,
                role: result.user.role,
                profileImage: result.user.profileImage || null,
              },
            });
            queryClient.invalidateQueries(["getOverlay"]); // make sure, we get overlays refreshed
          }
        } else {
          setError(result.message || "Failed to submit quiz");
        }
      } catch (error) {
        setError("Error submitting quiz");
      }
    },
    [userAnswers, questions, quizId, dispatch, userDetails?.token, queryClient]
  );  

  useEffect(() => {
    if (hasStarted) {
      const timer = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            handleSubmit("expired");
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue =
          "Are you sure you want to leave? Your progress will be lost.";
      };

      window.addEventListener("beforeunload", handleBeforeUnload);

      return () => {
        clearInterval(timer);
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [hasStarted, handleSubmit]);

  const handleStart = async () => {
    await fetchQuestions();
    setHasStarted(true);
  };

  const handleAnswerSelect = (answer) => {
    const newAnswers = [...userAnswers];
    newAnswers[currentQuestionIndex] = answer;
    setUserAnswers(newAnswers);
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes < 10 ? "0" + minutes : minutes}:${
      secs < 10 ? "0" : ""
    }${secs}`;
  };

  const allQuestionsAnswered = userAnswers.every((answer) => answer !== null);

  if (quizSubmitted) {
    return (
      <Layout>
        <div className={Styles.narrow}>
          <p>
            {quizStatus.status === "expired"
              ? "Die Zeit ist rum."
              : "Alle Fragen wurden beantwortet."}
          </p>

          <h2>
            {quizStatus.passed
              ? "Herzlichen Glückwunsch! Du hast die Prüfung bestanden."
              : "Leider hat es noch nicht gereicht, die Prüfung wurde nicht bestanden."}
          </h2>

          <p>{`Dein Ergebnis: ${
            quizStatus.percentageCorrect
              ? quizStatus.percentageCorrect.toFixed(2)
              : 0
          }% richtig.`}</p>

          {!quizStatus.passed && (
            <p>Du kannst die Prüfung jederzeit nochmals versuchen.</p>
          )}

          {!quizStatus.passed ? (
            <Link
              to={`/quiz/${encodeURIComponent(quizId)}`}
              className={Styles.button}
            >
              Zum Ergebnis
            </Link>
          ) : (
            <Link
              to={`/`}
              className={Styles.button}
            >
              Zum Exerhub
            </Link>
          )}

        </div>
      </Layout>
    );
  }

  if (loading)
    return (
      <Layout>
        <div className={Styles.narrow}>
          <h2>
            Willkommen zur <br />
            Abschluss&shy;prüfung
          </h2>
          <LoadingSpinner />
        </div>
      </Layout>
    );

  if (error)
    return (
      <Layout>
        <div className={Styles.narrow}>
          <h2>
            Willkommen zur <br />
            Abschluss&shy;prüfung
          </h2>
          <p className={Styles.error}>{error}</p>
        </div>
      </Layout>
    );

  if (!hasStarted) {
    return (
      <Layout>
        <div className={Styles.narrow}>
          <h2>
            Willkommen zur <br />
            Abschluss&shy;prüfung
          </h2>
          <h4>Regeln:</h4>
          {instructions ? (
            <p
              dangerouslySetInnerHTML={{
                __html: instructions.replace(/\n/g, "<br />"),
              }}
            ></p>
          ) : null}
          <p>Verbleibende Zeit: {formatTime(timeLeft)}</p>

          <button onClick={handleStart}>Starten</button>
        </div>
      </Layout>
    );
  }

  return (
    <Container>
      <div className={Styles.header}>
        <div className={Styles.quizNavigation}>
          <button
            onClick={handlePreviousQuestion}
            disabled={currentQuestionIndex <= 0}
          >
            ←
          </button>

          <button
            onClick={handleNextQuestion}
            disabled={currentQuestionIndex >= questions.length - 1}
          >
            →
          </button>

          <p className={Styles.questionPagination}>
            {currentQuestionIndex + 1}/{questions.length}
          </p>
        </div>

        <p className={Styles.timer}>{formatTime(timeLeft)}</p>
      </div>

      <div className={Styles.narrow}>
        <div className={Styles.form}>
          <p>
            <a
              href={questions[currentQuestionIndex].link}
              target="_blank"
              rel="noreferrer"
            >
              Link zur Knowledgebase
            </a>
          </p>

          <Question
            question={questions[currentQuestionIndex]}
            selectedAnswer={userAnswers[currentQuestionIndex]}
            onAnswerSelect={handleAnswerSelect}
          />

          {allQuestionsAnswered ||
          currentQuestionIndex >= questions.length - 1 ? (
            <button
              className={Styles.submit}
              onClick={() => handleSubmit("completed")}
              disabled={!allQuestionsAnswered}
            >
              Abschliessen
            </button>
          ) : (
            <button
              onClick={handleNextQuestion}
              disabled={userAnswers[currentQuestionIndex] === null}
            >
              Nächste Frage
            </button>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Quiz;
