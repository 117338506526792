import React from "react";
import { useAuthState, useAuthDispatch, logout } from "../../Context";
import { useInfiniteQuery } from "react-query";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Layout from "../../Components/Layout/Layout";
import WorkoutList from "../../Components/WorkoutList/WorkoutList";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import video from "..//../Assets/AlexandraGIF-1.mp4";
import Home from "../../Assets/Home.js";
import Leaderboard from "../../Assets/Leaderboard.js";
import Settings from "../../Assets/Settings.js";
import Styles from "./Workouts.module.scss";

import ROOT_URL from "../../Config/rootUrl";
import { Trans, useTranslation } from "react-i18next";

const Workouts = () => {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();
  const [loaded, setLoaded] = React.useState(false);
  const [workoutData, setWorkoutData] = React.useState();

  const { t } = useTranslation();

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    "workouts",
    async ({ pageParam = 1 }) => {
      const token = userDetails?.token;
      if (!token) return;
      const response = await fetch(
        `${ROOT_URL}/api/v1/workout?page=${pageParam}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseJson = await response.json();
      if (data?.error?.name === "TokenExpiredError") {
        logout(dispatch);
      }
      return responseJson;
    },
    {
      getNextPageParam: (page) =>
        page.currentPage === page.totalPages ? undefined : page.currentPage + 1,
    }
  );

  React.useEffect(() => {
    try {
      const fetchedWorkoutData = data?.pages
        ?.map((entries) => entries.entries)
        .flat();

      // this gives an object with dates as keys
      const groups = fetchedWorkoutData?.reduce((groups, workout) => {
        const updatedAt = workout.updatedAt.split("T")[0];
        if (!groups[updatedAt]) {
          groups[updatedAt] = [];
        }
        groups[updatedAt].push(workout);
        return groups;
      }, {});

      // add it in the array format instead
      const groupArrays =
        groups &&
        Object.keys(groups).map((updatedAt) => {
          return {
            updatedAt,
            workouts: groups[updatedAt],
          };
        });

      setLoaded(true);
      setWorkoutData(groupArrays);
    } catch (err) {
      console.log(err);
      return;
    }
  }, [data]);

  const dataLength = data?.pages?.reduce(
    (counter, page) => counter + page.entries?.length,
    0
  );

  if (!data || dataLength === undefined || !loaded)
    return (
      <Layout headline="My Workouts">
        <LoadingSpinner />
      </Layout>
    );

  return (
    <Layout
      headline={workoutData && workoutData.length > 0 && t("workouts.headline")}
    >
      <>
        {workoutData && workoutData.length > 0 && (
          <InfiniteScroll
            dataLength={dataLength}
            next={fetchNextPage}
            hasMore={hasNextPage}
            loader={
              dataLength > 0 && <LoadingSpinner positionRelative={true} />
            }
            className={Styles.container}
          >
            {data.pages.map((group, i, array) => (
              <React.Fragment key={i}></React.Fragment>
            ))}
            <WorkoutList workoutData={workoutData} />
          </InfiniteScroll>
        )}
        {workoutData && workoutData.length <= 0 && (
          <>
            <h1 className={Styles.pageHeader}>{t("common.noWorkouts.headline")}</h1>
            <video className={Styles.video} autoPlay muted loop playsInline>
              <source src={video} type="video/mp4" />
            </video>

            <h2>{t("common.noWorkouts.subHeadline")}</h2>
            <p>{t("common.noWorkouts.paragraph1")}</p>

            <p>{t("common.noWorkouts.paragraph2")}</p>
            <p className={Styles.small}>{t("common.noWorkouts.smallText")}</p>

            <p>
              <Trans i18nKey="common.noWorkouts.paragraph3">
                <Link to="/workouts">My Workouts</Link>
              </Trans>
            </p>

            <div className={Styles.menu}>
              <div className={Styles.buttonGroup}>
                <Link className={Styles.button} to="/">
                  <Home /> {t("common.menu.exerhub")}
                </Link>

                <Link className={Styles.button} to="/leaderboards">
                  <Leaderboard /> {t("common.menu.leaderboards")}
                </Link>

                <Link className={Styles.button} to="/settings">
                  <Settings /> {t("common.menu.settings")}
                </Link>
              </div>
            </div>
          </>
        )}
      </>
    </Layout>
  );
};

export default Workouts;
