import React, { useEffect } from "react";
import { CiTrophy } from "react-icons/ci";
import { IoIosCheckmarkCircle } from "react-icons/io";
import Styles from "./Popup.module.scss";

const Popup = ({ data, onClick }) => {
  useEffect(() => {
    // Add overflow hidden to body when overlay is open
    document.body.style.overflow = "hidden";

    // Cleanup: remove overflow hidden when overlay is closed
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className={Styles.fullscreenOverlay}>
      <div className={Styles.popup}>
        <div className={Styles.popupContent}>
          <div className={Styles.popupText}>
            {data.icon ? (
              <h1
                style={{
                  lineHeight:
                    data.icon === "trophy" || data.icon === "checkmark"
                      ? 0
                      : "normal",
                  fontStyle: "normal",
                  marginBottom: 4,
                }}
              >
                <span className="brand-primary">
                  {data.icon === "trophy" ? (
                    <CiTrophy />
                  ) : data.icon === "checkmark" ? (
                    <IoIosCheckmarkCircle />
                  ) : (
                    <span>{data.icon}</span>
                  )}
                </span>
              </h1>
            ) : null}

            {data.iconText ? <p>{data.iconText}</p> : null}

            {data.title ? <h1 className={Styles.title}>{data.title}</h1> : null}
            {data.description ? <p>{data.description}</p> : null}

            {data.expAmount ? (
              <h3>
                <span className="brand-primary">
                  +
                  {data.expAmount
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, "’") + " "}
                  Points
                </span>
              </h3>
            ) : null}
          </div>

          <button className={Styles.popupButton} onClick={onClick}>
            {data.buttonText ? data.buttonText : "Claim"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
