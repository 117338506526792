import React, { useState, useRef } from "react";
import ROOT_URL from "../../Config/rootUrl";
import { useAuthState, useAuthDispatch, logout } from "../../Context";
import { useQuery } from "react-query";
import Layout from "../../Components/Layout/Layout";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import Styles from "./Ausbildung.module.scss";
import Row from "../../Components/Row/Row";
import WorkoutBurpees from "../../Assets/WorkoutBurpees.svg";
// import WorkoutHighTouchRight from "../../Assets/WorkoutHighTouchRight.svg";
import WorkoutPunchRight from "../../Assets/WorkoutPunchRight.svg";
import WorkoutJumps from "../../Assets/WorkoutJumps.svg";
import WorkoutSquats from "../../Assets/WorkoutSquats.svg";
import WorkoutLungeRight from "../../Assets/WorkoutLungeRight.svg";
import WorkoutTripples from "../../Assets/WorkoutTripples.svg";
import FixedButton from "../../Components/FixedButton/FixedButton";
import { AiOutlineUnlock } from "react-icons/ai";

const Ausbildung = () => {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();
  const [errorMessage, setErrorMessage] = useState(null);

  const [isVisibleW1, setIsVisibleW1] = useState(false);
  const [isVisibleW2, setIsVisibleW2] = useState(false);
  const [isVisibleW3, setIsVisibleW3] = useState(false);
  const [isVisibleW4, setIsVisibleW4] = useState(false);
  const [isVisibleW5, setIsVisibleW5] = useState(false);
  const [isVisibleW6, setIsVisibleW6] = useState(false);

  const detailsRefW1 = useRef(null);
  const detailsRefW2 = useRef(null);
  const detailsRefW3 = useRef(null);
  const detailsRefW4 = useRef(null);
  const detailsRefW5 = useRef(null);
  const detailsRefW6 = useRef(null);

  const { data, isError, isLoading } = useQuery(
    ["ausbildung"],
    async () => {
      const token = userDetails?.token;
      if (!token) return;

      const response = await fetch(`${ROOT_URL}/api/v1/ausbildung/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const responseJson = await response.json();

      if (!response.ok) {
        throw new Error(responseJson.error?.message || "Request failed");
      }

      if (responseJson?.error) {
        if (responseJson.error.name === "TokenExpiredError") {
          logout(dispatch);
          setErrorMessage("Session expired. Please log in again.");
        } else {
          setErrorMessage(responseJson.error.message || "Request failed");
        }
      }

      return responseJson;
    },
    {
      onError: (err) => {
        setErrorMessage(err.message || "An unexpected error occurred");
      },
      onSuccess: () => {
        setErrorMessage(null);
      },
    }
  );

  // Handle visibility toggling with dynamic height calculation
  const handleToggle = (setter, ref) => {
    setter((prev) => {
      if (ref.current) {
        // Trigger reflow
        ref.current.style.height = ref.current.scrollHeight + "px";
        // After a frame, update height to 0 if collapsing
        requestAnimationFrame(() => {
          ref.current.style.height = prev
            ? "0"
            : ref.current.scrollHeight + "px";
        });
      }
      return !prev;
    });
  };

  return (
    <Layout
      headline="Ausbildung"
      fixedButtonAtBottom={
        !!data?.w1Completed &&
        !!data?.w2Completed &&
        !!data?.w3Completed &&
        !!data?.w4Completed &&
        !!data?.w5Completed &&
        !!data?.w6Completed
      }
    >
      <p>
        Absolviere alle aufgelisteten Trainings um die Prüfung zum{" "}
        <strong>[ExerCube] Coach</strong> freizuschalten.
      </p>

      <p>Absolvierte Workouts werden automatisch erkannt und abgehakt.</p>

      {isLoading && <LoadingSpinner />}

      {isError && <p className={Styles.error}>{errorMessage}</p>}

      {data && (
        <>
          <div className={Styles.divider}>Racer</div>

          <div className={Styles.results}>
            <div
              className={Styles.result}
              onClick={() => handleToggle(setIsVisibleW1, detailsRefW1)}
            >
              <div className={Styles.visibleRow}>
                <object
                  className={Styles.svgAnimation}
                  type="image/svg+xml"
                  data={WorkoutPunchRight}
                  aria-label="animation"
                />

                <div>
                  <p>TRAINING</p>
                  <h3>UpperBody</h3>
                </div>

                <div
                  className={`${Styles.checkmark} ${
                    !!data.w1Completed ? Styles.checked : ""
                  }`}
                ></div>
              </div>

              <div
                ref={detailsRefW1}
                className={`${Styles.details} ${
                  isVisibleW1 ? Styles.visible : ""
                }`}
              >
                <div className={Styles.marginTop}>
                  <Row>
                    <div className={Styles.workoutStat}>
                      <h4>Difficulty</h4>
                      <p>Expert</p>
                    </div>

                    <div className={Styles.workoutStat}>
                      <h4>Duration</h4>
                      <p>3 Min.</p>
                    </div>

                    <div className={Styles.workoutStat}>
                      <h4>StartSpeed</h4>
                      <p>4</p>
                    </div>
                  </Row>

                  <Row>
                    <div className={Styles.workoutStat}>
                      <h4>Tutorial</h4>
                      <p>Yes</p>
                    </div>

                    <div className={Styles.workoutStat}>
                      <h4>Seed</h4>
                      <p>–</p>
                    </div>

                    <div className={Styles.workoutStat}>
                      {/* <h4></h4>
                    <p></p> */}
                    </div>
                  </Row>

                  {/* <Row>
                  <div className={Styles.workoutHinweise}>
                    <h4>Hinweise</h4>
                    <p>–</p>
                  </div>
                </Row> */}
                </div>
              </div>
            </div>

            <div
              className={Styles.result}
              onClick={() => handleToggle(setIsVisibleW2, detailsRefW2)}
            >
              <div className={Styles.visibleRow}>
                <object
                  className={Styles.svgAnimation}
                  type="image/svg+xml"
                  data={WorkoutSquats}
                  aria-label="animation"
                />

                <div>
                  <p>TRAINING</p>
                  <h3>DualFlow</h3>
                </div>

                <div
                  className={`${Styles.checkmark} ${
                    !!data.w2Completed ? Styles.checked : ""
                  }`}
                ></div>
              </div>

              <div
                ref={detailsRefW2}
                className={`${Styles.details} ${
                  isVisibleW2 ? Styles.visible : ""
                }`}
              >
                <div className={Styles.marginTop}>
                  <Row>
                    <div className={Styles.workoutStat}>
                      <h4>Difficulty</h4>
                      <p>Intermediate</p>
                    </div>

                    <div className={Styles.workoutStat}>
                      <h4>Duration</h4>
                      <p>5 Min.</p>
                    </div>

                    <div className={Styles.workoutStat}>
                      <h4>StartSpeed</h4>
                      <p>6</p>
                    </div>
                  </Row>

                  <Row>
                    <div className={Styles.workoutStat}>
                      <h4>Tutorial</h4>
                      <p>–</p>
                    </div>

                    <div className={Styles.workoutStat}>
                      <h4>Seed</h4>
                      <p>–</p>
                    </div>

                    <div className={Styles.workoutStat}>
                      {/* <h4></h4>
                    <p></p> */}
                    </div>
                  </Row>

                  <Row>
                    <div className={Styles.workoutHinweise}>
                      <h4>Hinweise</h4>
                      <p>Mit Pulsgurt auf 90% HRMax</p>
                    </div>
                  </Row>
                </div>
              </div>
            </div>

            <div
              className={Styles.result}
              onClick={() => handleToggle(setIsVisibleW3, detailsRefW3)}
            >
              <div className={Styles.visibleRow}>
                <object
                  className={Styles.svgAnimation}
                  type="image/svg+xml"
                  data={WorkoutJumps}
                  aria-label="animation"
                />

                <div>
                  <p>COMPETITION</p>
                  <h3>Reha</h3>
                </div>

                <div
                  className={`${Styles.checkmark} ${
                    !!data.w3Completed ? Styles.checked : ""
                  }`}
                ></div>
              </div>

              <div
                ref={detailsRefW3}
                className={`${Styles.details} ${
                  isVisibleW3 ? Styles.visible : ""
                }`}
              >
                <div className={Styles.marginTop}>
                  <Row>
                    <div className={Styles.workoutStat}>
                      <h4>Difficulty</h4>
                      <p>–</p>
                    </div>

                    <div className={Styles.workoutStat}>
                      <h4>Duration</h4>
                      <p>5 Min.</p>
                    </div>

                    <div className={Styles.workoutStat}>
                      <h4>StartSpeed</h4>
                      <p>5</p>
                    </div>
                  </Row>

                  <Row>
                    <div className={Styles.workoutStat}>
                      <h4>Tutorial</h4>
                      <p>–</p>
                    </div>

                    <div className={Styles.workoutStat}>
                      <h4>Seed</h4>
                      <p>12345</p>
                    </div>

                    <div className={Styles.workoutStat}>
                      {/* <h4></h4>
                    <p></p> */}
                    </div>
                  </Row>

                  <Row>
                    <div className={Styles.workoutHinweise}>
                      <h4>Hinweise</h4>
                      <p>Vergiss nicht den korrekten Seed einzugeben</p>
                    </div>
                  </Row>
                </div>
              </div>
            </div>

            <div
              className={Styles.result}
              onClick={() => handleToggle(setIsVisibleW4, detailsRefW4)}
            >
              <div className={Styles.visibleRow}>
                <object
                  className={Styles.svgAnimation}
                  type="image/svg+xml"
                  data={WorkoutLungeRight}
                  aria-label="animation"
                />

                <div>
                  <p>COMPETITION</p>
                  <h3>FIBO</h3>
                </div>

                <div
                  className={`${Styles.checkmark} ${
                    !!data.w4Completed ? Styles.checked : ""
                  }`}
                ></div>
              </div>

              <div
                ref={detailsRefW4}
                className={`${Styles.details} ${
                  isVisibleW4 ? Styles.visible : ""
                }`}
              >
                <div className={Styles.marginTop}>
                  <Row>
                    <div className={Styles.workoutStat}>
                      <h4>Difficulty</h4>
                      <p>–</p>
                    </div>

                    <div className={Styles.workoutStat}>
                      <h4>Duration</h4>
                      <p>5 Min.</p>
                    </div>

                    <div className={Styles.workoutStat}>
                      <h4>StartSpeed</h4>
                      <p>5</p>
                    </div>
                  </Row>

                  <Row>
                    <div className={Styles.workoutStat}>
                      <h4>Tutorial</h4>
                      <p>–</p>
                    </div>

                    <div className={Styles.workoutStat}>
                      <h4>Seed</h4>
                      <p>–</p>
                    </div>

                    <div className={Styles.workoutStat}>
                      {/* <h4></h4>
                    <p></p> */}
                    </div>
                  </Row>

                  <Row>
                    <div className={Styles.workoutHinweise}>
                      <h4>Hinweise</h4>
                      <p>Für Menschen im Rollstuhl konfiguriert</p>
                    </div>
                  </Row>
                </div>
              </div>
            </div>

            <div
              className={Styles.result}
              onClick={() => handleToggle(setIsVisibleW5, detailsRefW5)}
            >
              <div className={Styles.visibleRow}>
                <object
                  className={Styles.svgAnimation}
                  type="image/svg+xml"
                  data={WorkoutTripples}
                  aria-label="animation"
                />

                <div>
                  <p>COMPETITION</p>
                  <h3>DualFlow</h3>
                </div>

                <div
                  className={`${Styles.checkmark} ${
                    !!data.w5Completed ? Styles.checked : ""
                  }`}
                ></div>
              </div>

              <div
                ref={detailsRefW5}
                className={`${Styles.details} ${
                  isVisibleW5 ? Styles.visible : ""
                }`}
              >
                <div className={Styles.marginTop}>
                  <Row>
                    <div className={Styles.workoutStat}>
                      <h4>Difficulty</h4>
                      <p>–</p>
                    </div>

                    <div className={Styles.workoutStat}>
                      <h4>Duration</h4>
                      <p>10 Min.</p>
                    </div>

                    <div className={Styles.workoutStat}>
                      <h4>StartSpeed</h4>
                      <p>5</p>
                    </div>
                  </Row>

                  <Row>
                    <div className={Styles.workoutStat}>
                      <h4>Tutorial</h4>
                      <p>–</p>
                    </div>

                    <div className={Styles.workoutStat}>
                      <h4>Seed</h4>
                      <p>–</p>
                    </div>

                    <div className={Styles.workoutStat}>
                      {/* <h4></h4>
                    <p></p> */}
                    </div>
                  </Row>

                  {/* <Row>
                  <div className={Styles.workoutHinweise}>
                    <h4>Hinweise</h4>
                    <p>–</p>
                  </div>
                </Row> */}
                </div>
              </div>
            </div>

            <div
              className={Styles.result}
              onClick={() => handleToggle(setIsVisibleW6, detailsRefW6)}
            >
              <div className={Styles.visibleRow}>
                <object
                  className={Styles.svgAnimation}
                  type="image/svg+xml"
                  data={WorkoutBurpees}
                  aria-label="animation"
                />

                <div>
                  <p>MISSION 1</p>
                  <h3>Story Mode</h3>
                </div>

                <div
                  className={`${Styles.checkmark} ${
                    !!data.w6Completed ? Styles.checked : ""
                  }`}
                ></div>
              </div>

              <div
                ref={detailsRefW6}
                className={`${Styles.details} ${
                  isVisibleW6 ? Styles.visible : ""
                }`}
              >
                <div className={Styles.marginTop}>
                  <Row>
                    <div className={Styles.workoutStat}>
                      <h4>Difficulty</h4>
                      <p>–</p>
                    </div>

                    <div className={Styles.workoutStat}>
                      <h4>Duration</h4>
                      <p>–</p>
                    </div>

                    <div className={Styles.workoutStat}>
                      <h4>StartSpeed</h4>
                      <p>–</p>
                    </div>
                  </Row>

                  <Row>
                    <div className={Styles.workoutStat}>
                      <h4>Tutorial</h4>
                      <p>–</p>
                    </div>

                    <div className={Styles.workoutStat}>
                      <h4>Seed</h4>
                      <p>–</p>
                    </div>

                    <div className={Styles.workoutStat}>
                      {/* <h4></h4>
                    <p></p> */}
                    </div>
                  </Row>

                  {/* <Row>
                  <div className={Styles.workoutHinweise}>
                    <h4>Hinweise</h4>
                    <p>–</p>
                  </div>
                </Row> */}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <FixedButton
        to="/quiz"
        text="Zur Prüfung"
        Icon={AiOutlineUnlock}
        disabled={
          !data?.w1Completed ||
          !data?.w2Completed ||
          !data?.w3Completed ||
          !data?.w4Completed ||
          !data?.w5Completed ||
          !data?.w6Completed
        }
      />
    </Layout>
  );
};

export default Ausbildung;
