import React from "react";
import { useAuthState, useAuthDispatch, logout } from "../../Context";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { useQuery, useInfiniteQuery } from "react-query";
import InfiniteScroll from "react-infinite-scroll-component";
import { FaUserAlt } from "react-icons/fa";
import Layout from "../../Components/Layout/Layout";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import { CountUp } from "use-count-up";
import SpheryAppDefaultImageMale from "../../Assets/SpheryAppDefaultImageMale.jpg";
import ROOT_URL from "../../Config/rootUrl";
import Styles from "./ChallengeDetail.module.scss";
import { useTranslation } from "react-i18next";

const ChallengeDetail = () => {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();
  const { id } = useParams();
  const [showRound1Details, setShowRound1Details] = React.useState(false);
  const [showRound2Details, setShowRound2Details] = React.useState(false);
  const [showRound3Details, setShowRound3Details] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [messageData, setMessageData] = React.useState();

  const { t, i18n } = useTranslation();

  const challengeData = useQuery(["challenge", id], async () => {
    const response = await fetch(`${ROOT_URL}/api/v1/challenge/${id}`, {
      headers: {
        Authorization: `Bearer ${userDetails?.token}`,
      },
    });

    const responseJson = await response.json();
    if (responseJson.data?.error?.name === "TokenExpiredError") {
      logout(dispatch);
    }
    return responseJson;
  });

  const round1Details = useQuery(
    ["challengeRound1", id],
    async () => {
      const response = await fetch(
        `${ROOT_URL}/api/v1/challenge/round/${challengeData?.data?.rounds[0]?.id}`,
        {
          headers: {
            Authorization: `Bearer ${userDetails?.token}`,
          },
        }
      );

      const responseJson = await response.json();
      if (responseJson.data?.error?.name === "TokenExpiredError") {
        logout(dispatch);
      }
      return responseJson;
    },
    { enabled: showRound1Details }
  );

  const round2Details = useQuery(
    ["challengeRound2", id],
    async () => {
      const response = await fetch(
        `${ROOT_URL}/api/v1/challenge/round/${challengeData?.data?.rounds[1]?.id}`,
        {
          headers: {
            Authorization: `Bearer ${userDetails?.token}`,
          },
        }
      );

      const responseJson = await response.json();
      if (responseJson.data?.error?.name === "TokenExpiredError") {
        logout(dispatch);
      }

      return responseJson;
    },
    { enabled: showRound2Details }
  );

  const round3Details = useQuery(
    ["challengeRound3", id],
    async () => {
      const response = await fetch(
        `${ROOT_URL}/api/v1/challenge/round/${challengeData?.data?.rounds[2]?.id}`,
        {
          headers: {
            Authorization: `Bearer ${userDetails?.token}`,
          },
        }
      );

      const responseJson = await response.json();
      if (responseJson.data?.error?.name === "TokenExpiredError") {
        logout(dispatch);
      }

      return responseJson;
    },
    { enabled: showRound3Details }
  );

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ["getChallengesMessages", id],
    async ({ pageParam = 1 }) => {
      const token = userDetails?.token;
      if (!token) return;
      const response = await fetch(
        `${ROOT_URL}/api/v1/message/find_all_messages_for_challenge/${id}/?size=25&page=${pageParam}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseJson = await response.json();
      if (data?.error?.name === "TokenExpiredError") {
        logout(dispatch);
      }
      return responseJson;
    },
    {
      getNextPageParam: (page) =>
        page.currentPage === page.totalPages ? undefined : page.currentPage + 1,
    }
  );

  React.useEffect(() => {
    try {
      const fetchedMessageData = data?.pages
        ?.map((entries) => entries.entries)
        .flat();

      setLoaded(true);
      setMessageData(fetchedMessageData);
    } catch (err) {
      console.log(err);
      return;
    }
  }, [data]);

  const dataLength = data?.pages?.reduce(
    (counter, page) => counter + page.entries?.length,
    0
  );

  if (!challengeData.data || !data || dataLength === undefined || !loaded)
    return (
      <Layout headline={t("challenges.headline")}>
        <LoadingSpinner />
      </Layout>
    );

  return (
    <Layout headline={t("challenges.headline")}>
      <div className={Styles.preset}>
        <h3>
          {challengeData.data?.rounds[0]?.initiatorWorkout?.workoutPreset?.name}
        </h3>
        <p className={Styles.duration}>
          <CountUp
            isCounting
            end={
              challengeData.data?.rounds[0]?.initiatorWorkout?.raceConfig
                ?.duration / 60
            }
            duration={0.25}
            decimalPlaces={0}
          />{" "}
          {t("common.minutes", {
            count:
              challengeData.data?.rounds[0]?.initiatorWorkout?.raceConfig
                ?.duration / 60,
          })}
        </p>
      </div>

      <div className={Styles.section}>
        <div className={Styles.overlaySectionTop}>
          <div className={Styles.userSection}>
            <div className={Styles.userLeft}>
              <h3>
                {challengeData.data.initiator?.username ===
                userDetails?.user?.username
                  ? challengeData.data.initiator?.username
                  : challengeData.data.opponent?.username}
              </h3>
            </div>
            <div className={Styles.userRight}>
              <h3>
                {challengeData.data.initiator?.username !==
                userDetails?.user?.username
                  ? challengeData.data.initiator?.username
                  : challengeData.data.opponent?.username}
              </h3>
            </div>
          </div>
        </div>

        <div className={Styles.imageContainer}>
          <div className={Styles.leftImage}>
            <svg viewBox="0 0 100 15" className={Styles.arrowTop}>
              <polygon points="0,0 0,15 100,0" />
            </svg>
            <svg viewBox="0 0 100 15" className={Styles.arrowBottom}>
              <polygon points="0,0 100,15 0,15" />
            </svg>
            <picture>
              <>
                {challengeData.data.initiator?.username ===
                userDetails?.user?.username ? (
                  <>
                    {challengeData.data.initiator?.profileImage ? (
                      <img
                        srcSet={`${ROOT_URL}/public/images/${challengeData.data.initiator?.profileImage}-160x160.jpg 160w, ${ROOT_URL}/public/images/${challengeData.data.initiator?.profileImage}-320x320.jpg 320w, ${ROOT_URL}/public/images/${challengeData.data.initiator?.profileImage}-640x640.jpg 640w, ${ROOT_URL}/public/images/${challengeData.data.initiator?.profileImage}-960x960.jpg 960w`}
                        sizes="(max-width: 1199px) 50vw, 584px"
                        src={`${ROOT_URL}/public/images/${challengeData.data.initiator?.profileImage}-320x320.jpg`}
                        alt=""
                      />
                    ) : (
                      <img src={SpheryAppDefaultImageMale} alt="" />
                    )}
                  </>
                ) : (
                  <>
                    {challengeData.data.opponent?.profileImage ? (
                      <img
                        srcSet={`${ROOT_URL}/public/images/${challengeData.data.opponent?.profileImage}-160x160.jpg 160w, ${ROOT_URL}/public/images/${challengeData.data.opponent?.profileImage}-320x320.jpg 320w, ${ROOT_URL}/public/images/${challengeData.data.opponent?.profileImage}-640x640.jpg 640w, ${ROOT_URL}/public/images/${challengeData.data.opponent?.profileImage}-960x960.jpg 960w`}
                        sizes="(max-width: 1199px) 100vw, 584px"
                        src={`${ROOT_URL}/public/images/${challengeData.data.opponent?.profileImage}-320x320.jpg`}
                        alt=""
                      />
                    ) : (
                      <img src={SpheryAppDefaultImageMale} alt="" />
                    )}
                  </>
                )}
              </>
            </picture>
          </div>

          <div className={Styles.rightImage}>
            <svg viewBox="0 0 100 15" className={Styles.arrowTop}>
              <polygon points="0,0 100,0 100,15" />
            </svg>

            <svg viewBox="0 0 100 15" className={Styles.arrowBottom}>
              <polygon points="0,15 100,0 100,15" />
            </svg>
            <picture>
              <>
                {challengeData.data.initiator?.username !==
                userDetails?.user?.username ? (
                  <>
                    {challengeData.data.initiator?.profileImage ? (
                      <img
                        srcSet={`${ROOT_URL}/public/images/${challengeData.data.initiator?.profileImage}-160x160.jpg 160w, ${ROOT_URL}/public/images/${challengeData.data.initiator?.profileImage}-320x320.jpg 320w, ${ROOT_URL}/public/images/${challengeData.data.initiator?.profileImage}-640x640.jpg 640w, ${ROOT_URL}/public/images/${challengeData.data.initiator?.profileImage}-960x960.jpg 960w`}
                        sizes="(max-width: 1199px) 50vw, 584px"
                        src={`${ROOT_URL}/public/images/${challengeData.data.initiator?.profileImage}-320x320.jpg`}
                        alt=""
                      />
                    ) : (
                      <img src={SpheryAppDefaultImageMale} alt="" />
                    )}
                  </>
                ) : (
                  <>
                    {challengeData.data.opponent?.profileImage ? (
                      <img
                        srcSet={`${ROOT_URL}/public/images/${challengeData.data.opponent?.profileImage}-160x160.jpg 160w, ${ROOT_URL}/public/images/${challengeData.data.opponent?.profileImage}-320x320.jpg 320w, ${ROOT_URL}/public/images/${challengeData.data.opponent?.profileImage}-640x640.jpg 640w, ${ROOT_URL}/public/images/${challengeData.data.opponent?.profileImage}-960x960.jpg 960w`}
                        sizes="(max-width: 1199px) 100vw, 584px"
                        src={`${ROOT_URL}/public/images/${challengeData.data.opponent?.profileImage}-320x320.jpg`}
                        alt=""
                      />
                    ) : (
                      <img src={SpheryAppDefaultImageMale} alt="" />
                    )}
                  </>
                )}
              </>
            </picture>
          </div>

          {challengeData.data.completedChallenge && (
            <div className={Styles.overlaySectionBottom}>
              <div className={Styles.userSection}>
                {challengeData.data.winner ? (
                  <>
                    <div className={Styles.userLeft}>
                      <h1>
                        {challengeData.data?.winner?.username ===
                        userDetails?.user?.username
                          ? t("challenges.challengeDetail.winner")
                          : t("challenges.challengeDetail.loser")}
                      </h1>
                    </div>
                    <div className={Styles.userRight}>
                      <h1>
                        {challengeData.data?.winner?.username !==
                        userDetails?.user?.username
                          ? t("challenges.challengeDetail.winner")
                          : t("challenges.challengeDetail.loser")}
                      </h1>
                    </div>
                  </>
                ) : (
                  <h1>{t("challenges.challengeDetail.draw")}</h1>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {challengeData.data.rounds.length > 2 && (
        <div className={Styles.section}>
          <div className={Styles.header}>
            <h3>
              {t("challenges.challengeDetail.roundAndNumber", { number: 3 })}
            </h3>
          </div>

          <div className={Styles.round}>
            <div className={Styles.left}>
              {challengeData.data.initiator?.username ===
              userDetails?.user?.username ? (
                <>
                  {challengeData.data.rounds[2].initiatorWorkout ? (
                    <div className={Styles.date}>
                      {new Intl.DateTimeFormat(i18n.resolvedLanguage === "en" ? "en-GB" : i18n.resolvedLanguage, {
                        weekday: "long",
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      }).format(
                        new Date(
                          challengeData.data.rounds[2].initiatorWorkout.updatedAt
                        )
                      )}
                    </div>
                  ) : (
                    <div className={Styles.date}>
                      {t("challenges.challengeDetail.workoutPending")}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {challengeData.data.rounds[2].opponentWorkout ? (
                    <div className={Styles.date}>
                      {new Intl.DateTimeFormat(i18n.resolvedLanguage === "en" ? "en-GB" : i18n.resolvedLanguage, {
                        weekday: "long",
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      }).format(
                        new Date(
                          challengeData.data.rounds[2].opponentWorkout.updatedAt
                        )
                      )}
                    </div>
                  ) : (
                    <div className={Styles.date}>
                      {t("challenges.challengeDetail.workoutPending")}
                    </div>
                  )}
                </>
              )}
            </div>

            <div className={Styles.right}>
              {challengeData.data.initiator?.username !==
              userDetails?.user?.username ? (
                <>
                  {challengeData.data.rounds[2].initiatorWorkout ? (
                    <div className={Styles.date}>
                      {new Intl.DateTimeFormat(i18n.resolvedLanguage === "en" ? "en-GB" : i18n.resolvedLanguage, {
                        weekday: "long",
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      }).format(
                        new Date(
                          challengeData.data.rounds[2].initiatorWorkout.updatedAt
                        )
                      )}
                    </div>
                  ) : (
                    <div className={Styles.date}>
                      {t("challenges.challengeDetail.workoutPending")}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {challengeData.data.rounds[2].opponentWorkout ? (
                    <div className={Styles.date}>
                      {new Intl.DateTimeFormat(i18n.resolvedLanguage === "en" ? "en-GB" : i18n.resolvedLanguage, {
                        weekday: "long",
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      }).format(
                        new Date(
                          challengeData.data.rounds[2].opponentWorkout.updatedAt
                        )
                      )}
                    </div>
                  ) : (
                    <div className={Styles.date}>
                      {t("challenges.challengeDetail.workoutPending")}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          <div className={Styles.round}>
            <div className={Styles.left}>
              <div className={Styles.roundMarker}>
                <span
                  className={`${
                    challengeData.data.rounds[2]?.winner
                      ? challengeData.data.rounds[2]?.winner?.username ===
                        userDetails.user.username
                        ? Styles.won
                        : Styles.lost
                      : (challengeData.data.initiator?.username ===
                          userDetails?.user?.username &&
                          challengeData.data.rounds[2]?.initiatorWorkout &&
                          !challengeData.data.rounds[2]?.opponentWorkout) ||
                        (challengeData.data.initiator?.username !==
                          userDetails?.user?.username &&
                          !challengeData.data.rounds[2]?.initiatorWorkout &&
                          challengeData.data.rounds[2]?.opponentWorkout)
                      ? Styles.played
                      : challengeData.data.rounds[2] &&
                        challengeData.data.rounds[2]?.initiatorWorkout
                          ?.score ===
                          challengeData.data.rounds[2]?.opponentWorkout?.score
                      ? Styles.won
                      : ""
                  }`}
                ></span>
              </div>
            </div>
            <div className={Styles.center}>
              <div className={Styles.score}>
                <>
                  <CountUp
                    isCounting
                    end={
                      challengeData.data.initiator?.username ===
                      userDetails?.user?.username
                        ? challengeData.data.rounds.filter(
                            (round) =>
                              round.winner?.username ===
                                challengeData.data.initiator?.username &&
                              round.round < 3
                          ).length
                        : challengeData.data.rounds.filter(
                            (round) =>
                              round.winner?.username ===
                                challengeData.data.opponent?.username &&
                              round.round < 3
                          ).length
                    }
                    duration={0.25}
                    decimalPlaces={0}
                  />
                  :
                  <CountUp
                    isCounting
                    end={
                      challengeData.data.initiator?.username !==
                      userDetails?.user?.username
                        ? challengeData.data.rounds.filter(
                            (round) =>
                              round.winner?.username ===
                                challengeData.data.initiator?.username &&
                              round.round < 3
                          ).length
                        : challengeData.data.rounds.filter(
                            (round) =>
                              round.winner?.username ===
                                challengeData.data.opponent?.username &&
                              round.round < 3
                          ).length
                    }
                    duration={0.25}
                    decimalPlaces={0}
                  />
                </>
              </div>
            </div>
            <div className={Styles.right}>
              <div className={Styles.roundMarker}>
                <span
                  className={`${
                    challengeData.data.rounds[2]?.winner
                      ? challengeData.data.rounds[2]?.winner?.username !==
                        userDetails.user.username
                        ? Styles.won
                        : Styles.lost
                      : (challengeData.data.initiator?.username !==
                          userDetails?.user?.username &&
                          challengeData.data.rounds[2]?.initiatorWorkout &&
                          !challengeData.data.rounds[2]?.opponentWorkout) ||
                        (challengeData.data.initiator?.username ===
                          userDetails?.user?.username &&
                          !challengeData.data.rounds[2]?.initiatorWorkout &&
                          challengeData.data.rounds[2]?.opponentWorkout)
                      ? Styles.played
                      : challengeData.data.rounds[2] &&
                        challengeData.data.rounds[2]?.initiatorWorkout
                          ?.score ===
                          challengeData.data.rounds[2]?.opponentWorkout?.score
                      ? Styles.won
                      : ""
                  }`}
                ></span>
              </div>
            </div>
          </div>

          {challengeData.data.rounds[2].initiatorWorkout &&
            challengeData.data.rounds[2].opponentWorkout && (
              <div className={Styles.round}>
                <div className={Styles.center}>
                  <div className={Styles.resultMessage}>
                    <>
                      {challengeData.data.rounds[2].winner ? (
                        <>
                          {challengeData.data.rounds[2].winner?.username ===
                          userDetails.user.username ? (
                            <div className={Styles.won}>
                              {t("challenges.challengeDetail.youWonThisRound")}
                            </div>
                          ) : (
                            <div className={Styles.lost}>
                              {t("challenges.challengeDetail.youLostThisRound")}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className={Styles.draw}>
                          {t("challenges.challengeDetail.thisRoundIsADraw")}
                        </div>
                      )}
                    </>
                  </div>
                </div>
              </div>
            )}

          <div className={Styles.round}>
            <div className={Styles.center}>
              <button
                className="small"
                onClick={() => setShowRound3Details(!showRound3Details)}
              >
                {showRound3Details ? t("common.buttons.hideDetails") : t("common.buttons.showDetails")}
              </button>
            </div>
          </div>

          {showRound3Details && round3Details && (
            <div className={Styles.details}>
              <div className={Styles.round}>
                <div className={Styles.left}>
                  {challengeData.data?.initiator.username ===
                  userDetails.user.username ? (
                    <>
                      {round3Details?.data?.initiatorWorkout?.score && (
                        <span>
                          <CountUp
                            isCounting
                            end={round3Details?.data?.initiatorWorkout?.score}
                            duration={0.25}
                            decimalPlaces={0}
                            thousandsSeparator="’"
                          />
                        </span>
                      )}
                    </>
                  ) : (
                    <>
                      {round3Details?.data?.opponentWorkout?.score && (
                        <span>
                          <CountUp
                            isCounting
                            end={round3Details?.data?.opponentWorkout?.score}
                            duration={0.25}
                            decimalPlaces={0}
                            thousandsSeparator="’"
                          />
                        </span>
                      )}
                    </>
                  )}
                </div>
                <div className={Styles.center}>
                  <h4>{t("challenges.challengeDetail.score")}</h4>
                </div>
                <div className={Styles.right}>
                  {challengeData.data?.initiator.username !==
                  userDetails.user.username ? (
                    <>
                      {round3Details?.data?.initiatorWorkout?.score && (
                        <span>
                          <CountUp
                            isCounting
                            end={round3Details?.data?.initiatorWorkout?.score}
                            duration={0.25}
                            decimalPlaces={0}
                            thousandsSeparator="’"
                          />
                        </span>
                      )}
                    </>
                  ) : (
                    <>
                      {round3Details?.data?.opponentWorkout?.score && (
                        <span>
                          <CountUp
                            isCounting
                            end={round3Details?.data?.opponentWorkout?.score}
                            duration={0.25}
                            decimalPlaces={0}
                            thousandsSeparator="’"
                          />
                        </span>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className={Styles.round}>
                <div className={Styles.left}>
                  {challengeData.data?.initiator.username ===
                  userDetails.user.username ? (
                    <>
                      {round3Details?.data?.initiatorWorkout?.dualflowScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round3Details?.data?.initiatorWorkout
                                  ?.dualflowScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {round3Details?.data?.opponentWorkout?.dualflowScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round3Details?.data?.opponentWorkout
                                  ?.dualflowScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className={Styles.center}>
                  <h4>{t("challenges.challengeDetail.flow")}</h4>
                </div>
                <div className={Styles.right}>
                  {challengeData.data?.initiator.username !==
                  userDetails.user.username ? (
                    <>
                      {round3Details?.data?.initiatorWorkout?.dualflowScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round3Details?.data?.initiatorWorkout
                                  ?.dualflowScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {round3Details?.data?.opponentWorkout?.dualflowScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round3Details?.data?.opponentWorkout
                                  ?.dualflowScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className={Styles.round}>
                <div className={Styles.left}>
                  {challengeData.data?.initiator.username ===
                  userDetails.user.username ? (
                    <>
                      {round3Details?.data?.initiatorWorkout?.brainScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round3Details?.data?.initiatorWorkout
                                  ?.brainScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {round3Details?.data?.opponentWorkout?.bodyScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round3Details?.data?.opponentWorkout
                                  ?.bodyScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className={Styles.center}>
                  <h4>{t("challenges.challengeDetail.body")}</h4>
                </div>
                <div className={Styles.right}>
                  {challengeData.data?.initiator.username !==
                  userDetails.user.username ? (
                    <>
                      {round3Details?.data?.initiatorWorkout?.bodyScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round3Details?.data?.initiatorWorkout
                                  ?.bodyScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {round3Details?.data?.opponentWorkout?.bodyScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round3Details?.data?.opponentWorkout
                                  ?.bodyScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className={Styles.round}>
                <div className={Styles.left}>
                  {challengeData.data?.initiator.username ===
                  userDetails.user.username ? (
                    <>
                      {round3Details?.data?.initiatorWorkout?.brainScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round3Details?.data?.initiatorWorkout
                                  ?.brainScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {round3Details?.data?.opponentWorkout?.brainScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round3Details?.data?.opponentWorkout
                                  ?.brainScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className={Styles.center}>
                  <h4>{t("challenges.challengeDetail.brain")}</h4>
                </div>
                <div className={Styles.right}>
                  {challengeData.data?.initiator.username !==
                  userDetails.user.username ? (
                    <>
                      {round3Details?.data?.initiatorWorkout?.brainScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round3Details?.data?.initiatorWorkout
                                  ?.brainScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {round3Details?.data?.opponentWorkout?.brainScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round3Details?.data?.opponentWorkout
                                  ?.brainScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className={Styles.round}>
                <div className={Styles.left}>
                  {challengeData.data?.initiator.username ===
                  userDetails.user.username ? (
                    <>
                      {round3Details?.data?.initiatorWorkout?.maxCombo && (
                        <span>
                          <CountUp
                            isCounting
                            end={
                              round3Details?.data?.initiatorWorkout?.maxCombo
                            }
                            duration={0.25}
                            decimalPlaces={0}
                            thousandsSeparator="’"
                          />
                        </span>
                      )}
                    </>
                  ) : (
                    <>
                      {round3Details?.data?.opponentWorkout?.maxCombo && (
                        <span>
                          <CountUp
                            isCounting
                            end={round3Details?.data?.opponentWorkout?.maxCombo}
                            duration={0.25}
                            decimalPlaces={0}
                            thousandsSeparator="’"
                          />
                        </span>
                      )}
                    </>
                  )}
                </div>
                <div className={Styles.center}>
                  <h4>{t("challenges.challengeDetail.bestCombo")}</h4>
                </div>
                <div className={Styles.right}>
                  {challengeData.data?.initiator.username !==
                  userDetails.user.username ? (
                    <>
                      {round3Details?.data?.initiatorWorkout?.maxCombo && (
                        <span>
                          <CountUp
                            isCounting
                            end={
                              round3Details?.data?.initiatorWorkout?.maxCombo
                            }
                            duration={0.25}
                            decimalPlaces={0}
                            thousandsSeparator="’"
                          />
                        </span>
                      )}
                    </>
                  ) : (
                    <>
                      {round3Details?.data?.opponentWorkout?.maxCombo && (
                        <span>
                          <CountUp
                            isCounting
                            end={round3Details?.data?.opponentWorkout?.maxCombo}
                            duration={0.25}
                            decimalPlaces={0}
                            thousandsSeparator="’"
                          />
                        </span>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className={Styles.detailLinkContainer}>
                <div className={Styles.left}>
                  {challengeData.data?.initiator.username ===
                  userDetails.user.username ? (
                    <>
                      {!!round3Details?.data?.initiatorWorkout?.id && (
                        <Link
                          to={`/workout/${round3Details.data.initiatorWorkout.id}`}
                          className={Styles.button}
                        >
                          {t("common.buttons.workoutDetails")}
                        </Link>
                      )}
                    </>
                  ) : (
                    <>
                      {!!round3Details?.data?.opponentWorkout?.id && (
                        <Link
                          to={`/workout/${round3Details.data.opponentWorkout.id}`}
                          className={Styles.button}
                        >
                          {t("common.buttons.workoutDetails")}
                        </Link>
                      )}
                    </>
                  )}
                </div>

                <div className={Styles.right}>
                  {challengeData.data?.initiator.username !==
                  userDetails.user.username ? (
                    <>
                      {!!round3Details?.data?.initiatorWorkout?.id && (
                        <Link
                          to={`/workout/${round3Details.data.initiatorWorkout.id}`}
                          className={Styles.button}
                        >
                          {t("common.buttons.workoutDetails")}
                        </Link>
                      )}
                    </>
                  ) : (
                    <>
                      {!!round3Details?.data?.opponentWorkout?.id && (
                        <Link
                          to={`/workout/${round3Details.data.opponentWorkout.id}`}
                          className={Styles.button}
                        >
                          {t("common.buttons.workoutDetails")}
                        </Link>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {challengeData.data.rounds.length > 1 && (
        <div className={Styles.section}>
          <div className={Styles.header}>
            <h3>
              {t("challenges.challengeDetail.roundAndNumber", { number: 2 })}
            </h3>
          </div>

          <div className={Styles.round}>
            <div className={Styles.left}>
              {challengeData.data.initiator?.username ===
              userDetails?.user?.username ? (
                <>
                  {challengeData.data.rounds[1].initiatorWorkout ? (
                    <div className={Styles.date}>
                      {new Intl.DateTimeFormat(i18n.resolvedLanguage === "en" ? "en-GB" : i18n.resolvedLanguage, {
                        weekday: "long",
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      }).format(
                        new Date(
                          challengeData.data.rounds[1].initiatorWorkout.updatedAt
                        )
                      )}
                    </div>
                  ) : (
                    <div className={Styles.date}>
                      {t("challenges.challengeDetail.workoutPending")}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {challengeData.data.rounds[1].opponentWorkout ? (
                    <div className={Styles.date}>
                      {new Intl.DateTimeFormat(i18n.resolvedLanguage === "en" ? "en-GB" : i18n.resolvedLanguage, {
                        weekday: "long",
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      }).format(
                        new Date(
                          challengeData.data.rounds[1].opponentWorkout.updatedAt
                        )
                      )}
                    </div>
                  ) : (
                    <div className={Styles.date}>
                      {t("challenges.challengeDetail.workoutPending")}
                    </div>
                  )}
                </>
              )}
            </div>

            <div className={Styles.right}>
              {challengeData.data.initiator?.username !==
              userDetails?.user?.username ? (
                <>
                  {challengeData.data.rounds[1].initiatorWorkout ? (
                    <div className={Styles.date}>
                      {new Intl.DateTimeFormat(i18n.resolvedLanguage === "en" ? "en-GB" : i18n.resolvedLanguage, {
                        weekday: "long",
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      }).format(
                        new Date(
                          challengeData.data.rounds[1].initiatorWorkout.updatedAt
                        )
                      )}
                    </div>
                  ) : (
                    <div className={Styles.date}>
                      {t("challenges.challengeDetail.workoutPending")}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {challengeData.data.rounds[1].opponentWorkout ? (
                    <div className={Styles.date}>
                      {new Intl.DateTimeFormat(i18n.resolvedLanguage === "en" ? "en-GB" : i18n.resolvedLanguage, {
                        weekday: "long",
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      }).format(
                        new Date(
                          challengeData.data.rounds[1].opponentWorkout.updatedAt
                        )
                      )}
                    </div>
                  ) : (
                    <div className={Styles.date}>
                      {t("challenges.challengeDetail.workoutPending")}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          <div className={Styles.round}>
            <div className={Styles.left}>
              <div className={Styles.roundMarker}>
                <span
                  className={`${
                    challengeData.data.rounds[1]?.winner
                      ? challengeData.data.rounds[1]?.winner?.username ===
                        userDetails.user.username
                        ? Styles.won
                        : Styles.lost
                      : (challengeData.data.initiator?.username ===
                          userDetails?.user?.username &&
                          challengeData.data.rounds[1]?.initiatorWorkout &&
                          !challengeData.data.rounds[1]?.opponentWorkout) ||
                        (challengeData.data.initiator?.username !==
                          userDetails?.user?.username &&
                          !challengeData.data.rounds[1]?.initiatorWorkout &&
                          challengeData.data.rounds[1]?.opponentWorkout)
                      ? Styles.played
                      : challengeData.data.rounds[1] &&
                        challengeData.data.rounds[1]?.initiatorWorkout
                          ?.score ===
                          challengeData.data.rounds[1]?.opponentWorkout?.score
                      ? Styles.won
                      : ""
                  }`}
                ></span>
              </div>
            </div>
            <div className={Styles.center}>
              <div className={Styles.score}>
                <>
                  <CountUp
                    isCounting
                    end={
                      challengeData.data.initiator?.username ===
                      userDetails?.user?.username
                        ? challengeData.data.rounds.filter(
                            (round) =>
                              round.winner?.username ===
                                challengeData.data.initiator?.username &&
                              round.round < 2
                          ).length
                        : challengeData.data.rounds.filter(
                            (round) =>
                              round.winner?.username ===
                                challengeData.data.opponent?.username &&
                              round.round < 2
                          ).length
                    }
                    duration={0.25}
                    decimalPlaces={0}
                  />
                  :
                  <CountUp
                    isCounting
                    end={
                      challengeData.data.initiator?.username !==
                      userDetails?.user?.username
                        ? challengeData.data.rounds.filter(
                            (round) =>
                              round.winner?.username ===
                                challengeData.data.initiator?.username &&
                              round.round < 2
                          ).length
                        : challengeData.data.rounds.filter(
                            (round) =>
                              round.winner?.username ===
                                challengeData.data.opponent?.username &&
                              round.round < 2
                          ).length
                    }
                    duration={0.25}
                    decimalPlaces={0}
                  />
                </>
              </div>
            </div>
            <div className={Styles.right}>
              <div className={Styles.roundMarker}>
                <span
                  className={`${
                    challengeData.data.rounds[1]?.winner
                      ? challengeData.data.rounds[1]?.winner?.username !==
                        userDetails.user.username
                        ? Styles.won
                        : Styles.lost
                      : (challengeData.data.initiator?.username !==
                          userDetails?.user?.username &&
                          challengeData.data.rounds[1]?.initiatorWorkout &&
                          !challengeData.data.rounds[1]?.opponentWorkout) ||
                        (challengeData.data.initiator?.username ===
                          userDetails?.user?.username &&
                          !challengeData.data.rounds[1]?.initiatorWorkout &&
                          challengeData.data.rounds[1]?.opponentWorkout)
                      ? Styles.played
                      : challengeData.data.rounds[1] &&
                        challengeData.data.rounds[1]?.initiatorWorkout
                          ?.score ===
                          challengeData.data.rounds[1]?.opponentWorkout?.score
                      ? Styles.won
                      : ""
                  }`}
                ></span>
              </div>
            </div>
          </div>

          {challengeData.data.rounds[1].initiatorWorkout &&
            challengeData.data.rounds[1].opponentWorkout && (
              <div className={Styles.round}>
                <div className={Styles.center}>
                  <div className={Styles.resultMessage}>
                    <>
                      {challengeData.data.rounds[1].winner ? (
                        <>
                          {challengeData.data.rounds[1].winner?.username ===
                          userDetails.user.username ? (
                            <div className={Styles.won}>
                              {t("challenges.challengeDetail.youWonThisRound")}
                            </div>
                          ) : (
                            <div className={Styles.lost}>
                              {t("challenges.challengeDetail.youLostThisRound")}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className={Styles.draw}>
                          {t("challenges.challengeDetail.thisRoundIsADraw")}
                        </div>
                      )}
                    </>
                  </div>
                </div>
              </div>
            )}

          <div className={Styles.round}>
            <div className={Styles.center}>
              <button
                className="small"
                onClick={() => setShowRound2Details(!showRound2Details)}
              >
                {showRound2Details ? t("common.buttons.hideDetails") : t("common.buttons.showDetails")}
              </button>
            </div>
          </div>

          {showRound2Details && round2Details && (
            <div className={Styles.details}>
              <div className={Styles.round}>
                <div className={Styles.left}>
                  {challengeData.data?.initiator.username ===
                  userDetails.user.username ? (
                    <>
                      {round2Details?.data?.initiatorWorkout?.score && (
                        <span>
                          <CountUp
                            isCounting
                            end={round2Details?.data?.initiatorWorkout?.score}
                            duration={0.25}
                            decimalPlaces={0}
                            thousandsSeparator="’"
                          />
                        </span>
                      )}
                    </>
                  ) : (
                    <>
                      {round2Details?.data?.opponentWorkout?.score && (
                        <span>
                          <CountUp
                            isCounting
                            end={round2Details?.data?.opponentWorkout?.score}
                            duration={0.25}
                            decimalPlaces={0}
                            thousandsSeparator="’"
                          />
                        </span>
                      )}
                    </>
                  )}
                </div>
                <div className={Styles.center}>
                  <h4>{t("challenges.challengeDetail.score")}</h4>
                </div>
                <div className={Styles.right}>
                  {challengeData.data?.initiator.username !==
                  userDetails.user.username ? (
                    <>
                      {round2Details?.data?.initiatorWorkout?.score && (
                        <span>
                          <CountUp
                            isCounting
                            end={round2Details?.data?.initiatorWorkout?.score}
                            duration={0.25}
                            decimalPlaces={0}
                            thousandsSeparator="’"
                          />
                        </span>
                      )}
                    </>
                  ) : (
                    <>
                      {round2Details?.data?.opponentWorkout?.score && (
                        <span>
                          <CountUp
                            isCounting
                            end={round2Details?.data?.opponentWorkout?.score}
                            duration={0.25}
                            decimalPlaces={0}
                            thousandsSeparator="’"
                          />
                        </span>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className={Styles.round}>
                <div className={Styles.left}>
                  {challengeData.data?.initiator.username ===
                  userDetails.user.username ? (
                    <>
                      {round2Details?.data?.initiatorWorkout?.dualflowScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round2Details?.data?.initiatorWorkout
                                  ?.dualflowScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {round2Details?.data?.opponentWorkout?.dualflowScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round2Details?.data?.opponentWorkout
                                  ?.dualflowScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className={Styles.center}>
                  <h4>{t("challenges.challengeDetail.flow")}</h4>
                </div>
                <div className={Styles.right}>
                  {challengeData.data?.initiator.username !==
                  userDetails.user.username ? (
                    <>
                      {round2Details?.data?.initiatorWorkout?.dualflowScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round2Details?.data?.initiatorWorkout
                                  ?.dualflowScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {round2Details?.data?.opponentWorkout?.dualflowScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round2Details?.data?.opponentWorkout
                                  ?.dualflowScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className={Styles.round}>
                <div className={Styles.left}>
                  {challengeData.data?.initiator.username ===
                  userDetails.user.username ? (
                    <>
                      {round2Details?.data?.initiatorWorkout?.brainScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round2Details?.data?.initiatorWorkout
                                  ?.brainScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {round2Details?.data?.opponentWorkout?.bodyScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round2Details?.data?.opponentWorkout
                                  ?.bodyScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className={Styles.center}>
                  <h4>{t("challenges.challengeDetail.body")}</h4>
                </div>
                <div className={Styles.right}>
                  {challengeData.data?.initiator.username !==
                  userDetails.user.username ? (
                    <>
                      {round2Details?.data?.initiatorWorkout?.bodyScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round2Details?.data?.initiatorWorkout
                                  ?.bodyScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {round2Details?.data?.opponentWorkout?.bodyScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round2Details?.data?.opponentWorkout
                                  ?.bodyScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className={Styles.round}>
                <div className={Styles.left}>
                  {challengeData.data?.initiator.username ===
                  userDetails.user.username ? (
                    <>
                      {round2Details?.data?.initiatorWorkout?.brainScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round2Details?.data?.initiatorWorkout
                                  ?.brainScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {round2Details?.data?.opponentWorkout?.brainScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round2Details?.data?.opponentWorkout
                                  ?.brainScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className={Styles.center}>
                  <h4>{t("challenges.challengeDetail.brain")}</h4>
                </div>
                <div className={Styles.right}>
                  {challengeData.data?.initiator.username !==
                  userDetails.user.username ? (
                    <>
                      {round2Details?.data?.initiatorWorkout?.brainScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round2Details?.data?.initiatorWorkout
                                  ?.brainScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {round2Details?.data?.opponentWorkout?.brainScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round2Details?.data?.opponentWorkout
                                  ?.brainScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className={Styles.round}>
                <div className={Styles.left}>
                  {challengeData.data?.initiator.username ===
                  userDetails.user.username ? (
                    <>
                      {round2Details?.data?.initiatorWorkout?.maxCombo && (
                        <span>
                          <CountUp
                            isCounting
                            end={
                              round2Details?.data?.initiatorWorkout?.maxCombo
                            }
                            duration={0.25}
                            decimalPlaces={0}
                            thousandsSeparator="’"
                          />
                        </span>
                      )}
                    </>
                  ) : (
                    <>
                      {round2Details?.data?.opponentWorkout?.maxCombo && (
                        <span>
                          <CountUp
                            isCounting
                            end={round2Details?.data?.opponentWorkout?.maxCombo}
                            duration={0.25}
                            decimalPlaces={0}
                            thousandsSeparator="’"
                          />
                        </span>
                      )}
                    </>
                  )}
                </div>
                <div className={Styles.center}>
                  <h4>{t("challenges.challengeDetail.bestCombo")}</h4>
                </div>
                <div className={Styles.right}>
                  {challengeData.data?.initiator.username !==
                  userDetails.user.username ? (
                    <>
                      {round2Details?.data?.initiatorWorkout?.maxCombo && (
                        <span>
                          <CountUp
                            isCounting
                            end={
                              round2Details?.data?.initiatorWorkout?.maxCombo
                            }
                            duration={0.25}
                            decimalPlaces={0}
                            thousandsSeparator="’"
                          />
                        </span>
                      )}
                    </>
                  ) : (
                    <>
                      {round2Details?.data?.opponentWorkout?.maxCombo && (
                        <span>
                          <CountUp
                            isCounting
                            end={round2Details?.data?.opponentWorkout?.maxCombo}
                            duration={0.25}
                            decimalPlaces={0}
                            thousandsSeparator="’"
                          />
                        </span>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className={Styles.detailLinkContainer}>
                <div className={Styles.left}>
                  {challengeData.data?.initiator.username ===
                  userDetails.user.username ? (
                    <>
                      {!!round2Details?.data?.initiatorWorkout?.id && (
                        <Link
                          to={`/workout/${round2Details.data.initiatorWorkout.id}`}
                          className={Styles.button}
                        >
                          {t("common.buttons.workoutDetails")}
                        </Link>
                      )}
                    </>
                  ) : (
                    <>
                      {!!round2Details?.data?.opponentWorkout?.id && (
                        <Link
                          to={`/workout/${round2Details.data.opponentWorkout.id}`}
                          className={Styles.button}
                        >
                          {t("common.buttons.workoutDetails")}
                        </Link>
                      )}
                    </>
                  )}
                </div>

                <div className={Styles.right}>
                  {challengeData.data?.initiator.username !==
                  userDetails.user.username ? (
                    <>
                      {!!round2Details?.data?.initiatorWorkout?.id && (
                        <Link
                          to={`/workout/${round2Details.data.initiatorWorkout.id}`}
                          className={Styles.button}
                        >
                          {t("common.buttons.workoutDetails")}
                        </Link>
                      )}
                    </>
                  ) : (
                    <>
                      {!!round2Details?.data?.opponentWorkout?.id && (
                        <Link
                          to={`/workout/${round2Details.data.opponentWorkout.id}`}
                          className={Styles.button}
                        >
                          {t("common.buttons.workoutDetails")}
                        </Link>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {challengeData.data.rounds.length > 0 && (
        <div className={Styles.section}>
          <div className={Styles.header}>
            <h3>
              {t("challenges.challengeDetail.roundAndNumber", { number: 1 })}
            </h3>
          </div>

          <div className={Styles.round}>
            <div className={Styles.left}>
              {challengeData.data.initiator?.username ===
              userDetails?.user?.username ? (
                <>
                  {challengeData.data.rounds[0].initiatorWorkout ? (
                    <div className={Styles.date}>
                      {new Intl.DateTimeFormat(i18n.resolvedLanguage === "en" ? "en-GB" : i18n.resolvedLanguage, {
                        weekday: "long",
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      }).format(
                        new Date(
                          challengeData.data.rounds[0].initiatorWorkout.updatedAt
                        )
                      )}
                    </div>
                  ) : (
                    <div className={Styles.date}>
                      {t("challenges.challengeDetail.workoutPending")}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {challengeData.data.rounds[0].opponentWorkout ? (
                    <div className={Styles.date}>
                      {new Intl.DateTimeFormat(i18n.resolvedLanguage === "en" ? "en-GB" : i18n.resolvedLanguage, {
                        weekday: "long",
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      }).format(
                        new Date(
                          challengeData.data.rounds[0].opponentWorkout.updatedAt
                        )
                      )}
                    </div>
                  ) : (
                    <div className={Styles.date}>
                      {t("challenges.challengeDetail.workoutPending")}
                    </div>
                  )}
                </>
              )}
            </div>

            <div className={Styles.right}>
              {challengeData.data.initiator?.username !==
              userDetails?.user?.username ? (
                <>
                  {challengeData.data.rounds[0].initiatorWorkout ? (
                    <div className={Styles.date}>
                      {new Intl.DateTimeFormat(i18n.resolvedLanguage === "en" ? "en-GB" : i18n.resolvedLanguage, {
                        weekday: "long",
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      }).format(
                        new Date(
                          challengeData.data.rounds[0].initiatorWorkout.updatedAt
                        )
                      )}
                    </div>
                  ) : (
                    <div className={Styles.date}>
                      {t("challenges.challengeDetail.workoutPending")}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {challengeData.data.rounds[0].opponentWorkout ? (
                    <div className={Styles.date}>
                      {new Intl.DateTimeFormat(i18n.resolvedLanguage === "en" ? "en-GB" : i18n.resolvedLanguage, {
                        weekday: "long",
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      }).format(
                        new Date(
                          challengeData.data.rounds[0].opponentWorkout.updatedAt
                        )
                      )}
                    </div>
                  ) : (
                    <div className={Styles.date}>
                      {t("challenges.challengeDetail.workoutPending")}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          <div className={Styles.round}>
            <div className={Styles.left}>
              <div className={Styles.roundMarker}>
                <span
                  className={`${
                    challengeData.data.rounds[0]?.winner
                      ? challengeData.data.rounds[0]?.winner?.username ===
                        userDetails.user.username
                        ? Styles.won
                        : Styles.lost
                      : (challengeData.data.initiator?.username ===
                          userDetails?.user?.username &&
                          challengeData.data.rounds[0]?.initiatorWorkout &&
                          !challengeData.data.rounds[0]?.opponentWorkout) ||
                        (challengeData.data.initiator?.username !==
                          userDetails?.user?.username &&
                          !challengeData.data.rounds[0]?.initiatorWorkout &&
                          challengeData.data.rounds[0]?.opponentWorkout)
                      ? Styles.played
                      : challengeData.data.rounds[0] &&
                        challengeData.data.rounds[0]?.initiatorWorkout
                          ?.score ===
                          challengeData.data.rounds[0]?.opponentWorkout?.score
                      ? Styles.won
                      : ""
                  }`}
                ></span>
              </div>
            </div>
            <div className={Styles.center}>
              <div className={Styles.score}>
                <>
                  <CountUp
                    isCounting
                    end={
                      challengeData.data.initiator?.username ===
                      userDetails?.user?.username
                        ? challengeData.data.rounds.filter(
                            (round) =>
                              round.winner?.username ===
                                challengeData.data.initiator?.username &&
                              round.round < 1
                          ).length
                        : challengeData.data.rounds.filter(
                            (round) =>
                              round.winner?.username ===
                                challengeData.data.opponent?.username &&
                              round.round < 1
                          ).length
                    }
                    duration={0.25}
                    decimalPlaces={0}
                  />
                  :
                  <CountUp
                    isCounting
                    end={
                      challengeData.data.initiator?.username !==
                      userDetails?.user?.username
                        ? challengeData.data.rounds.filter(
                            (round) =>
                              round.winner?.username ===
                                challengeData.data.initiator?.username &&
                              round.round < 1
                          ).length
                        : challengeData.data.rounds.filter(
                            (round) =>
                              round.winner?.username ===
                                challengeData.data.opponent?.username &&
                              round.round < 1
                          ).length
                    }
                    duration={0.25}
                    decimalPlaces={0}
                  />
                </>
              </div>
            </div>
            <div className={Styles.right}>
              <div className={Styles.roundMarker}>
                <span
                  className={`${
                    challengeData.data.rounds[0]?.winner
                      ? challengeData.data.rounds[0]?.winner?.username !==
                        userDetails.user.username
                        ? Styles.won
                        : Styles.lost
                      : (challengeData.data.initiator?.username !==
                          userDetails?.user?.username &&
                          challengeData.data.rounds[0]?.initiatorWorkout &&
                          !challengeData.data.rounds[0]?.opponentWorkout) ||
                        (challengeData.data.initiator?.username ===
                          userDetails?.user?.username &&
                          !challengeData.data.rounds[0]?.initiatorWorkout &&
                          challengeData.data.rounds[0]?.opponentWorkout)
                      ? Styles.played
                      : challengeData.data.rounds[0] &&
                        challengeData.data.rounds[0]?.initiatorWorkout
                          ?.score ===
                          challengeData.data.rounds[0]?.opponentWorkout?.score
                      ? Styles.won
                      : ""
                  }`}
                ></span>
              </div>
            </div>
          </div>

          {challengeData.data.rounds[0].initiatorWorkout &&
            challengeData.data.rounds[0].opponentWorkout && (
              <div className={Styles.round}>
                <div className={Styles.center}>
                  <div className={Styles.resultMessage}>
                    <>
                      {challengeData.data.rounds[0].winner ? (
                        <>
                          {challengeData.data.rounds[0].winner?.username ===
                          userDetails.user.username ? (
                            <div className={Styles.won}>
                              {t("challenges.challengeDetail.youWonThisRound")}
                            </div>
                          ) : (
                            <div className={Styles.lost}>
                              {t("challenges.challengeDetail.youLostThisRound")}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className={Styles.draw}>
                          {t("challenges.challengeDetail.thisRoundIsADraw")}
                        </div>
                      )}
                    </>
                  </div>
                </div>
              </div>
            )}

          <div className={Styles.round}>
            <div className={Styles.center}>
              <button
                className="small"
                onClick={() => setShowRound1Details(!showRound1Details)}
              >
                {showRound1Details ? t("common.buttons.hideDetails") : t("common.buttons.showDetails")}
              </button>
            </div>
          </div>

          {showRound1Details && round1Details && (
            <div className={Styles.details}>
              <div className={Styles.round}>
                <div className={Styles.left}>
                  {challengeData.data?.initiator.username ===
                  userDetails.user.username ? (
                    <>
                      {round1Details?.data?.initiatorWorkout?.score && (
                        <span>
                          <CountUp
                            isCounting
                            end={round1Details?.data?.initiatorWorkout?.score}
                            duration={0.25}
                            decimalPlaces={0}
                            thousandsSeparator="’"
                          />
                        </span>
                      )}
                    </>
                  ) : (
                    <>
                      {round1Details?.data?.opponentWorkout?.score && (
                        <span>
                          <CountUp
                            isCounting
                            end={round1Details?.data?.opponentWorkout?.score}
                            duration={0.25}
                            decimalPlaces={0}
                            thousandsSeparator="’"
                          />
                        </span>
                      )}
                    </>
                  )}
                </div>
                <div className={Styles.center}>
                  <h4>{t("challenges.challengeDetail.score")}</h4>
                </div>
                <div className={Styles.right}>
                  {challengeData.data?.initiator.username !==
                  userDetails.user.username ? (
                    <>
                      {round1Details?.data?.initiatorWorkout?.score && (
                        <span>
                          <CountUp
                            isCounting
                            end={round1Details?.data?.initiatorWorkout?.score}
                            duration={0.25}
                            decimalPlaces={0}
                            thousandsSeparator="’"
                          />
                        </span>
                      )}
                    </>
                  ) : (
                    <>
                      {round1Details?.data?.opponentWorkout?.score && (
                        <span>
                          <CountUp
                            isCounting
                            end={round1Details?.data?.opponentWorkout?.score}
                            duration={0.25}
                            decimalPlaces={0}
                            thousandsSeparator="’"
                          />
                        </span>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className={Styles.round}>
                <div className={Styles.left}>
                  {challengeData.data?.initiator.username ===
                  userDetails.user.username ? (
                    <>
                      {round1Details?.data?.initiatorWorkout?.dualflowScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round1Details?.data?.initiatorWorkout
                                  ?.dualflowScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {round1Details?.data?.opponentWorkout?.dualflowScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round1Details?.data?.opponentWorkout
                                  ?.dualflowScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className={Styles.center}>
                  <h4>{t("challenges.challengeDetail.flow")}</h4>
                </div>
                <div className={Styles.right}>
                  {challengeData.data?.initiator.username !==
                  userDetails.user.username ? (
                    <>
                      {round1Details?.data?.initiatorWorkout?.dualflowScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round1Details?.data?.initiatorWorkout
                                  ?.dualflowScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {round1Details?.data?.opponentWorkout?.dualflowScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round1Details?.data?.opponentWorkout
                                  ?.dualflowScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className={Styles.round}>
                <div className={Styles.left}>
                  {challengeData.data?.initiator.username ===
                  userDetails.user.username ? (
                    <>
                      {round1Details?.data?.initiatorWorkout?.brainScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round1Details?.data?.initiatorWorkout
                                  ?.brainScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {round1Details?.data?.opponentWorkout?.bodyScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round1Details?.data?.opponentWorkout
                                  ?.bodyScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className={Styles.center}>
                  <h4>{t("challenges.challengeDetail.body")}</h4>
                </div>
                <div className={Styles.right}>
                  {challengeData.data?.initiator.username !==
                  userDetails.user.username ? (
                    <>
                      {round1Details?.data?.initiatorWorkout?.bodyScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round1Details?.data?.initiatorWorkout
                                  ?.bodyScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {round1Details?.data?.opponentWorkout?.bodyScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round1Details?.data?.opponentWorkout
                                  ?.bodyScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className={Styles.round}>
                <div className={Styles.left}>
                  {challengeData.data?.initiator.username ===
                  userDetails.user.username ? (
                    <>
                      {round1Details?.data?.initiatorWorkout?.brainScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round1Details?.data?.initiatorWorkout
                                  ?.brainScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {round1Details?.data?.opponentWorkout?.brainScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round1Details?.data?.opponentWorkout
                                  ?.brainScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className={Styles.center}>
                  <h4>{t("challenges.challengeDetail.brain")}</h4>
                </div>
                <div className={Styles.right}>
                  {challengeData.data?.initiator.username !==
                  userDetails.user.username ? (
                    <>
                      {round1Details?.data?.initiatorWorkout?.brainScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round1Details?.data?.initiatorWorkout
                                  ?.brainScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {round1Details?.data?.opponentWorkout?.brainScore && (
                        <>
                          <span>
                            <CountUp
                              isCounting
                              end={
                                round1Details?.data?.opponentWorkout
                                  ?.brainScore * 100
                              }
                              duration={0.25}
                              decimalPlaces={2}
                              thousandsSeparator="’"
                            />
                            %
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className={Styles.round}>
                <div className={Styles.left}>
                  {challengeData.data?.initiator.username ===
                  userDetails.user.username ? (
                    <>
                      {round1Details?.data?.initiatorWorkout?.maxCombo && (
                        <span>
                          <CountUp
                            isCounting
                            end={
                              round1Details?.data?.initiatorWorkout?.maxCombo
                            }
                            duration={0.25}
                            decimalPlaces={0}
                            thousandsSeparator="’"
                          />
                        </span>
                      )}
                    </>
                  ) : (
                    <>
                      {round1Details?.data?.opponentWorkout?.maxCombo && (
                        <span>
                          <CountUp
                            isCounting
                            end={round1Details?.data?.opponentWorkout?.maxCombo}
                            duration={0.25}
                            decimalPlaces={0}
                            thousandsSeparator="’"
                          />
                        </span>
                      )}
                    </>
                  )}
                </div>
                <div className={Styles.center}>
                  <h4>{t("challenges.challengeDetail.bestCombo")}</h4>
                </div>
                <div className={Styles.right}>
                  {challengeData.data?.initiator.username !==
                  userDetails.user.username ? (
                    <>
                      {round1Details?.data?.initiatorWorkout?.maxCombo && (
                        <span>
                          <CountUp
                            isCounting
                            end={
                              round1Details?.data?.initiatorWorkout?.maxCombo
                            }
                            duration={0.25}
                            decimalPlaces={0}
                            thousandsSeparator="’"
                          />
                        </span>
                      )}
                    </>
                  ) : (
                    <>
                      {round1Details?.data?.opponentWorkout?.maxCombo && (
                        <span>
                          <CountUp
                            isCounting
                            end={round1Details?.data?.opponentWorkout?.maxCombo}
                            duration={0.25}
                            decimalPlaces={0}
                            thousandsSeparator="’"
                          />
                        </span>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className={Styles.detailLinkContainer}>
                <div className={Styles.left}>
                  {challengeData.data?.initiator.username ===
                  userDetails.user.username ? (
                    <>
                      {!!round1Details?.data?.initiatorWorkout?.id && (
                        <Link
                          to={`/workout/${round1Details.data.initiatorWorkout.id}`}
                          className={Styles.button}
                        >
                          {t("common.buttons.workoutDetails")}
                        </Link>
                      )}
                    </>
                  ) : (
                    <>
                      {!!round1Details?.data?.opponentWorkout?.id && (
                        <Link
                          to={`/workout/${round1Details.data.opponentWorkout.id}`}
                          className={Styles.button}
                        >
                          {t("common.buttons.workoutDetails")}
                        </Link>
                      )}
                    </>
                  )}
                </div>

                <div className={Styles.right}>
                  {challengeData.data?.initiator.username !==
                  userDetails.user.username ? (
                    <>
                      {!!round1Details?.data?.initiatorWorkout?.id && (
                        <Link
                          to={`/workout/${round1Details.data.initiatorWorkout.id}`}
                          className={Styles.button}
                        >
                          {t("common.buttons.workoutDetails")}
                        </Link>
                      )}
                    </>
                  ) : (
                    <>
                      {!!round1Details?.data?.opponentWorkout?.id && (
                        <Link
                          to={`/workout/${round1Details.data.opponentWorkout.id}`}
                          className={Styles.button}
                        >
                          {t("common.buttons.workoutDetails")}
                        </Link>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {messageData && messageData.length > 0 && (
        <div className={Styles.section}>
          <div className={Styles.header}>
            <h3>{t("challenges.challengeLog")}</h3>
          </div>
          <InfiniteScroll
            dataLength={dataLength}
            next={fetchNextPage}
            hasMore={hasNextPage}
            loader={
              dataLength > 0 && <LoadingSpinner positionRelative={true} />
            }
            className={Styles.container}
          >
            {data.pages.map((group, i, array) => (
              <React.Fragment key={i}></React.Fragment>
            ))}

            {messageData.map((message) => (
              <div key={message.id} className={Styles.pendingChallenge}>
                <div className={Styles.message}>
                  <div className={Styles.messageContent}>
                    {!!message.body && (
                      <p dangerouslySetInnerHTML={{ __html: message.body }} />
                    )}
                    <p className={Styles.dateSeparator}>
                      {new Intl.DateTimeFormat(i18n.resolvedLanguage === "en" ? "en-GB" : i18n.resolvedLanguage, {
                        weekday: "long",
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      }).format(new Date(message.createdAt))}
                    </p>
                  </div>

                  <div className={Styles.circle}>
                    {message.sender.profileImage ? (
                      <img
                        srcSet={`${ROOT_URL}/public/images/${message.sender.profileImage}-40x40.jpg 40w, ${ROOT_URL}/public/images/${message.sender.profileImage}-80x80.jpg 80w, ${ROOT_URL}/public/images/${message.sender.profileImage}-120x120.jpg 120w, ${ROOT_URL}/public/images/${message.sender.profileImage}-160x160.jpg 160w`}
                        sizes="40px"
                        src={`${ROOT_URL}/public/images/${message.sender.profileImage}-40x40.jpg`}
                        alt={message.sender.username}
                      />
                    ) : (
                      <FaUserAlt className={Styles.placeholder} />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </div>
      )}
    </Layout>
  );
};

export default ChallengeDetail;
