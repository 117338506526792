const Leaderboard = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.75 14.0312C6.5775 14.0312 6.4375 14.1713 6.4375 14.3438C6.4375 14.5163 6.5775 14.6562 6.75 14.6562C6.9225 14.6562 7.0625 14.5163 7.0625 14.3438C7.0625 14.1713 6.9225 14.0312 6.75 14.0312Z"
        fill="white"
      />
      <path
        d="M8.625 10.9062H8.3125V8.71875C8.3125 8.54616 8.17259 8.40625 8 8.40625H7.375C7.20241 8.40625 7.0625 8.54616 7.0625 8.71875C7.0625 8.89134 7.20241 9.03125 7.375 9.03125H7.6875V10.9062H7.375C7.20241 10.9062 7.0625 11.0462 7.0625 11.2188C7.0625 11.3913 7.20241 11.5312 7.375 11.5312H8.625C8.79759 11.5312 8.9375 11.3913 8.9375 11.2188C8.9375 11.0462 8.79759 10.9062 8.625 10.9062Z"
        fill="white"
      />
      <path
        d="M3.53125 12.7812C3.70384 12.7812 3.84375 12.6413 3.84375 12.4688V11.2188C3.84375 11.0462 3.70384 10.9062 3.53125 10.9062H2.28125C2.10866 10.9062 1.96875 11.0462 1.96875 11.2188C1.96875 11.3913 2.10866 11.5312 2.28125 11.5312H3.21875V12.1562H2.28125C2.10866 12.1562 1.96875 12.2962 1.96875 12.4688V13.7188C1.96875 13.8913 2.10866 14.0312 2.28125 14.0312H3.53125C3.70384 14.0312 3.84375 13.8913 3.84375 13.7188C3.84375 13.5462 3.70384 13.4062 3.53125 13.4062H2.59375V12.7812H3.53125Z"
        fill="white"
      />
      <path
        d="M13.7188 11.5312H12.4688C12.2962 11.5312 12.1562 11.6712 12.1562 11.8438C12.1562 12.0163 12.2962 12.1562 12.4688 12.1562H13.4062V12.7812H12.4688C12.2962 12.7812 12.1562 12.9212 12.1562 13.0938C12.1562 13.2663 12.2962 13.4062 12.4688 13.4062H13.4062V14.0312H12.4688C12.2962 14.0312 12.1562 14.1712 12.1562 14.3438C12.1562 14.5163 12.2962 14.6562 12.4688 14.6562H13.7188C13.8913 14.6562 14.0312 14.5163 14.0312 14.3438V11.8438C14.0312 11.6712 13.8913 11.5312 13.7188 11.5312Z"
        fill="white"
      />
      <path
        d="M15.6875 10.2812H10.8125V7.375C10.8125 7.20241 10.6726 7.0625 10.5 7.0625H5.5C5.32741 7.0625 5.1875 7.20241 5.1875 7.375V9.03125H0.3125C0.139906 9.03125 0 9.17116 0 9.34375V15.6875C0 15.8601 0.139906 16 0.3125 16H5.5H10.5H15.6875C15.8601 16 16 15.8601 16 15.6875V10.5938C16 10.4212 15.8601 10.2812 15.6875 10.2812ZM5.1875 15.375H0.625V9.65625H5.1875V15.375ZM10.1875 15.375H5.8125V7.6875H10.1875V15.375ZM15.375 15.375H10.8125V10.9062H15.375V15.375Z"
        fill="white"
      />
      <path
        d="M11.1023 2.38153C11.0656 2.26841 10.9678 2.18594 10.8501 2.16884L9.07083 1.91038L8.27158 0.181375C8.22046 0.0708125 8.10977 0 7.98793 0C7.86615 0 7.7554 0.0707813 7.70427 0.181344L6.90474 1.91044L5.12549 2.16891C5.00777 2.186 4.90999 2.26847 4.87321 2.38159C4.83646 2.49472 4.86712 2.61891 4.9523 2.70194L6.24449 3.96147L5.93827 5.83366C5.91915 5.95063 5.9678 6.06834 6.06396 6.13766C6.16015 6.20694 6.28724 6.21588 6.39208 6.16072L7.98787 5.32156L9.58333 6.16069C9.62908 6.18475 9.67902 6.19659 9.72877 6.19659C9.79315 6.19659 9.85724 6.17672 9.91149 6.13763C10.0076 6.06834 10.0563 5.95063 10.0372 5.83363L9.73099 3.96144L11.0232 2.70191C11.1084 2.61888 11.1391 2.49469 11.1023 2.38153ZM9.17824 3.62747C9.10521 3.69866 9.07149 3.80103 9.08796 3.90169L9.31921 5.3155L8.13337 4.69184C8.0423 4.64397 7.93352 4.64397 7.84246 4.69184L6.6563 5.31556L6.88755 3.90169C6.90402 3.80103 6.87033 3.69866 6.79727 3.62747L5.84199 2.69634L7.16221 2.50456C7.26677 2.48938 7.35658 2.42238 7.40093 2.32647L7.98787 1.05716L8.57455 2.32641C8.6189 2.42234 8.70871 2.48934 8.8133 2.50453L10.1335 2.69631L9.17824 3.62747Z"
        fill="white"
      />
      <path
        d="M9.25 14.0312H8C7.82741 14.0312 7.6875 14.1712 7.6875 14.3438C7.6875 14.5163 7.82741 14.6562 8 14.6562H9.25C9.42259 14.6562 9.5625 14.5163 9.5625 14.3438C9.5625 14.1712 9.42259 14.0312 9.25 14.0312Z"
        fill="white"
      />
    </svg>
  );
};

export default Leaderboard;
