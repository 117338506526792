import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { registerUser, useAuthState, useAuthDispatch } from "../../Context";

import Logo from "../../Assets/Logo.js";
import Container from "../../Components/Container/Container";
import Styles from "./Register.module.scss";

import { CSSTransition } from "react-transition-group";
import Alert from "../../Components/Alert/Alert";
import { Trans, useTranslation } from "react-i18next";

const Register = () => {
  const [username, setUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  const [license, setLicense] = React.useState(false);

  /************************************************
   *
   *      QUICK FIX => PLEASE REMOVE LATER !!!
   *
   *************************************************/
   React.useEffect(() => {
    if (document.body.classList.contains("menu-open")) {
      document.body.classList.remove("menu-open");
    }
  }, []);

  const { t } = useTranslation();

  const dispatch = useAuthDispatch();
  const { user, loading, 
    // errorMessage 
  } = useAuthState(); // read the values of loading and errorMessage from context

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = { email, password, username };
    try {
      const response = await registerUser(dispatch, payload); // registerUser action makes the request and handles all the neccessary state changes

      if (!response?.success) {
        setShowAlert(true);
        return;
      }
      navigate("/verify_email"); // navigate to Verify Email on success
    } catch (error) {
      console.log(error);
      setShowAlert(true);
    }
  };

  if (user?.email) return <Navigate to="/" />;

  return (
    <Container fullHeight>
      <h1>{t("register.headline")}</h1>

      <form className={Styles.form} onSubmit={handleSubmit}>
        <CSSTransition
          in={showAlert}
          timeout={200}
          classNames="fade"
          unmountOnExit
        >
          <Alert
            // message={errorMessage}
            message={t("register.errorMessage")}
            color="danger"
            dismissible={true}
            onClick={setShowAlert}
          />
        </CSSTransition>
        <input
          type="text"
          name="username"
          value={username}
          placeholder={t("common.inputPlaceholders.username")}
          onChange={(e) => setUsername(e.target.value)}
          disabled={loading}
          autoComplete="on"
          required
        />
        <input
          type="email"
          name="email"
          value={email}
          placeholder={t("common.inputPlaceholders.email")}
          onChange={(e) => setEmail(e.target.value)}
          disabled={loading}
          autoComplete="on"
          required
        />
        <input
          type="password"
          name="password"
          value={password}
          placeholder={t("common.inputPlaceholders.password")}
          onChange={(e) => setPassword(e.target.value)}
          disabled={loading}
          autoComplete="on"
          minLength="4"
          required
          pattern="[a-zA-Z0-9!@#$%^*_|]{4,100}"
        />

        <p className={Styles.warning}>{t("common.passwordInstructions")}</p>

        <label htmlFor="license" className="checkbox-container">
          <Trans i18nKey={"register.termsConditions"}>
            <Link to="/terms-conditions"></Link>
            <Link to="/eula"></Link>
          </Trans>
          <input
            type="checkbox"
            id="license"
            name="license"
            value={license}
            onChange={(e) => setLicense(e.target.checked)}
            required
          />
          <span className="checkmark" />
        </label>

        <input
          type="submit"
          disabled={!license || loading}
          value={t("common.buttons.createAccount")}
        />
        <p className="text-center">
          <Trans i18nKey={"register.login"}>
            <Link to="/login"></Link>
          </Trans>
        </p>
      </form>

      <div className={Styles.footer}>
        <Logo />
      </div>
    </Container>
  );
};

export default Register;
