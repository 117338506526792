import React from "react";
import { useAuthState } from "../../Context";
import Container from "../../Components/Container/Container";
import { Link, useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import Alert from "../../Components/Alert/Alert";
import SpheryAppDefaultImageMale from "../../Assets/SpheryAppDefaultImageMale.jpg";
import Styles from "./UploadAvatar.module.scss";

import ROOT_URL from "../../Config/rootUrl";
import { useTranslation } from "react-i18next";

const UploadAvatar = () => {
  const userDetails = useAuthState();
  const [data, setData] = React.useState(null);
  const [avatar, setAvatar] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [showAlert, setShowAlert] = React.useState(false);

  const { t } = useTranslation();

  const navigate = useNavigate();

  /************************************************
   *
   *      QUICK FIX => PLEASE REMOVE LATER !!!
   *
   *************************************************/
  React.useEffect(() => {
    if (document.body.classList.contains("menu-open")) {
      document.body.classList.remove("menu-open");
    }
  }, []);

  React.useEffect(() => {
    const token = userDetails?.token;
    if (!token) return;

    async function fetchData() {
      const response = await fetch(`${ROOT_URL}/api/v1/profile`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (!response.ok) {
        // console.log(response);
        setErrorMessage(data.error.message);
        setShowAlert(true);
        return;
      }

      // console.log(data);
      setData(data);
    }

    fetchData();
  }, [userDetails?.token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("avatar", avatar);

      // console.log(formData);

      const token = userDetails?.token;
      if (!token) return;

      const response = await fetch(`${ROOT_URL}/api/v1/profile`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      await response.json();

      if (!response.ok) {
        // console.log(response);
        setErrorMessage(t("settings.avatarUpdateError"));
        setShowAlert(true);
        return;
      }

      navigate("/health-data"); // navigate to health data on success
    } catch (err) {
      // console.error(err);
      setShowAlert(true);
      return;
    }
  };

  return (
    <Container fullHeight>
      <h1 className={Styles.pageHeader}>{t("uploadAvatar.headline")}</h1>

      <div className={Styles.imageContainer}>
        <svg viewBox="0 0 100 15" className={Styles.arrowTop}>
          <polygon points="0,0 0,15 100,0" />
        </svg>

        <svg viewBox="0 0 100 15" className={Styles.arrowBottom}>
          <polygon points="0,15 100,0 100,15" />
        </svg>

        <picture>
          {!avatar && data?.profileImage && (
            <img
              srcSet={`${ROOT_URL}/public/images/${data.profileImage}-320x320.jpg 320w, ${ROOT_URL}/public/images/${data.profileImage}-640x640.jpg 640w, ${ROOT_URL}/public/images/${data.profileImage}-960x960.jpg 960w, ${ROOT_URL}/public/images/${data.profileImage}-1200x1200.jpg 1200w`}
              sizes="(max-width: 1199px) 100vw, 1168px"
              src={`${ROOT_URL}/public/images/${data.profileImage}-640x640.jpg`}
              alt=""
            />
          )}
          {avatar && (
            <img
              src={URL.createObjectURL(avatar)}
              className={Styles.previewImage}
              alt=""
            />
          )}
          {!avatar && !data?.profileImage && (
            <img src={SpheryAppDefaultImageMale} alt="" />
          )}
        </picture>

        <form className={Styles.form} onSubmit={handleSubmit}>
          <CSSTransition
            in={showAlert}
            timeout={200}
            classNames="fade"
            unmountOnExit
          >
            <Alert
              message={errorMessage}
              color="danger"
              dismissible={true}
              onClick={setShowAlert}
            />
          </CSSTransition>
          <label htmlFor="avatar" className={Styles.button}>
            {t("common.buttons.browseImages")}
            <input
              id="avatar"
              type="file"
              name="avatar"
              onChange={(e) => setAvatar(e.target.files[0])}
            />
          </label>
          {avatar && (
            <input
              type="submit"
              value={t("common.buttons.uploadImage")}
              disabled={!avatar}
              accept="image/jpeg, image/png, image/jpg, image/webp"
            />
          )}
        </form>
      </div>

      <div className={Styles.skipLinkContainer}>
        <Link to="/health-data" className={Styles.skip}>
        {t("common.skip")}
        </Link>
      </div>
    </Container>
  );
};

export default UploadAvatar;
