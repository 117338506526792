import React from "react";
import { useAuthState } from "../../Context";
import Container from "../../Components/Container/Container";
import { Link, useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import Alert from "../../Components/Alert/Alert";
import ROOT_URL from "../../Config/rootUrl";
import Styles from "./HealthData.module.scss";
import { useTranslation } from "react-i18next";

const HealthData = () => {
  const userDetails = useAuthState();
  const [height, setHeight] = React.useState("");
  const [weight, setWeight] = React.useState("");
  const [dd, setDd] = React.useState("");
  const [mm, setMm] = React.useState("");
  const [yyyy, setYyyy] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [showAlert, setShowAlert] = React.useState(false);

  const { t } = useTranslation();

  const navigate = useNavigate();

  /************************************************
   *
   *      QUICK FIX => PLEASE REMOVE LATER !!!
   *
   *************************************************/
  React.useEffect(() => {
    if (document.body.classList.contains("menu-open")) {
      document.body.classList.remove("menu-open");
    }
  }, []);

  React.useEffect(() => {
    const token = userDetails?.token;
    if (!token) return;

    async function fetchData() {
      const response = await fetch(`${ROOT_URL}/api/v1/healthdata`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (!response.ok) {
        return;
      }

      if (data?.height) setHeight(data.height);
      if (data?.weight) setWeight(data.weight);
      if (data?.dob) {
        setDd(data?.dob.split("-")[2]);
        setMm(data?.dob.split("-")[1]);
        setYyyy(data?.dob.split("-")[0]);
      }
    }

    fetchData();
  }, [userDetails?.token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = userDetails?.token;
      if (!token) return;

      const dob = yyyy && mm && dd ? String(yyyy + "-" + mm + "-" + dd) : null;

      const payload = { height: +height, weight: +weight, birthdate: dob };

      const requestOptions = {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      };

      const response = await fetch(
        `${ROOT_URL}/api/v1/healthdata`,
        requestOptions
      );

      await response.json();

      if (!response.ok) {
        setErrorMessage(t("settings.avatarUpdateError"));
        setShowAlert(true);
        return;
      }

      navigate("/"); // navigate to home on success
    } catch (err) {
      setShowAlert(true);
      return;
    }
  };

  return (
    <Container fullHeight>
      <h1>{t("healthData.headline")}</h1>

      <form className={Styles.form} onSubmit={handleSubmit}>
        <CSSTransition
          in={showAlert}
          timeout={200}
          classNames="fade"
          unmountOnExit
        >
          <Alert
            message={errorMessage}
            color="danger"
            dismissible={true}
            onClick={setShowAlert}
          />
        </CSSTransition>

        <p className={Styles.formText}>{t("healthData.why")}</p>
        <label>
          {t("common.inputPlaceholders.height")}
          <input
            type="number"
            name="height"
            value={height}
            placeholder={t("common.inputPlaceholders.height")}
            onChange={(e) => setHeight(e.target.value)}
            autoComplete="off"
          />
        </label>
        <label>
          {t("common.inputPlaceholders.weight")}
          <input
            type="number"
            name="weight"
            value={weight}
            placeholder={t("common.inputPlaceholders.weight")}
            onChange={(e) => setWeight(e.target.value)}
            autoComplete="off"
          />
        </label>
        <label>
          {t("common.inputPlaceholders.dateOfBirth")}
          <div className={Styles.formRow}>
            <input
              type="text"
              inputMode="numeric"
              name="dd"
              placeholder={t("common.inputPlaceholders.day")}
              value={dd}
              onChange={(e) => setDd(e.target.value)}
              autoComplete="off"
              minLength="2"
              maxLength="2"
            />
            <input
              type="text"
              inputMode="numeric"
              name="mm"
              placeholder={t("common.inputPlaceholders.month")}
              value={mm}
              onChange={(e) => setMm(e.target.value)}
              autoComplete="off"
              minLength="2"
              maxLength="2"
            />
            <input
              type="text"
              inputMode="numeric"
              name="yyyy"
              placeholder={t("common.inputPlaceholders.year")}
              value={yyyy}
              onChange={(e) => setYyyy(e.target.value)}
              autoComplete="off"
              minLength="4"
              maxLength="4"
            />
          </div>
        </label>

        <input
          type="submit"
          disabled={!dd && !mm && !yyyy && !height && !weight}
          value={t("common.buttons.continue")}
        />
      </form>

      <div className={Styles.footer}>
        <Link to="/" className={Styles.skip}>
          {t("common.skip")}
        </Link>
      </div>
    </Container>
  );
};

export default HealthData;
