import React from "react";
import ROOT_URL from "../../Config/rootUrl";
// import moment from "moment";
import { Link } from "react-router-dom";
import { FaUserAlt } from "react-icons/fa";
import Styles from "./CRUDUser.module.scss";
import { useTranslation } from "react-i18next";

const CRUDUser = ({ user, changeRoleUserMutation, setModalDeleteUser, verifyUserMutation }) => {
  const { i18n } = useTranslation();
  
  const handleChange = (e) => {
    changeRoleUserMutation.mutate({ userId: user.id, role: e.target.value });
  };


  return (
    <div className={Styles.outerContainer}>
      <div className={Styles.innerContainer}>
        <div className={Styles.userEntry}>
          <div className={Styles.user}>
            <div className={Styles.circle}>
              {user.profileImage ? (
                <img
                  srcSet={`${ROOT_URL}/public/images/${user.profileImage}-40x40.jpg 40w, ${ROOT_URL}/public/images/${user.profileImage}-80x80.jpg 80w, ${ROOT_URL}/public/images/${user.profileImage}-120x120.jpg 120w, ${ROOT_URL}/public/images/${user.profileImage}-160x160.jpg 160w`}
                  sizes="40px"
                  src={`${ROOT_URL}/public/images/${user.profileImage}-40x40.jpg`}
                  alt={user.username}
                />
              ) : (
                <FaUserAlt className={Styles.placeholder} />
              )}
            </div>
            <div>
              <h3>{user.username}&nbsp;</h3>
              <p className={`${!user.verified ? Styles.notVerified : ""}`}>
                {user.email}
              </p>
              <p className={Styles.signedUp}>
                {/* Registered: {moment(user.createdAt).format("DD.MM.YYYY")} */}
                Registered:{" "}
                {new Intl.DateTimeFormat(i18n.resolvedLanguage === "en" ? "en-GB" : i18n.resolvedLanguage, {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                }).format(new Date(user.createdAt))}
              </p>
              <p className={Styles.signedUp}>ID: {user.id}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={Styles.actionContainer}>
        {!user.verified && (
          <button
            className={`${"small"} ${Styles.verify}`}
            onClick={() => verifyUserMutation.mutate(user.id)}
          >
            Verify
          </button>
        )}

        {!!user.verified && (
          <Link
            className={`${"button small"} ${Styles.button} ${Styles.edit}`}
            to={`/crud/users/${user.id}/workouts`}
          >
            Workouts
          </Link>
        )}

        {!!user.verified && (
          <Link
            className={`${"button small"} ${Styles.button} ${Styles.edit}`}
            to={`/crud/users/${user.id}/licensekeys`}
          >
            License keys
          </Link>
        )}

        <Link
          className={`${"button small"} ${Styles.button} ${Styles.edit}`}
          to={`/crud/users/${user.id}`}
        >
          Edit
        </Link>

        <select className={Styles.select} name="role" id="role" value={user.role} disabled={user.role === "admin"} onChange={handleChange}>
          <option value="admin" disabled>Admin</option>
          <option value="coach">Coach</option>
          <option value="trainee">Trainee</option>
          <option value="user">User</option>
        </select>

        <button
          className={`${"small"} ${Styles.delete}`}
          onClick={() =>
            setModalDeleteUser({ userId: user.id, userEmail: user.email })
          }
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default CRUDUser;
