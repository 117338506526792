const Dumbbell = () => {
  return (
    <svg
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.60935 0.620605C3.2773 0.620981 3.00819 0.890092 3.00781 1.22214V10.8467C3.00781 11.1789 3.27715 11.4482 3.60935 11.4482C3.94154 11.4482 4.21088 11.1789 4.21088 10.8467V1.22214C4.2105 0.890092 3.94139 0.620981 3.60935 0.620605Z"
        fill="white"
      />
      <path
        d="M4.81226 5.43286H12.6322V6.63593H4.81226V5.43286Z"
        fill="white"
      />
      <path
        d="M0.601533 5.43286C0.269336 5.43286 0 5.7022 0 6.03439C0 6.36659 0.269336 6.63593 0.601533 6.63593V5.43286Z"
        fill="white"
      />
      <path
        d="M16.8428 5.43286V6.63593C17.175 6.63593 17.4443 6.36659 17.4443 6.03439C17.4443 5.7022 17.175 5.43286 16.8428 5.43286Z"
        fill="white"
      />
      <path
        d="M16.2416 9.94441V2.12448C16.2416 1.79229 15.9723 1.52295 15.6401 1.52295C15.3079 1.52295 15.0386 1.79229 15.0386 2.12448V9.94441C15.0386 10.2766 15.3079 10.5459 15.6401 10.5459C15.9723 10.5459 16.2416 10.2766 16.2416 9.94441Z"
        fill="white"
      />
      <path
        d="M13.8352 0.620605C13.5031 0.620981 13.234 0.890092 13.2336 1.22214V10.8467C13.2336 11.1789 13.503 11.4482 13.8352 11.4482C14.1674 11.4482 14.4367 11.1789 14.4367 10.8467V1.22214C14.4363 0.890092 14.1672 0.620981 13.8352 0.620605Z"
        fill="white"
      />
      <path
        d="M2.40619 9.94441V2.12448C2.40619 1.79229 2.13685 1.52295 1.80466 1.52295C1.47246 1.52295 1.20312 1.79229 1.20312 2.12448V9.94441C1.20312 10.2766 1.47246 10.5459 1.80466 10.5459C2.13685 10.5459 2.40619 10.2766 2.40619 9.94441Z"
        fill="white"
      />
    </svg>
  );
};
export default Dumbbell;
