import jwt_decode from "jwt-decode";

let user = localStorage.getItem("cu")
  ? JSON.parse(localStorage.getItem("cu")).user
  : {};
let token = localStorage.getItem("cu")
  ? JSON.parse(localStorage.getItem("cu")).token
  : "";
let email = localStorage.getItem("em")
  ? JSON.parse(localStorage.getItem("em"))
  : null;

if (token !== "") {
  const decodedToken = jwt_decode(token);
  const currentDate = new Date();
  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    localStorage.removeItem("cu");
    token = "";
    user = {};
  }
}

export const initialState = {
  user: user || {},
  token: token || "",
  loading: false,
  errorMessage: null,
  successMessage: null,
  email: email || null,
};

export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case "AUTH_REQUEST":
      return {
        ...initialState,
        loading: true,
      };
    case "AUTH_SUCCESS":
      return {
        ...initialState,
        user: action.payload.user,
        token: action.payload.token,
        loading: false,
        errorMessage: null,
        successMessage: action.payload.success,
      };
    case "REGISTER_SUCCESS":
      return {
        ...initialState,
        loading: false,
        errorMessage: null,
        successMessage: action.payload.success.message,
        email: action.payload.success.email,
      };
      case "RESEND_SUCCESS":
      return {
        ...initialState,
        loading: false,
        // errorMessage: null,
        successMessage: action.payload.success.message,
      };
    case "AUTH_LOGOUT":
      return {
        ...initialState,
        user: {},
        token: "",
        loading: false,
        errorMessage: null,
        successMessage: null,
      };
    case "AUTH_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
