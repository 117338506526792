import React from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  verifyEmail,
  resendVerifyEmail,
  useAuthState,
  useAuthDispatch,
} from "../../Context";

import Logo from "../../Assets/Logo.js";
import Container from "../../Components/Container/Container";
import Styles from "./VerifyEmail.module.scss";

import { CSSTransition } from "react-transition-group";
import Alert from "../../Components/Alert/Alert";
import { useTranslation } from "react-i18next";

const VerifyEmail = () => {
  const [code, setCode] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = React.useState(false);

  const {t} = useTranslation();

  const dispatch = useAuthDispatch();
  const { user, email, loading, 
    // errorMessage 
  } = useAuthState(); // read the values of loading and errorMessage from context

  const navigate = useNavigate();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const codeFromQuery = query.get("code");

  React.useEffect(() => {
    if (codeFromQuery) {
      setCode(codeFromQuery);
    }
  }, [codeFromQuery]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = { code, email };
    try {
      const response = await verifyEmail(dispatch, payload); // verifyEmail action makes the request and handles all the neccessary state changes
      if (!response?.user) {
        setShowAlert(true);
        return;
      }
      navigate("/upload-avatar"); // navigate to Upload Avatar on success
    } catch (error) {
      console.log(error);
      setShowAlert(true);
    }
  };

  const resendVerificationEmail = async (e) => {
    e.preventDefault();
    const payload = { email };
    try {
      const response = await resendVerifyEmail(dispatch, payload); // resendVerifyEmail action makes the request and handles all the neccessary state changes

      if (response?.success) {
        setShowSuccessAlert(true);
      }
    } catch (error) {
      console.log(error);
      setShowAlert(true);
    }
  };

  // if (user?.email) return <Navigate to="/" />;

  if (!email && !user?.email) return <Navigate to="/login" />;

  return (
    <Container fullHeight>
      <h1>{t("verifyEmail.headline")}</h1>

      <form className={Styles.form} onSubmit={handleSubmit}>
        <CSSTransition
          in={showAlert}
          timeout={200}
          classNames="fade"
          unmountOnExit
        >
          <Alert
            message={t("verifyEmail.codeIncorrect")}
            color="danger"
            dismissible={true}
            onClick={setShowAlert}
          />
        </CSSTransition>

        <CSSTransition
          in={showSuccessAlert}
          timeout={200}
          classNames="fade"
          unmountOnExit
        >
          <Alert
            message={t("verifyEmail.newVerificationSent", {email})}
            color="success"
            dismissible={true}
            onClick={setShowSuccessAlert}
          />
        </CSSTransition>
        <input
          type="text"
          name="code"
          value={code}
          placeholder={t("verifyEmail.code")}
          onChange={(e) => setCode(e.target.value)}
          disabled={loading}
          autoComplete="on"
          minLength="4"
          maxLength="4"
          required
        />
        <input type="submit" disabled={loading} value={t("common.buttons.submitCode")} />
        <p onClick={resendVerificationEmail} className="link-style text-center">
         {t("verifyEmail.resendVerificationEmail")}
        </p>
      </form>

      <div className={Styles.footer}>
        <Logo />
      </div>
    </Container>
  );
};

export default VerifyEmail;
