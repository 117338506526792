import Container from "../Container/Container";
import Header from "./Header";

const Layout = ({ headline, HeadlineIcon, subheadline, fixedButtonAtBottom, children, headlinePositionAbsolute }) => {
  return (
    <>
      <Header
        headline={headline}
        HeadlineIcon={HeadlineIcon}
        subheadline={subheadline}
        headlinePositionAbsolute={headlinePositionAbsolute}
      />
      <Container fixedButtonAtBottom={fixedButtonAtBottom}>{children}</Container>
    </>
  );
};

export default Layout;
