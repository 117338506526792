import React, { useState, useEffect } from "react";
import { useAuthState, useAuthDispatch, logout } from "../../Context";
import { useQuery } from "react-query";

import Styles from "./Speedcage.module.scss";
import Layout from "../../Components/Layout/Layout";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";

import ROOT_URL from "../../Config/rootUrl";

const SpeedCage = () => {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();

  // Initial query to get all entries
  const { data, isLoading, isError } = useQuery(["speedcage"], async () => {
    const token = userDetails?.token;
    if (!token) return;
    const response = await fetch(`${ROOT_URL}/api/v1/speedcage`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const responseJson = await response.json();
    if (data?.error?.name === "TokenExpiredError") {
      logout(dispatch);
    }
    return responseJson;
  });

  // State to hold the selected ID, initialized as undefined
  const [selectedId, setSelectedId] = useState();

  // Set the first ID as the initial selected ID when data is loaded
  useEffect(() => {
    if (data?.entries?.length > 0) {
      setSelectedId(data.entries[0].id);
    }
  }, [data]);

  // Detail query that re-runs when selectedId changes
  const { data: detailedData, isLoading: isLoadingDetail } = useQuery(
    ["speedcageDetail", selectedId],
    async () => {
      if (!selectedId) return;
      const token = userDetails?.token;
      const response = await fetch(`${ROOT_URL}/api/v1/speedcage/${selectedId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.json();
    },
    { enabled: !!selectedId } // Only run this query if selectedId is available
  );

  if (isLoading) return <LoadingSpinner />;
  if (isError) return <div>Error loading data. Please try again.</div>;

  return (
    <Layout headline="speedCage">
      <div>Available SpeedCage IDs for this user:</div>

      {data?.entries?.length > 0 ? (
        <ul>
          {data.entries.map((entry) => (
            <li key={entry.id}>{entry.id}</li>
          ))}
        </ul>
      ) : (
        <div>No entries found.</div>
      )}

      {/* Input for selecting a new ID */}
      <div className={Styles.idInput}>
        <label>
          Enter ID to view details:
          <input
            type="number"
            value={selectedId || ""}
            onChange={(e) => setSelectedId(e.target.value)}
            placeholder="Enter ID"
          />
        </label>
      </div>

      {/* Display details of the selected ID */}
      {isLoadingDetail ? (
        <LoadingSpinner />
      ) : (
        detailedData && (
            <div className={Styles.details}>
            <h3>Details for ID {selectedId}:</h3>
            <pre style={{ fontSize: 9 }}>{JSON.stringify(detailedData, null, 4)}</pre>
          </div>
        )
      )}
    </Layout>
  );
};

export default SpeedCage;
