import ROOT_URL from "../Config/rootUrl";

export async function loginUser(dispatch, payload) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };

  try {
    dispatch({ type: "AUTH_REQUEST" });
    const response = await fetch(
      `${ROOT_URL}/api/v1/auth/sign_in`,
      requestOptions
    );

    const data = await response.json();

    if (data?.success) {
      dispatch({ type: "REGISTER_SUCCESS", payload: data });
      localStorage.setItem("em", JSON.stringify(data.success.email));

      return data;
    }

    if (data?.token) {
      dispatch({ type: "AUTH_SUCCESS", payload: data });
      localStorage.setItem("cu", JSON.stringify(data));

      return data;
    }

    dispatch({ type: "AUTH_ERROR", error: data.error.message });
    return;
  } catch (error) {
    dispatch({ type: "AUTH_ERROR", error: error.toString() });
  }
}

export async function logout(dispatch) {
  dispatch({ type: "AUTH_LOGOUT" });
  localStorage.removeItem("cu");
}

export async function registerUser(dispatch, payload) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };

  try {
    dispatch({ type: "AUTH_REQUEST" });
    const response = await fetch(
      `${ROOT_URL}/api/v1/auth/sign_up`,
      requestOptions
    );

    const data = await response.json();

    if (data?.success) {
      dispatch({ type: "REGISTER_SUCCESS", payload: data });
      localStorage.setItem("em", JSON.stringify(data.success.email));

      return data;
    }

    dispatch({ type: "AUTH_ERROR", error: data.error.message });
    return;
  } catch (error) {
    dispatch({ type: "AUTH_ERROR", error: error.toString() });
  }
}

export async function verifyEmail(dispatch, payload) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };

  try {
    dispatch({ type: "AUTH_REQUEST" });
    const response = await fetch(
      `${ROOT_URL}/api/v1/auth/verification`,
      requestOptions
    );
    const data = await response.json();

    if (data?.token) {
      data.success = { message: "Thank you for verifying your email" };
      dispatch({ type: "AUTH_SUCCESS", payload: data });
      localStorage.setItem("cu", JSON.stringify(data));
      localStorage.removeItem("em");

      return data;
    }

    dispatch({ type: "AUTH_ERROR", error: data.error.message });
    return;
  } catch (error) {
    dispatch({ type: "AUTH_ERROR", error: error.toString() });
  }
}

export async function resendVerifyEmail(dispatch, payload) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };

  try {
    dispatch({ type: "AUTH_REQUEST" });
    const response = await fetch(
      `${ROOT_URL}/api/v1/auth/resend_verification`,
      requestOptions
    );

    const data = await response.json();

    if (data?.success) {
      dispatch({ type: "RESEND_SUCCESS", payload: data });

      return data;
    }

    dispatch({ type: "AUTH_ERROR", error: data.error.message });
    return;
  } catch (error) {
    dispatch({ type: "AUTH_ERROR", error: error.toString() });
  }
}

export async function sendPasswordReset(dispatch, payload) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };

  try {
    dispatch({ type: "AUTH_REQUEST" });
    const response = await fetch(
      `${ROOT_URL}/api/v1/auth/send_password_reset`,
      requestOptions
    );

    const data = await response.json();

    if (data?.success) {
      data.success = {
        message:
          "Please check your email and follow the instructions to change your password",
      };
      dispatch({ type: "RESEND_SUCCESS", payload: data });

      return data;
    }

    dispatch({ type: "AUTH_ERROR", error: data.error.message });
    return;
  } catch (error) {
    dispatch({ type: "AUTH_ERROR", error: error.toString() });
  }
}

export async function passwordReset(dispatch, payload) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };

  try {
    dispatch({ type: "AUTH_REQUEST" });
    const response = await fetch(
      `${ROOT_URL}/api/v1/auth/password_reset`,
      requestOptions
    );

    const data = await response.json();

    if (data?.success) {
      dispatch({ type: "REGISTER_SUCCESS", payload: data });
      localStorage.setItem("em", JSON.stringify(data.success.email));

      return data;
    }

    if (data?.token) {
      data.success = { message: "Your password was successfully changed" };
      dispatch({ type: "AUTH_SUCCESS", payload: data });
      localStorage.setItem("cu", JSON.stringify(data));

      return data;
    }

    dispatch({ type: "AUTH_ERROR", error: data.error.message });
    return;
  } catch (error) {
    dispatch({ type: "AUTH_ERROR", error: error.toString() });
  }
}

export function replaceUserAndToken(dispatch, updatedUser) {
  try {
    dispatch({ type: "AUTH_SUCCESS", payload: updatedUser });

    localStorage.setItem("cu", JSON.stringify(updatedUser));

    return updatedUser;
  } catch (error) {
    dispatch({ type: "AUTH_ERROR", error: error.toString() });
  }
}
