import React from "react";
import { useAuthState, useAuthDispatch, logout } from "../../Context";
import { useInfiniteQuery, useMutation } from "react-query";
import InfiniteScroll from "react-infinite-scroll-component";
import Layout from "../../Components/Layout/Layout";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import ROOT_URL from "../../Config/rootUrl";
import Styles from "./CRUDUsers.module.scss";
import CRUDUserList from "../../Components/CRUDUserList/CRUDUserList";
import Search from "../../Assets/Search";
import { BsTrash } from "react-icons/bs";
import { Link } from "react-router-dom";

const CRUDUsers = () => {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();
  const [loaded, setLoaded] = React.useState(false);
  const [userData, setUserData] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [onlyNotVerified, setOnlyNotVerified] = React.useState(0);
  const [modalDeleteUser, setModalDeleteUser] = React.useState(null);

  const { data, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery(
    "getUsers",
    async ({ pageParam = 1 }) => {
      const token = userDetails?.token;
      if (!token) return;
      const response = await fetch(
        `${ROOT_URL}/api/v1/crud/users/?searchterm=${searchTerm}&onlynotverified=${onlyNotVerified}&page=${pageParam}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseJson = await response.json();
      if (data?.error?.name === "TokenExpiredError") {
        logout(dispatch);
      }
      return responseJson;
    },
    {
      getNextPageParam: (page) =>
        page.currentPage === page.totalPages ? undefined : page.currentPage + 1,
    }
  );

  React.useEffect(() => {
    try {
      const fetchedUserData = data?.pages
        ?.map((entries) => entries.entries)
        .flat();

      setLoaded(true);
      setUserData(fetchedUserData);
    } catch (err) {
      console.log(err);
      return;
    }
  }, [data]);

  const dataLength = data?.pages?.reduce(
    (counter, page) => counter + page.entries?.length,
    0
  );

  const handleChange = (event) => {
    if (event.target.name === "verified") {
      setOnlyNotVerified(event.target.checked ? 1 : 0);
    } else {
      setSearchTerm(event.target.value);
    }
  };

  React.useEffect(() => {
    refetch();
  }, [onlyNotVerified, refetch]);

  const handleSubmit = (event) => {
    event.preventDefault();
    refetch();
  };

  const deleteUserMutation = useMutation(
    async (userId) =>
      await fetch(`${ROOT_URL}/api/v1/crud/users/${userId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${userDetails?.token}`,
        },
      }),
    {
      // After success or failure, refetch the query and set Modal to null
      onSettled: () => {
        setModalDeleteUser(null);
        refetch();
      },
    }
  );

  const verifyUserMutation = useMutation(
    async (userId) =>
      await fetch(`${ROOT_URL}/api/v1/crud/users/${userId}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${userDetails?.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ verified: true, verificationCode: null }),
      }),
    {
      // After success or failure, refetch the query
      onSettled: () => {
        refetch();
      },
    }
  );

  const changeRoleUserMutation = useMutation(
    async ({ userId, role }) => {
      await fetch(`${ROOT_URL}/api/v1/crud/users/${userId}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${userDetails?.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ role: role }),
      });
    },
    {
      // After success or failure, refetch the query
      onSettled: () => {
        refetch();
      },
    }
  );

  if (!data || dataLength === undefined || !loaded)
    return (
      <Layout headline="CRUD USERS">
        <LoadingSpinner />
      </Layout>
    );

  return (
    <Layout headline="CRUD USERS">
      <div className={Styles.createButtonContainer}>
        <Link
          to="create"
          className={`${"button small"} ${Styles.createButton}`}
        >
          Create new user
        </Link>
      </div>

      <form onSubmit={handleSubmit} className={Styles.searchForm}>
        <div className={Styles.formRow}>
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={handleChange}
          />
          <button type="submit" className={Styles.searchButton}>
            <Search />
          </button>
        </div>
        <div className={Styles.formRow}>
          <input
            type="checkbox"
            id="verified"
            name="verified"
            checked={onlyNotVerified}
            onChange={handleChange}
          />
          <label
            htmlFor="verified"
            className={`${"button"} ${"small"} ${Styles.verifiedCheckbox}`}
          >
            Only email not verified
          </label>
        </div>
      </form>

      {!!userData?.length > 0 && (
        <InfiniteScroll
          dataLength={dataLength}
          next={fetchNextPage}
          hasMore={hasNextPage}
          loader={dataLength > 0 && <LoadingSpinner positionRelative={true} />}
          className={Styles.container}
        >
          {data.pages.map((group, i, array) => (
            <React.Fragment key={i}></React.Fragment>
          ))}
          <CRUDUserList
            userData={userData}
            setModalDeleteUser={setModalDeleteUser}
            verifyUserMutation={verifyUserMutation}
            changeRoleUserMutation={changeRoleUserMutation}
          />
        </InfiniteScroll>
      )}

      {modalDeleteUser && (
        <div className={Styles.overlay}>
          <div className={Styles.circle}>
            <BsTrash className={Styles.placeholder} />
          </div>
          <h2>You are about to delete a user</h2>
          <p>The following user will be permanently removed:</p>
          <p>
            <span className={Styles.email}>{modalDeleteUser.userEmail}</span>
          </p>
          <div className={Styles.buttonGroup}>
            <button onClick={() => setModalDeleteUser(null)}>Cancel</button>
            <button
              className={Styles.deleteButton}
              onClick={() => deleteUserMutation.mutate(modalDeleteUser.userId)}
            >
              Delete
            </button>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default CRUDUsers;
