import React from "react";
import { Link } from "react-router-dom";
import Container from "../../Components/Container/Container";
import Styles from "./EULA.module.scss";
import Markdown from "markdown-to-jsx";
import { useTranslation } from "react-i18next";

const EULA = () => {
  const { t } = useTranslation();
  const fileName = t("common.eulaFileName");
  const [data, setData] = React.useState();

  /************************************************
   *
   *      QUICK FIX => PLEASE REMOVE LATER !!!
   *
   *************************************************/
  React.useEffect(() => {
    if (document.body.classList.contains("menu-open")) {
      document.body.classList.remove("menu-open");
    }
  }, []);

  React.useEffect(() => {
    import(`../../translations/${fileName}`)
      .then((res) => {
        fetch(res.default)
          .then((res) => res.text())
          .then((res) => setData(res));
      })
      .catch((err) => console.log(err));
  }, [fileName]);

  return (
    <Container fullHeight>
      <div className={Styles.markdown}>
        {!!data && <Markdown>{data}</Markdown>}
      </div>

      <Link className={Styles.button} to="/register">
        {t("common.back")}
      </Link>
    </Container>
  );
};

export default EULA;
