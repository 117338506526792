import React from "react";
import Styles from "./GlobalProgress.module.scss";
import { CountUp } from "use-count-up";
import { useTranslation } from "react-i18next";

const GlobalProgress = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className={Styles.container}>
      <div className={Styles.progressContainer}>
        {!!data?.level && (
          <div className={Styles.levelCircle}>
            <h3>{data.level}</h3>
          </div>
        )}

        <div className={Styles.progressBarContainer}>
          <div className={Styles.progressBar} />
          <div
            className={Styles.actualProgress}
            style={{ width: `${data.currentProgress * 100}%` }}
          />
        </div>
      </div>

      <p>
        <CountUp
          isCounting
          end={data.totalExp - data.previousLevelExp}
          duration={0}
          decimalPlaces={0}
          decimalSeparator={t("common.decimalSeparator")}
          thousandsSeparator={t("common.thousandsSeparator")}
        />
        /
        <CountUp
          isCounting
          end={data.nextLevelExp - data.previousLevelExp}
          duration={0}
          decimalPlaces={0}
          decimalSeparator={t("common.decimalSeparator")}
          thousandsSeparator={t("common.thousandsSeparator")}
        />{" "}
        Fitness Points
      </p>
    </div>
  );
};

export default GlobalProgress;
