import React, { useState } from "react";
import {
  useAuthState,
  useAuthDispatch,
  replaceUserAndToken,
} from "../../Context";
import { useQueryClient } from "react-query";
import { QrReader } from "react-qr-reader";
import { useNavigate } from "react-router-dom";
import { BsFillCheckCircleFill } from "react-icons/bs";

import ROOT_URL from "../../Config/rootUrl";
import Layout from "../../Components/Layout/Layout";
import ViewFinder from "../../Components/ViewFinder/ViewFinder";

const QrScanner = () => {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [scanning, setScanning] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleRequest = async (qrData) => {
    const token = userDetails?.token;
    if (!token) return;

    // try to check if user is scanning non-sphery qr codes
    if (
      qrData.includes("http://") ||
      qrData.includes("https://") ||
      qrData.includes("www.")
    ) {
      setErrorMessage("Invalid QR code.");
      return;
    }

    // check if user is trying to scan qr for upgrade his user account to trainee
    const searchString = "u2t?lId=";

    if (qrData.includes(searchString)) {
      // user seems to be scanning a qr code for upgrading account to trainee
      const locationId = qrData.split(searchString)[1];

      if (!locationId) {
        setErrorMessage("Invalid location ID in QR code.");
        return;
      }

      const payload = { locationId };

      try {
        const response = await fetch(
          `${ROOT_URL}/api/v1/auth/user_to_trainee`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error.message || "Request failed");
        }

        if (data?.token) {
          // After successful response, replace user and token
          replaceUserAndToken(dispatch, {
            token: data.token,
            user: {
              id: data.user.id,
              email: data.user.email,
              username: data.user.username,
              role: data.user.role,
              profileImage: data?.user?.profileImage || null,
            },
          });
        }

        setErrorMessage(null);
        setScanning(false);

        queryClient.invalidateQueries(["getOverlay"]); // make sure, we get overlays refreshed
        navigate("/ausbildung"); // Navigate to new trainee page on success
      } catch (error) {
        console.error(error);
        setErrorMessage(error.message);
      }
    } else {
      // user seems to be scanning qr code for login to exercube
      const payload = { exercubeIdentifier: qrData };

      try {
        const response = await fetch(`${ROOT_URL}/api/v1/auth/qr_app`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error.message || "Request failed");
        }

        setErrorMessage(null);
        setScanning(false);
        setTimeout(() => {
          navigate("/"); // Navigate to Home on success
        }, 2000);
      } catch (error) {
        console.error(error);
        setErrorMessage(error.message);
      }
    }
  };

  return (
    <Layout>
      {!scanning && (
        <div
          style={{
            margin: "auto",
            width: "400px",
            maxWidth: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "#00ff00",
            minHeight: "400px",
          }}
        >
          <BsFillCheckCircleFill style={{ fontSize: 64, marginBottom: 16 }} />
          <p>Returning to exerhub...</p>
        </div>
      )}

      {!!scanning && (
        <div
          style={{
            margin: "auto",
            width: "400px",
            maxWidth: "100%",
          }}
        >
          <QrReader
            constraints={{ facingMode: "environment" }}
            ViewFinder={ViewFinder}
            onResult={(result, error) => {
              if (!!result) {
                handleRequest(result?.text);
              }

              if (!!error) {
                console.info(error);
              }
            }}
            style={{ width: "100%" }}
          />

          {errorMessage ? (
            <div style={{ color: "#eb3547", marginTop: 16 }}>
              {errorMessage}
            </div>
          ) : null}

          <div style={{ marginTop: 16 }}>
            <p>Scan the login QR code on the exercube terminal</p>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default QrScanner;
