import React from "react";
import User from "../User/User";
import Styles from "./UserList.module.scss";

const UserList = ({
  userData,
  select,
  selectedUsers,
  setSelectedUsers,
}) => {
  return (
    <div className={Styles.marginBottom}>
      {userData &&
        userData.map((user) => (
          <div key={user.id}>
            <User user={user} select={select} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} />
          </div>
        ))}
    </div>
  );
};

export default UserList;
