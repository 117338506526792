import React from "react";
import { useAuthState, useAuthDispatch, logout } from "../../Context";
import { useInfiniteQuery } from "react-query";
import InfiniteScroll from "react-infinite-scroll-component";
import Layout from "../../Components/Layout/Layout";
import WorkoutList from "../../Components/WorkoutList/WorkoutList";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import FixedButton from "../../Components/FixedButton/FixedButton";
import Challenge from "../../Assets/Challenge.js";
import ROOT_URL from "../../Config/rootUrl";
import Styles from "./ChallengesPastWorkouts.module.scss";
import { useTranslation } from "react-i18next";

const ChallengesPastWorkouts = () => {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();
  const [loaded, setLoaded] = React.useState(false);
  const [workoutData, setWorkoutData] = React.useState();
  const { t } = useTranslation();

  const [selectedWorkout, setSelectedWorkout] = React.useState();

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    "pastWorkouts",
    async ({ pageParam = 1 }) => {
      const token = userDetails?.token;
      if (!token) return;
      const response = await fetch(
        `${ROOT_URL}/api/v1/workout/challengeable/?page=${pageParam}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseJson = await response.json();
      if (data?.error?.name === "TokenExpiredError") {
        logout(dispatch);
      }
      return responseJson;
    },
    {
      getNextPageParam: (page) =>
        page.currentPage === page.totalPages ? undefined : page.currentPage + 1,
    }
  );

  React.useEffect(() => {
    try {
      const fetchedWorkoutData = data?.pages
        ?.map((entries) => entries.entries)
        .flat();

      // this gives an object with dates as keys
      const groups = fetchedWorkoutData?.reduce((groups, workout) => {
        const updatedAt = workout.updatedAt.split("T")[0];
        if (!groups[updatedAt]) {
          groups[updatedAt] = [];
        }
        groups[updatedAt].push(workout);
        return groups;
      }, {});

      // add it in the array format instead
      const groupArrays =
        groups &&
        Object.keys(groups).map((updatedAt) => {
          return {
            updatedAt,
            workouts: groups[updatedAt],
          };
        });

      setLoaded(true);
      setWorkoutData(groupArrays);
    } catch (err) {
      console.log(err);
      return;
    }
  }, [data]);

  const dataLength = data?.pages?.reduce(
    (counter, page) => counter + page.entries?.length,
    0
  );

  if (!data || dataLength === undefined || !loaded)
    return (
      <Layout headline={t("challenges.headline")}>
        <LoadingSpinner />
      </Layout>
    );

  return (
    <Layout headline={t("challenges.headline")} fixedButtonAtBottom={true}>
      <div className={Styles.challengeSelector}>
        <p className={Styles.subtitle}>{t("challenges.pastWorkouts")}</p>
      </div>

      {workoutData && workoutData.length > 0 && (
        <InfiniteScroll
          dataLength={dataLength}
          next={fetchNextPage}
          hasMore={hasNextPage}
          loader={dataLength > 0 && <LoadingSpinner positionRelative={true} />}
          className={Styles.container}
        >
          {data.pages.map((group, i, array) => (
            <React.Fragment key={i}></React.Fragment>
          ))}
          <WorkoutList
            workoutData={workoutData}
            select={true}
            setSelectedWorkout={setSelectedWorkout}
            selectedWorkout={selectedWorkout}
          />
        </InfiniteScroll>
      )}

      <FixedButton
        to="/challenges-choose-opponents"
        state={{ workoutId: selectedWorkout }}
        text={t("common.buttons.sendChallenge")}
        Icon={Challenge}
        disabled={!selectedWorkout}
      />
    </Layout>
  );
};

export default ChallengesPastWorkouts;
