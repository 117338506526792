import React from "react";
import { useAuthState, useAuthDispatch, logout } from "../../Context";
import Layout from "../../Components/Layout/Layout";
import ROOT_URL from "../../Config/rootUrl";
import Styles from "./CRUDUsersCreate.module.scss";
import { Link } from "react-router-dom";

const CRUDUsersCreate = () => {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();

  const [username, setUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = userDetails?.token;
      if (!token) return;

      const payload = { email, password, username };

      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      };

      const response = await fetch(
        `${ROOT_URL}/api/v1/crud/users`,
        requestOptions
      );

      const data = await response.json();

      if (!response.ok) {
        setSuccessMessage("");
        setErrorMessage(data?.error?.message);
        return;
      }

      if (data?.error?.name === "TokenExpiredError") {
        logout(dispatch);
      }

      setErrorMessage("");
      setSuccessMessage(
        data?.success?.message + " for " + data?.success?.email
      );
      setUsername("");
      setEmail("");
      setPassword("");
    } catch (err) {
      console.error(err);
      return;
    }
  };

  return (
    <Layout headline="CRUD Users Create">
      <div className={Styles.container}>
        <form className={Styles.form} onSubmit={handleSubmit}>
          <div>
            <h3>Create User</h3>
            <p>
              Users created with this form are already verified and will be
              notified of their new account via email.
            </p>
          </div>

          <input
            type="text"
            name="username"
            value={username}
            placeholder="Username"
            onChange={(e) => setUsername(e.target.value)}
            autoComplete="off"
            required
          />
          <input
            type="email"
            name="email"
            value={email}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="off"
            required
          />
          <input
            type="password"
            name="password"
            value={password}
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="off"
            minLength="4"
            required
            pattern="[a-zA-Z0-9!@#$%^*_|]{4,100}"
          />

          <p className={Styles.warning}>
            Minimum 4 characters. Currently only numbers, letters and @ , . - _
            + allowed
          </p>

          <input type="submit" value="Create User" />

          {errorMessage && <p className={Styles.error}>{errorMessage}!</p>}
          {successMessage && (
            <p className={Styles.success}>{successMessage}!</p>
          )}
        </form>

        <div style={{ textAlign: "center", marginBottom: 64 }}>
          <Link to="../crud/users">&lt; Go back to overview</Link>
        </div>
      </div>
    </Layout>
  );
};

export default CRUDUsersCreate;
