export const Seconds2MinutesAndSeconds = (timeInSeconds) => {
  const pad = (num, size) => ("000" + num).slice(size * -1),
    time = parseFloat(timeInSeconds).toFixed(3),
    minutes = Math.floor(time / 60) % 60,
    seconds = Math.round(time - minutes * 60);

  return (
    pad(minutes, 2) +
    ":" +
    pad(seconds, 2)
  );
};
