import React from "react";
import { useAuthState, useAuthDispatch, logout } from "../../Context";

import InfiniteScroll from "react-infinite-scroll-component";
import { useQuery, useInfiniteQuery } from "react-query";
import { Link, useSearchParams, useNavigate } from "react-router-dom";

import Styles from "./LeaderboardAttackOnTheCore.module.scss";

import Layout from "../../Components/Layout/Layout";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import DualflowScoreStat from "../../Components/WorkoutStat/DualflowScoreStat";
import ScoreStat from "../../Components/WorkoutStat/ScoreStat";

import { FaUserAlt } from "react-icons/fa";

import ROOT_URL from "../../Config/rootUrl";
import { useTranslation } from "react-i18next";

const LeaderboardsAttackOnTheScore = () => {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [storyPhase, setStoryPhase] = React.useState();
  const [workoutDuration, setWorkoutDuration] = React.useState();

  const {t} = useTranslation();

  const [dataLength, setDataLength] = React.useState();
  const [highscoreData, setHighscoreData] = React.useState();


  React.useEffect(() => {
    setStoryPhase(+searchParams.get("phase"));
    setWorkoutDuration(+searchParams.get("duration"));
  }, [searchParams, setSearchParams])
  



  const basicData = useQuery(["getAttackOnTheCoreHighscoresBasicData"], async () => {
    const response = await fetch(`${ROOT_URL}/api/v1/highscores-attack-on-the-core`, {
      headers: {
        Authorization: `Bearer ${userDetails?.token}`,
      },
    });

    const responseJson = await response.json();
    if (responseJson?.error?.name === "TokenExpiredError") {
      logout(dispatch);
    }
    return responseJson;
  });


  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ["getAttackOnTheCoreHighscores", { phase: +storyPhase, duration: +workoutDuration }],
    async ({ pageParam = 1 }) => {
      const token = userDetails?.token;
      if (!token) return;
      if (!workoutDuration || !storyPhase ) return;
      const response = await fetch(
        `${ROOT_URL}/api/v1/highscores-attack-on-the-core/actual-data?storyphase=${+storyPhase}&duration=${+workoutDuration}&size=100&page=${pageParam}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseJson = await response.json();
      if (data?.error?.name === "TokenExpiredError") {
        logout(dispatch);
      }
      return responseJson;
    },
    {
      getNextPageParam: (page) =>
      (!!page) ? (page.currentPage === page.totalPages ? undefined : page.currentPage + 1) : null,
    }
  );



  React.useEffect(() => {
    if(!workoutDuration || !storyPhase) return;
    try {
      setDataLength(
        data?.pages?.reduce(
          (counter, page) => counter + page.highscores?.length,
          0
        )
      );
      const fetchedHighscoreData = data?.pages
        ?.map((entries) => entries.highscores)
        .flat();

      setHighscoreData(fetchedHighscoreData);
    } catch (err) {
      console.log(err);
      return;
    }
  }, [data, workoutDuration, storyPhase]);




  React.useEffect(() => {
    if (basicData?.data?.durationsArray?.length && !searchParams.get("phase") && !searchParams.get("duration")) {
      // console.log(searchParams.get("duration"));
      setStoryPhase(1);
      setWorkoutDuration(basicData.data.durationsArray[0]);
    }
  }, [basicData, storyPhase, searchParams]);


  const changeStoryPhaseAndWorkoutDuration = (e) => {
    // setStoryPhase(e.target.value)
    setSearchParams(`phase=${+e.target.value}&duration=${basicData.data.durationsArray[+e.target.value - 1]}`);
  }

  
  


  return (
    <Layout headline={t("leaderboards.headline")}>
      <div className={Styles.workoutSelector}>
        <div className={Styles.selectType}>
          <div
            className={Styles.selectLeft}
            // onClick={() => navigate(-1)}
            onClick={() => navigate("/leaderboards?workout=5&duration=600&league_qualification=1")}
            // onClick={()=> (window.history.state && window.history.state.idx > 0) ? navigate(-1) : navigate("/leaderboards")}
            // onClick={(reverse) => changeWorkoutPresetId(reverse)}
          />

          <h2 className={Styles.workoutType}>Attack on the core</h2>

          <div
            className={Styles.selectRight}
            onClick={() => navigate("/leaderboards")}
          />
        </div>

        {!!basicData?.data?.durationsArray?.length ? (
          <select
            className={Styles.workoutTime}
            value={storyPhase}
            onChange={changeStoryPhaseAndWorkoutDuration}
            disabled={basicData?.data?.durationsArray?.length < 1}
          >
            {basicData.data.durationsArray.map((duration, i) => (
              <option key={i + duration} value={i + 1}>
                Phase {i + 1}
              </option>
            ))}
          </select>
        ) : null}
      </div>

      {/* {!!data && (
        <pre style={{ fontSize: 8 }}>{JSON.stringify(data, null, 4)}</pre>
      )} */}

      {!!basicData?.data?.error?.message && (
        <p style={{textAlign: "center"}}>{basicData?.data?.error?.message}</p>
      )}

      {/* <h1>storyPhase: {storyPhase}</h1>
      <h1>workoutDuration: {workoutDuration}</h1>

      {!!basicData?.data && (
        <pre style={{ fontSize: 8 }}>{JSON.stringify(basicData.data, null, 4)}</pre>
      )}

{!!data && (
        <pre style={{ fontSize: 8 }}>{JSON.stringify(data, null, 4)}</pre>
      )} */}

      {!data || dataLength === undefined ? (
        !basicData?.data?.error ? (
          <LoadingSpinner />
        ) : null
      ) : (
        <InfiniteScroll
          dataLength={dataLength}
          next={fetchNextPage}
          hasMore={hasNextPage}
          loader={dataLength > 0 && <LoadingSpinner positionRelative={true} />}
        >
          {data.pages.map((group, i, array) => (
            <React.Fragment key={i}></React.Fragment>
          ))}
          {highscoreData &&
            highscoreData.map((highscore, i) => (
              <Link
                to={`/workout/${highscore.id}`}
                className={`${Styles.highscore} ${storyPhase >= 6 ? Styles.disabled : ""}`}
                key={highscore.dualflowScore + highscore.score + i}
              >
                <div className={Styles.user}>
                  <div className={Styles.circle}>
                    {highscore.profileImage ? (
                      <img
                        srcSet={`${ROOT_URL}/public/images/${highscore.profileImage}-40x40.jpg 40w, ${ROOT_URL}/public/images/${highscore.profileImage}-80x80.jpg 80w, ${ROOT_URL}/public/images/${highscore.profileImage}-120x120.jpg 120w, ${ROOT_URL}/public/images/${highscore.profileImage}-160x160.jpg 160w`}
                        sizes="40px"
                        src={`${ROOT_URL}/public/images/${highscore.profileImage}-40x40.jpg`}
                        alt={highscore.username}
                      />
                    ) : (
                      <FaUserAlt className={Styles.placeholder} />
                    )}
                  </div>
                  <div className={Styles.userNameContainer}>
                    <h3>{highscore.username}&nbsp;</h3>
                  </div>
                  <h3 className={Styles.position}>{++i}.</h3>
                </div>
                <div className={Styles.statsColumn}>
                  <DualflowScoreStat
                    value={highscore.dualflowScore}
                    horizontal={true}
                  />
                  <ScoreStat value={highscore.score} horizontal={true} />
                </div>
              </Link>
            ))}
        </InfiniteScroll>
      )}
    </Layout>
  );
};

export default LeaderboardsAttackOnTheScore;
