import React from "react";
import Layout from "../../Components/Layout/Layout";
// import Legend from "../../Components/MultilineChart/Legend";
import ProgressChart from "../../Components/ProgressChart/ProgressChart";
import { useAuthState, useAuthDispatch, logout } from "../../Context";
import { useQuery } from "react-query";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";

import ROOT_URL from "../../Config/rootUrl";

import Styles from "./Progress.module.scss";
import { useTranslation } from "react-i18next";

// import schc from "./SCHC.json";
// import vcit from "./VCIT.json";
// import portfolio from "./PORTFOLIO.json";

// const portfolioData = {
//   name: "Portfolio",
//   color: "#ffffff",
//   items: portfolio.map((d) => ({ ...d, date: new Date(d.date) })),
// };
// const schcData = {
//   name: "SCHC",
//   color: "#d53e4f",
//   items: schc.map((d) => ({ ...d, date: new Date(d.date) })),
// };
// const vcitData = {
//   name: "VCIT",
//   color: "#5e4fa2",
//   items: vcit.map((d) => ({ ...d, date: new Date(d.date) })),
// };

const Progress = () => {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();

  const { t } = useTranslation();

  // const [selectedItems, setSelectedItems] = React.useState([]);
  // const legendData = [portfolioData, schcData, vcitData];
  // const chartData = [
  //   portfolioData,
  //   ...[schcData, vcitData].filter((d) => selectedItems.includes(d.name)),
  // ];
  // const onChangeSelection = (name) => {
  //   const newSelectedItems = selectedItems.includes(name)
  //     ? selectedItems.filter((item) => item !== name)
  //     : [...selectedItems, name];
  //   setSelectedItems(newSelectedItems);
  // };

  const [selectedProgress, setSelectedProgress] = React.useState("Flow Score");
  const [selectedTimeFrame, setSelectedTimeFrame] = React.useState("weekly");

  const changeSelectedProgress = (e) => {
    setSelectedProgress(e.target.name);
  };

  const { data } = useQuery(["progress", selectedTimeFrame], async () => {
    const token = userDetails?.token;
    if (!token) return;
    const response = await fetch(
      `${ROOT_URL}/api/v1/progress/${selectedTimeFrame}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseJson = await response.json();
    if (data?.error?.name === "TokenExpiredError") {
      logout(dispatch);
    }
    return responseJson;
  });

  if (!data) return <LoadingSpinner />;

  // const chartDataNewFake = [
  //   {
  //     name: "Flow Score",
  //     color: "#E2BE00",
  //     items: [
  //       {
  //         date: "2022-01-17T00:00:00.000Z",
  //         value: 0.69,
  //       },
  //       {
  //         date: "2022-01-18T00:00:00.000Z",
  //         value: 0.9,
  //       },
  //       {
  //         date: "2022-01-19T00:00:00.000Z",
  //         value: 0.8369,
  //       },
  //       {
  //         date: "2022-01-20T00:00:00.000Z",
  //         value: 0.367,
  //       },
  //       {
  //         date: "2022-01-21T00:00:00.000Z",
  //         value: 0.8369,
  //       },
  //       {
  //         date: "2022-01-23T00:00:00.000Z",
  //         value: 0.69,
  //       },
  //     ],
  //   },
  //   {
  //     name: "Score Flow",
  //     color: "#d53e4f",
  //     items: [
  //       {
  //         date: "2022-01-17T00:00:00.000Z",
  //         value: 0.4369,
  //       },
  //       {
  //         date: "2022-01-18T00:00:00.000Z",
  //         value: 0.6369,
  //       },
  //       {
  //         date: "2022-01-19T00:00:00.000Z",
  //         value: 0.28369,
  //       },
  //       {
  //         date: "2022-01-20T00:00:00.000Z",
  //         value: 0.4367,
  //       },
  //       {
  //         date: "2022-01-21T00:00:00.000Z",
  //         value: 0.18369,
  //       },
  //       {
  //         date: "2022-01-23T00:00:00.000Z",
  //         value: 0.369,
  //       },
  //     ],
  //   },
  // ];

  // const chartDataNewFakeDate = chartDataNewFake.map((wtf) => ({
  //   ...wtf,
  //   items: wtf.items.map((d) => ({ ...d, date: new Date(d.date).setHours(0) })),
  // }));

  // const chartDataNewFakeDate = [...chartDataNewFake, chartDataNewFake.map((wtf) =>
  //   wtf.items.map((d) => ({ ...d, date: new Date(d.date).setHours(0) }))
  // )];

  // const chartDataNewFakeDate = chartDataNewFake.map((wtf) =>
  //   wtf.items.map((d) => ({ ...d, date: new Date(d.date).setHours(0) }))
  // );

  // const chartDataNewFakeDate = chartDataNewFake;

  // chartDataNewFakeDate[0].items = chartDataNewFakeItemsWithDate

  // const test = [
  //   { value: 0.64894, date: "MONDAY" },
  //   { value: 0.4894, date: "TUESDAY" },
  //   { value: 0.894, date: "WEDNESDAY" },
  //   { value: 0.64894, date: "THURSDAY" },
  //   { value: 0.4894, date: "FRIDAY" },
  //   { value: 0.894, date: "SATURDAY" },
  //   { value: 0.894, date: "SUNDAY" },
  // ];

  // const testYearly = [
  //   { value: 0.64894, date: "JANUARY" },
  //   { value: 0.4894, date: "FEBRUARY" },
  //   { value: 0.894, date: "MARCH" },
  //   { value: 0.64894, date: "APRIL" },
  //   { value: 0.64894, date: "MAI" },
  //   { value: 0.4894, date: "JUNE" },
  //   { value: 0.894, date: "JULY" },
  //   { value: 0.894, date: "AUGUST" },
  //   { value: 0.894, date: "SEPTEMBER" },
  //   { value: 0.894, date: "OCTOBER" },
  //   { value: 0.894, date: "NOVEMBER" },
  //   { value: 0.894, date: "DECEMBER" },
  // ];

  const days = [
    t("common.weekdays.sunday"),
    t("common.weekdays.monday"),
    t("common.weekdays.tuesday"),
    t("common.weekdays.wednesday"),
    t("common.weekdays.thursday"),
    t("common.weekdays.friday"),
    t("common.weekdays.saturday"),
  ];
  const months = [
    t("common.months.january"),
    t("common.months.february"),
    t("common.months.march"),
    t("common.months.april"),
    t("common.months.may"),
    t("common.months.june"),
    t("common.months.july"),
    t("common.months.august"),
    t("common.months.september"),
    t("common.months.october"),
    t("common.months.november"),
    t("common.months.december"),
  ];

  const chartDataNew = [
    {
      name: "Flow Score",
      color: "#E2BE00",
      items: data.map((d) => ({
        date:
          selectedTimeFrame === "weekly"
            ? days[new Date(d.date).getDay()]
            : months[new Date(d.date).getMonth()],
        value: +d.avg_dualflowScore,
      })),
    },
    {
      name: "Average HR",
      color: "#E2BE00",
      items: data.map((d) => ({
        date:
          selectedTimeFrame === "weekly"
            ? days[new Date(d.date).getDay()]
            : months[new Date(d.date).getMonth()],
        value: !!d.avg_hrAverage ? +d.avg_hrAverage : null,
      })),
    },
    {
      name: "Max HR",
      color: "#00B9E2",
      items: data.map((d) => ({
        date:
          selectedTimeFrame === "weekly"
            ? days[new Date(d.date).getDay()]
            : months[new Date(d.date).getMonth()],
        value: !!d.avg_hrMax ? +d.avg_hrMax : null,
      })),
    },

    {
      name: "Missed Exercises",
      color: "#FB004B",
      items: data.map((d) => ({
        date:
          selectedTimeFrame === "weekly"
            ? days[new Date(d.date).getDay()]
            : months[new Date(d.date).getMonth()],
        value:
          +d.avg_missedExercises > 0
            ? +d.avg_missedExercises /
              (+d.avg_missedExercises +
                +d.avg_oneStarExercises +
                +d.avg_twoStarExercises +
                +d.avg_threeStarExercises)
            : null,
        value2: +d.avg_missedExercises > 0 ? 0 : null,
        star: 0,
      })),
    },
    {
      name: "One Star Exercises",
      color: "#E2AC00",
      items: data.map((d) => ({
        date:
          selectedTimeFrame === "weekly"
            ? days[new Date(d.date).getDay()]
            : months[new Date(d.date).getMonth()],
        value:
          +d.avg_oneStarExercises > 0
            ? +d.avg_oneStarExercises /
                (+d.avg_missedExercises +
                  +d.avg_oneStarExercises +
                  +d.avg_twoStarExercises +
                  +d.avg_threeStarExercises) +
              +d.avg_missedExercises /
                (+d.avg_missedExercises +
                  +d.avg_oneStarExercises +
                  +d.avg_twoStarExercises +
                  +d.avg_threeStarExercises)
            : null,
        value2:
          +d.avg_oneStarExercises > 0
            ? +d.avg_missedExercises /
              (+d.avg_missedExercises +
                +d.avg_oneStarExercises +
                +d.avg_twoStarExercises +
                +d.avg_threeStarExercises)
            : null,
        star: 1,
      })),
    },
    {
      name: "Two Star Exercises",
      color: "#E2AC00",
      items: data.map((d) => ({
        date:
          selectedTimeFrame === "weekly"
            ? days[new Date(d.date).getDay()]
            : months[new Date(d.date).getMonth()],
        value:
          +d.avg_twoStarExercises > 0
            ? +d.avg_twoStarExercises /
                (+d.avg_missedExercises +
                  +d.avg_oneStarExercises +
                  +d.avg_twoStarExercises +
                  +d.avg_threeStarExercises) +
              +d.avg_oneStarExercises /
                (+d.avg_missedExercises +
                  +d.avg_oneStarExercises +
                  +d.avg_twoStarExercises +
                  +d.avg_threeStarExercises) +
              +d.avg_missedExercises /
                (+d.avg_missedExercises +
                  +d.avg_oneStarExercises +
                  +d.avg_twoStarExercises +
                  +d.avg_threeStarExercises)
            : null,
        value2:
          +d.avg_twoStarExercises > 0
            ? +d.avg_oneStarExercises /
                (+d.avg_missedExercises +
                  +d.avg_oneStarExercises +
                  +d.avg_twoStarExercises +
                  +d.avg_threeStarExercises) +
              +d.avg_missedExercises /
                (+d.avg_missedExercises +
                  +d.avg_oneStarExercises +
                  +d.avg_twoStarExercises +
                  +d.avg_threeStarExercises)
            : null,
        star: 2,
      })),
    },
    {
      name: "Three Star Exercises",
      color: "#E2AC00",
      items: data.map((d) => ({
        date:
          selectedTimeFrame === "weekly"
            ? days[new Date(d.date).getDay()]
            : months[new Date(d.date).getMonth()],
        value:
          +d.avg_threeStarExercises > 0
            ? +d.avg_threeStarExercises /
                (+d.avg_missedExercises +
                  +d.avg_oneStarExercises +
                  +d.avg_twoStarExercises +
                  +d.avg_threeStarExercises) +
              +d.avg_twoStarExercises /
                (+d.avg_missedExercises +
                  +d.avg_oneStarExercises +
                  +d.avg_twoStarExercises +
                  +d.avg_threeStarExercises) +
              +d.avg_oneStarExercises /
                (+d.avg_missedExercises +
                  +d.avg_oneStarExercises +
                  +d.avg_twoStarExercises +
                  +d.avg_threeStarExercises) +
              +d.avg_missedExercises /
                (+d.avg_missedExercises +
                  +d.avg_oneStarExercises +
                  +d.avg_twoStarExercises +
                  +d.avg_threeStarExercises)
            : null,
        value2:
          +d.avg_threeStarExercises > 0
            ? +d.avg_twoStarExercises /
                (+d.avg_missedExercises +
                  +d.avg_oneStarExercises +
                  +d.avg_twoStarExercises +
                  +d.avg_threeStarExercises) +
              +d.avg_oneStarExercises /
                (+d.avg_missedExercises +
                  +d.avg_oneStarExercises +
                  +d.avg_twoStarExercises +
                  +d.avg_threeStarExercises) +
              +d.avg_missedExercises /
                (+d.avg_missedExercises +
                  +d.avg_oneStarExercises +
                  +d.avg_twoStarExercises +
                  +d.avg_threeStarExercises)
            : null,
        star: 3,
      })),
    },

    {
      name: "Tier 1",
      color: "#00A0B5",
      items: data.map((d) => ({
        date:
          selectedTimeFrame === "weekly"
            ? days[new Date(d.date).getDay()]
            : months[new Date(d.date).getMonth()],
        value:
          +d.avg_timeInTier1 > 0
            ? +d.avg_timeInTier1 /
              (+d.avg_timeInTier1 +
                +d.avg_timeInTier2 +
                +d.avg_timeInTier3 +
                +d.avg_timeInTier4 +
                +d.avg_timeInTier5)
            : null,
        value2: +d.avg_timeInTier1 > 0 ? 0 : null,
        circle: 1,
      })),
    },
    {
      name: "Tier 2",
      color: "#0071ED",
      items: data.map((d) => ({
        date:
          selectedTimeFrame === "weekly"
            ? days[new Date(d.date).getDay()]
            : months[new Date(d.date).getMonth()],
        value:
          +d.avg_timeInTier2 > 0
            ? +d.avg_timeInTier2 /
                (+d.avg_timeInTier1 +
                  +d.avg_timeInTier2 +
                  +d.avg_timeInTier3 +
                  +d.avg_timeInTier4 +
                  +d.avg_timeInTier5) +
              +d.avg_timeInTier1 /
                (+d.avg_timeInTier1 +
                  +d.avg_timeInTier2 +
                  +d.avg_timeInTier3 +
                  +d.avg_timeInTier4 +
                  +d.avg_timeInTier5)
            : null,
        value2:
          +d.avg_timeInTier2 > 0
            ? +d.avg_timeInTier1 /
              (+d.avg_timeInTier1 +
                +d.avg_timeInTier2 +
                +d.avg_timeInTier3 +
                +d.avg_timeInTier4 +
                +d.avg_timeInTier5)
            : null,
        circle: 2,
      })),
    },
    {
      name: "Tier 3",
      color: "#6ECE41",
      items: data.map((d) => ({
        date:
          selectedTimeFrame === "weekly"
            ? days[new Date(d.date).getDay()]
            : months[new Date(d.date).getMonth()],
        value:
          +d.avg_timeInTier3 > 0
            ? +d.avg_timeInTier3 /
                (+d.avg_timeInTier1 +
                  +d.avg_timeInTier2 +
                  +d.avg_timeInTier3 +
                  +d.avg_timeInTier4 +
                  +d.avg_timeInTier5) +
              +d.avg_timeInTier2 /
                (+d.avg_timeInTier1 +
                  +d.avg_timeInTier2 +
                  +d.avg_timeInTier3 +
                  +d.avg_timeInTier4 +
                  +d.avg_timeInTier5) +
              +d.avg_timeInTier1 /
                (+d.avg_timeInTier1 +
                  +d.avg_timeInTier2 +
                  +d.avg_timeInTier3 +
                  +d.avg_timeInTier4 +
                  +d.avg_timeInTier5)
            : null,
        value2:
          +d.avg_timeInTier3 > 0
            ? +d.avg_timeInTier2 /
                (+d.avg_timeInTier1 +
                  +d.avg_timeInTier2 +
                  +d.avg_timeInTier3 +
                  +d.avg_timeInTier4 +
                  +d.avg_timeInTier5) +
              +d.avg_timeInTier1 /
                (+d.avg_timeInTier1 +
                  +d.avg_timeInTier2 +
                  +d.avg_timeInTier3 +
                  +d.avg_timeInTier4 +
                  +d.avg_timeInTier5)
            : null,
        circle: 3,
      })),
    },
    {
      name: "Tier 4",
      color: "#E2AC00",
      items: data.map((d) => ({
        date:
          selectedTimeFrame === "weekly"
            ? days[new Date(d.date).getDay()]
            : months[new Date(d.date).getMonth()],
        value:
          +d.avg_timeInTier4 > 0
            ? +d.avg_timeInTier4 /
                (+d.avg_timeInTier1 +
                  +d.avg_timeInTier2 +
                  +d.avg_timeInTier3 +
                  +d.avg_timeInTier4 +
                  +d.avg_timeInTier5) +
              +d.avg_timeInTier3 /
                (+d.avg_timeInTier1 +
                  +d.avg_timeInTier2 +
                  +d.avg_timeInTier3 +
                  +d.avg_timeInTier4 +
                  +d.avg_timeInTier5) +
              +d.avg_timeInTier2 /
                (+d.avg_timeInTier1 +
                  +d.avg_timeInTier2 +
                  +d.avg_timeInTier3 +
                  +d.avg_timeInTier4 +
                  +d.avg_timeInTier5) +
              +d.avg_timeInTier1 /
                (+d.avg_timeInTier1 +
                  +d.avg_timeInTier2 +
                  +d.avg_timeInTier3 +
                  +d.avg_timeInTier4 +
                  +d.avg_timeInTier5)
            : null,
        value2:
          +d.avg_timeInTier4 > 0
            ? +d.avg_timeInTier3 /
                (+d.avg_timeInTier1 +
                  +d.avg_timeInTier2 +
                  +d.avg_timeInTier3 +
                  +d.avg_timeInTier4 +
                  +d.avg_timeInTier5) +
              +d.avg_timeInTier2 /
                (+d.avg_timeInTier1 +
                  +d.avg_timeInTier2 +
                  +d.avg_timeInTier3 +
                  +d.avg_timeInTier4 +
                  +d.avg_timeInTier5) +
              +d.avg_timeInTier1 /
                (+d.avg_timeInTier1 +
                  +d.avg_timeInTier2 +
                  +d.avg_timeInTier3 +
                  +d.avg_timeInTier4 +
                  +d.avg_timeInTier5)
            : null,
        circle: 4,
      })),
    },
    {
      name: "Tier 5",
      color: "#C600DB",
      items: data.map((d) => ({
        date:
          selectedTimeFrame === "weekly"
            ? days[new Date(d.date).getDay()]
            : months[new Date(d.date).getMonth()],
        value:
          +d.avg_timeInTier5 > 0
            ? +d.avg_timeInTier5 /
                (+d.avg_timeInTier1 +
                  +d.avg_timeInTier2 +
                  +d.avg_timeInTier3 +
                  +d.avg_timeInTier4 +
                  +d.avg_timeInTier5) +
              +d.avg_timeInTier4 /
                (+d.avg_timeInTier1 +
                  +d.avg_timeInTier2 +
                  +d.avg_timeInTier3 +
                  +d.avg_timeInTier4 +
                  +d.avg_timeInTier5) +
              +d.avg_timeInTier3 /
                (+d.avg_timeInTier1 +
                  +d.avg_timeInTier2 +
                  +d.avg_timeInTier3 +
                  +d.avg_timeInTier4 +
                  +d.avg_timeInTier5) +
              +d.avg_timeInTier2 /
                (+d.avg_timeInTier1 +
                  +d.avg_timeInTier2 +
                  +d.avg_timeInTier3 +
                  +d.avg_timeInTier4 +
                  +d.avg_timeInTier5) +
              +d.avg_timeInTier1 /
                (+d.avg_timeInTier1 +
                  +d.avg_timeInTier2 +
                  +d.avg_timeInTier3 +
                  +d.avg_timeInTier4 +
                  +d.avg_timeInTier5)
            : null,
        value2:
          +d.avg_timeInTier5 > 0
            ? +d.avg_timeInTier4 /
                (+d.avg_timeInTier1 +
                  +d.avg_timeInTier2 +
                  +d.avg_timeInTier3 +
                  +d.avg_timeInTier4 +
                  +d.avg_timeInTier5) +
              +d.avg_timeInTier3 /
                (+d.avg_timeInTier1 +
                  +d.avg_timeInTier2 +
                  +d.avg_timeInTier3 +
                  +d.avg_timeInTier4 +
                  +d.avg_timeInTier5) +
              +d.avg_timeInTier2 /
                (+d.avg_timeInTier1 +
                  +d.avg_timeInTier2 +
                  +d.avg_timeInTier3 +
                  +d.avg_timeInTier4 +
                  +d.avg_timeInTier5) +
              +d.avg_timeInTier1 /
                (+d.avg_timeInTier1 +
                  +d.avg_timeInTier2 +
                  +d.avg_timeInTier3 +
                  +d.avg_timeInTier4 +
                  +d.avg_timeInTier5)
            : null,
        circle: 5,
      })),
    },
  ];

  const filteredChartDataNew =
    selectedProgress === "Flow Score"
      ? chartDataNew.filter((el) => el.name === "Flow Score")
      : selectedProgress === "HR Max / AVG HR"
      ? chartDataNew.filter(
          (el) => el.name === "Average HR" || el.name === "Max HR"
        )
      : selectedProgress === "Rating"
      ? chartDataNew.filter(
          (el) =>
            el.name === "Missed Exercises" ||
            el.name === "One Star Exercises" ||
            el.name === "Two Star Exercises" ||
            el.name === "Three Star Exercises"
        )
      : selectedProgress === "Tier Times"
      ? chartDataNew.filter(
          (el) =>
            el.name === "Tier 1" ||
            el.name === "Tier 2" ||
            el.name === "Tier 3" ||
            el.name === "Tier 4" ||
            el.name === "Tier 5"
        )
      : null;

  return (
    <Layout headline={t("progress.headline")}>
      <h2 className={Styles.progressType}>
        {selectedProgress === "Tier Times" && t("progress.tierTimes")}
        {selectedProgress === "Flow Score" && t("progress.flowScore")}
        {selectedProgress === "Rating" && t("progress.rating")}
        {selectedProgress === "HR Max / AVG HR" && t("progress.hrMaxAvgHr")}
      </h2>

      <select
        className={Styles.selectTimeFrame}
        onChange={(e) => setSelectedTimeFrame(e.target.value)}
        value={selectedTimeFrame}
      >
        <option value={"weekly"}>{t("progress.timeFrameWeekly")}</option>
        <option value={"yearly"}>{t("progress.timeFrameYearly")}</option>
        <option value={"lastyearly"}>2021</option>
      </select>

      <ProgressChart
        data={filteredChartDataNew}
        margin={{
          top: 10,
          right: 1,
          bottom: 30,
          left: 39,
        }}
        timeFrame={selectedTimeFrame}
        HrYScale={selectedProgress === "HR Max / AVG HR"}
        lineVertical={
          selectedProgress === "Rating" || selectedProgress === "Tier Times"
        }
      />

      <div className={Styles.selectProgress}>
        <button
          name="Tier Times"
          className={`${
            selectedProgress === "Tier Times" ? Styles.active : ""
          } ${"small"}`}
          onClick={(e) => changeSelectedProgress(e)}
        >
          {t("progress.tierTimes")}
        </button>

        <button
          name="Flow Score"
          className={`${
            selectedProgress === "Flow Score" ? Styles.active : ""
          } ${"small"}`}
          onClick={(e) => changeSelectedProgress(e)}
        >
          {t("progress.flowScore")}
        </button>

        <button
          name="Rating"
          className={`${
            selectedProgress === "Rating" ? Styles.active : ""
          } ${"small"}`}
          onClick={(e) => changeSelectedProgress(e)}
        >
          {t("progress.rating")}
        </button>

        <button
          name="HR Max / AVG HR"
          className={`${
            selectedProgress === "HR Max / AVG HR" ? Styles.active : ""
          } ${"small"}`}
          onClick={(e) => changeSelectedProgress(e)}
        >
          {t("progress.hrMaxAvgHr")}
        </button>
      </div>

      {/* <div style={{ marginTop: 32 }}>
        <h6>Server Data:</h6>
        <pre style={{ fontSize: 8 }}>{JSON.stringify(data, null, 4)}</pre>
      </div>
      <div style={{ marginTop: 32 }}>
        <h6>Processed Data:</h6>
        <pre style={{ fontSize: 8 }}>
          {JSON.stringify(chartDataNew, null, 4)}
        </pre>
      </div> */}
    </Layout>
  );
};

export default Progress;
