/** MultilineChart.controller.js */
import { useMemo } from "react";
import * as d3 from "d3";
import { scalePoint } from "d3";
import { useTranslation } from "react-i18next";

const useController = ({ data, width, height, timeFrame, HrYScale }) => {
  const { t } = useTranslation();

  const xScale = useMemo(
    () =>
      timeFrame === "weekly"
        ? d3
            .scalePoint()
            .domain([
              t("common.weekdays.monday"),
              t("common.weekdays.tuesday"),
              t("common.weekdays.wednesday"),
              t("common.weekdays.thursday"),
              t("common.weekdays.friday"),
              t("common.weekdays.saturday"),
              t("common.weekdays.sunday"),
            ])
            .range([0, width])
            .padding(0.5)
        : d3
            .scalePoint()
            .domain([
              t("common.months.january"),
              t("common.months.february"),
              t("common.months.march"),
              t("common.months.april"),
              t("common.months.may"),
              t("common.months.june"),
              t("common.months.july"),
              t("common.months.august"),
              t("common.months.september"),
              t("common.months.october"),
              t("common.months.november"),
              t("common.months.december"),
            ])
            .range([0, width])
            .padding(0.5),
    [timeFrame, width, t]
  );

  const xScaleForAxis = useMemo(
    () =>
      timeFrame === "weekly"
        ? scalePoint().domain([0, 1, 2, 3, 4, 5, 6, 7]).range([0, width])
        : scalePoint()
            .domain([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])
            .range([0, width]),
    [timeFrame, width]
  );

  const yMin = useMemo(
    () => d3.min(data, ({ items }) => d3.min(items, ({ value }) => value)),
    [data]
  );

  const yMax = useMemo(
    () => d3.max(data, ({ items }) => d3.max(items, ({ value }) => value)),
    [data]
  );

  const yScale = useMemo(
    () =>
      HrYScale
        ? d3
            .scaleLinear()
            .domain([d3.min([80, yMin]), d3.max([220, yMax])])
            .range([height, 0])
            .nice()
        : d3.scaleLinear().domain([0, 1]).range([height, 0]),
    [height, HrYScale, yMin, yMax]
  );

  const yScaleForAxis = yScale;

  const yTickFormat = (d) => (HrYScale ? d : d3.format(".0%")(d));

  const xTickFormat = (d) =>
    timeFrame === "weekly" ? d.substring(0, 2) : d.substring(0, 1);

  return {
    xTickFormat,
    yTickFormat,
    xScale,
    yScale,
    xScaleForAxis,
    yScaleForAxis,
  };
};

export default useController;
