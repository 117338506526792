import React from "react";
import { useAuthState, useAuthDispatch, logout } from "../../Context";

import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteQuery } from "react-query";
import { Link, useSearchParams, useNavigate } from "react-router-dom";

import Styles from "./Leaderboards.module.scss";

import Layout from "../../Components/Layout/Layout";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import DualflowScoreStat from "../../Components/WorkoutStat/DualflowScoreStat";
import ScoreStat from "../../Components/WorkoutStat/ScoreStat";

import { FaUserAlt } from "react-icons/fa";

import ROOT_URL from "../../Config/rootUrl";
import { useTranslation } from "react-i18next";

const Leaderboards = () => {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [workoutPresetId, setWorkoutPresetId] = React.useState(+searchParams.get("workout") || 1);
  const [workoutDuration, setWorkoutDuration] = React.useState(+searchParams.get("duration") || 600);
  const [leagueQualification, setLeagueQualification] = React.useState(+searchParams.get("league_qualification") || 0);

  const { t } = useTranslation();

  // let workoutPresetId = searchParams.get("workout") || 1;
  // let workoutDuration = searchParams.get("duration") || 600;



  const [dataLength, setDataLength] = React.useState();
  const [highscoreData, setHighscoreData] = React.useState();


  React.useEffect(() => {
    setWorkoutPresetId(+searchParams.get("workout") || 1);
    setWorkoutDuration(+searchParams.get("duration") || 600);
    setLeagueQualification(+searchParams.get("league_qualification") || 0);
  }, [searchParams, setSearchParams])



  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ["highscores", { id: +workoutPresetId, duration: +workoutDuration, leagueQualification: +leagueQualification }],
    async ({ pageParam = 1 }) => {
      const token = userDetails?.token;
      if (!token) return;
      const response = await fetch(
        `${ROOT_URL}/api/v1/highscores?workoutPresetId=${+workoutPresetId}&duration=${+workoutDuration}&size=100&page=${pageParam}${!!(leagueQualification > 0) ? "&league_qualification=1" : ""}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseJson = await response.json();
      if (data?.error?.name === "TokenExpiredError") {
        logout(dispatch);
      }
      return responseJson;
    },
    {
      getNextPageParam: (page) =>
        page.currentPage === page.totalPages ? undefined : page.currentPage + 1,
    }
  );



  React.useEffect(() => {
    try {
      setDataLength(
        data?.pages?.reduce(
          (counter, page) => counter + page.highscores?.length,
          0
        )
      );
      const fetchedHighscoreData = data?.pages
        ?.map((entries) => entries.highscores)
        .flat();

      setHighscoreData(fetchedHighscoreData);
    } catch (err) {
      console.log(err);
      return;
    }
  }, [data]);




  const changeWorkoutPresetId = (reverse) => {

    if (reverse) {

      if (workoutPresetId === 1) {
        navigate("/leaderboard-attack-on-the-core");
      }

      if (workoutPresetId === 2) {
        setSearchParams(`workout=${1}&duration=${+workoutDuration}`);
      }

      if (workoutPresetId === 6) {
        setSearchParams(`workout=${2}&duration=${+workoutDuration}`);
      }

      if (workoutPresetId === 5) {
        if (!!leagueQualification) {
          setSearchParams(`workout=${5}&duration=${+workoutDuration}`)
        } else {
          setSearchParams(`workout=${6}&duration=${+workoutDuration}`)
        }
      }

    } else {

      if (workoutPresetId === 1) {
        setSearchParams(`workout=${2}&duration=${+workoutDuration}`)
      }

      if (workoutPresetId === 2) {
        setSearchParams(`workout=${6}&duration=${+workoutDuration}`)
      }

      if (workoutPresetId === 6) {
        setSearchParams(`workout=${5}&duration=${+workoutDuration}`)
      }

      if (workoutPresetId === 5) {
        if (!leagueQualification) {
          setSearchParams(`workout=${5}&duration=${600}&league_qualification=${1}`)
        } else {
          navigate("/leaderboard-attack-on-the-core")
        }
      }

    }

  };



  const changeWorkoutDuration = (e) => {
    // setWorkoutDuration(+e.target.value);
    setSearchParams(`workout=${workoutPresetId}&duration=${e.target.value}`);
  };






  return (
    <Layout headline={t("leaderboards.headline")}>
      <div className={Styles.workoutSelector}>
        <div className={Styles.selectType}>
          <div
            className={Styles.selectLeft}
            onClick={(reverse) => changeWorkoutPresetId(reverse)}
          />

          <h2 className={Styles.workoutType}>
            {(workoutPresetId === 1 && "Dual Flow") ||
              (workoutPresetId === 2 && "Upper Body") ||
              (workoutPresetId === 6 && "Reha Flow") ||
              (workoutPresetId === 5 && !leagueQualification && "FIBO Mode") ||
              (workoutPresetId === 5 && !!leagueQualification && "League Qualification")}
          </h2>

          <div
            className={Styles.selectRight}
            onClick={() => changeWorkoutPresetId()}
          />
        </div>

        <select
          className={Styles.workoutTime}
          value={workoutDuration}
          onChange={changeWorkoutDuration}
          disabled={workoutPresetId === 5 && !!leagueQualification}
        >
          <option value={180}>3 {t("common.minutes", { count: 3 })}</option>
          <option value={300}>5 {t("common.minutes", { count: 5 })}</option>
          <option value={600}>10 {t("common.minutes", { count: 10 })}</option>
          <option value={900}>15 {t("common.minutes", { count: 15 })}</option>
          <option value={1200}>20 {t("common.minutes", { count: 20 })}</option>
          <option value={1500}>25 {t("common.minutes", { count: 25 })}</option>
          <option value={1800}>30 {t("common.minutes", { count: 30 })}</option>
        </select>
      </div>

      {!data || dataLength === undefined ? (
        <LoadingSpinner />
      ) : (
        <InfiniteScroll
          dataLength={dataLength}
          next={fetchNextPage}
          hasMore={hasNextPage}
          loader={dataLength > 0 && <LoadingSpinner positionRelative={true} />}
        >
          {data.pages.map((group, i, array) => (
            <React.Fragment key={i}></React.Fragment>
          ))}
          {highscoreData &&
            highscoreData.map((highscore, i) => (
              <Link
                to={`/workout/${highscore.id}`}
                className={`${Styles.highscore} ${workoutPresetId === 5 && !!(leagueQualification > 0) ? Styles.qualified : ""
                  }`}
                key={highscore.dualflowScore + highscore.score + i}
              >
                <div className={Styles.user}>
                  <div className={Styles.circle}>
                    {highscore.profileImage ? (
                      <img
                        srcSet={`${ROOT_URL}/public/images/${highscore.profileImage}-40x40.jpg 40w, ${ROOT_URL}/public/images/${highscore.profileImage}-80x80.jpg 80w, ${ROOT_URL}/public/images/${highscore.profileImage}-120x120.jpg 120w, ${ROOT_URL}/public/images/${highscore.profileImage}-160x160.jpg 160w`}
                        sizes="40px"
                        src={`${ROOT_URL}/public/images/${highscore.profileImage}-40x40.jpg`}
                        alt={highscore.username}
                      />
                    ) : (
                      <FaUserAlt className={Styles.placeholder} />
                    )}
                  </div>
                  <div className={Styles.userNameContainer}>
                    <h3>{highscore.username}&nbsp;</h3>
                    {workoutPresetId === 5 && <p>{highscore.exercube}&nbsp;</p>}
                  </div>
                  <h3 className={Styles.position}>{++i}.</h3>
                </div>
                <div className={Styles.statsColumn}>
                  <DualflowScoreStat
                    value={highscore.dualflowScore}
                    horizontal={true}
                  />
                  <ScoreStat value={highscore.score} horizontal={true} />
                </div>
              </Link>
            ))}
        </InfiniteScroll>
      )}
    </Layout>
  );
};

export default Leaderboards;
