import React from "react";
import ReactDOM from "react-dom";
import "./Fonts/MessinaSans_Web_Regular/MessinaSansWeb-Regular.eot";
import "./Fonts/MessinaSans_Web_Regular/MessinaSansWeb-Regular.woff";
import "./Fonts/MessinaSans_Web_Regular/MessinaSansWeb-Regular.woff2";
import "./Fonts/MessinaSans_Web_Bold/MessinaSansWeb-Bold.eot";
import "./Fonts/MessinaSans_Web_Bold/MessinaSansWeb-Bold.woff";
import "./Fonts/MessinaSans_Web_Bold/MessinaSansWeb-Bold.woff2";
import "./Fonts/MessinaSans_Web_BoldItalic/MessinaSansWeb-BoldItalic.eot";
import "./Fonts/MessinaSans_Web_BoldItalic/MessinaSansWeb-BoldItalic.woff";
import "./Fonts/MessinaSans_Web_BoldItalic/MessinaSansWeb-BoldItalic.woff2";
import "./Fonts/MessinaSans_Web_BlackItalic/MessinaSansWeb-BlackItalic.eot";
import "./Fonts/MessinaSans_Web_BlackItalic/MessinaSansWeb-BlackItalic.woff";
import "./Fonts/MessinaSans_Web_BlackItalic/MessinaSansWeb-BlackItalic.woff2";
import "./index.scss";

import App from "./App";
// import reportWebVitals from './reportWebVitals';

import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
