import React from "react";
import { TailSpin } from "react-loader-spinner";
import Styles from "./LoadingSpinner.module.scss";

const LoadingSpinner = ({ positionRelative }) => {
  return (
    <div
      className={`${Styles.loaderContainer} ${
        positionRelative && Styles.positionRelative
      }`}
    >
      <TailSpin color="#00d1ff" height={40} width={40} />
    </div>
  );
};

export default LoadingSpinner;
