const DumbbellIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill="white" />
      <path
        d="M4.48276 4C4.25435 4.00026 4.06923 4.18538 4.06897 4.41379V11.0345C4.06897 11.263 4.25425 11.4483 4.48276 11.4483C4.71128 11.4483 4.89656 11.263 4.89656 11.0345V4.41379C4.8963 4.18538 4.71118 4.00026 4.48276 4Z"
        fill="black"
      />
      <path d="M5.3103 7.31033H10.6896V8.13792H5.3103V7.31033Z" fill="black" />
      <path
        d="M2.41379 7.31033C2.18528 7.31033 2 7.49561 2 7.72413C2 7.95264 2.18528 8.13792 2.41379 8.13792V7.31033Z"
        fill="black"
      />
      <path
        d="M13.5861 7.31033V8.13792C13.8146 8.13792 13.9999 7.95264 13.9999 7.72413C13.9999 7.49561 13.8146 7.31033 13.5861 7.31033Z"
        fill="black"
      />
      <path
        d="M13.1724 10.4138V5.03449C13.1724 4.80597 12.9872 4.6207 12.7586 4.6207C12.5301 4.6207 12.3448 4.80597 12.3448 5.03449V10.4138C12.3448 10.6423 12.5301 10.8276 12.7586 10.8276C12.9872 10.8276 13.1724 10.6423 13.1724 10.4138Z"
        fill="black"
      />
      <path
        d="M11.5172 4C11.2888 4.00026 11.1037 4.18538 11.1034 4.41379V11.0345C11.1034 11.263 11.2887 11.4483 11.5172 11.4483C11.7457 11.4483 11.931 11.263 11.931 11.0345V4.41379C11.9307 4.18538 11.7456 4.00026 11.5172 4Z"
        fill="black"
      />
      <path
        d="M3.65522 10.4138V5.03449C3.65522 4.80597 3.46995 4.6207 3.24143 4.6207C3.01291 4.6207 2.82764 4.80597 2.82764 5.03449V10.4138C2.82764 10.6423 3.01291 10.8276 3.24143 10.8276C3.46995 10.8276 3.65522 10.6423 3.65522 10.4138Z"
        fill="black"
      />
    </svg>
  );
};
export default DumbbellIcon;
