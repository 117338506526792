import React from "react";
import Styles from "./Layout.module.scss";

const Hamburger = ({ setMenuOpen, menuOpen }) => {
  return (
    <button
      className={`${Styles.hamburger} ${menuOpen && Styles.menuOpen}`}
      onClick={() => setMenuOpen(!menuOpen)}
    >
      <span className={Styles.barTop}></span>
      <span className={Styles.barMid}></span>
      <span className={Styles.barBot}></span>
    </button>
  );
};

export default Hamburger;
