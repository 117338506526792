import React from "react";
import OutlineDummyAnim from "../../Assets/OutlineDummyAnim.svg";

export const TimelineMarkerCircle = ({
  r,
  x,
  y,
  physicalRating,
  cognitiveRating,
  tier,
}) => {
  return (
    <g transform={`translate(${r / 2 - 12}, ${r / 2 - 12})`}>
      <circle
        cx={x}
        cy={y}
        r={r}
        fill="#101010"
        stroke={`${
          tier === 0
            ? "#00A0B5"
            : tier === 1
            ? "#0071ED"
            : tier === 2
            ? "#6ECE41"
            : tier === 3
            ? "#E2AC00"
            : "#C600DB"
        }`}
        strokeWidth={0.5}
      />

      <g transform={`translate(${x}, ${r + 16})`}>
        <circle
          cx={0}
          cy={0}
          r={r - 6}
          stroke={`${
            physicalRating > 0 || cognitiveRating > 0 ? "white" : "#AFAFAF"
          }`}
          fill="none"
        />
        {physicalRating > 0 || cognitiveRating > 0 ? (
          <g transform={`translate(-22,-24)`}>
            <image x={0} y={0} width={44} height={44} href={OutlineDummyAnim} />
          </g>
        ) : (
          <g transform={`translate(-9,-9)`}>
            <path
              d="M9.013 11.7818L3.59399 17.2018L0.913025 14.5198L6.33301 9.09984L0.911987 3.67982L3.59399 0.998825L9.013 6.41783L14.435 0.996826L17.116 3.67783L11.695 9.09984L17.117 14.5218L14.435 17.2038L9.013 11.7818Z"
              fill="#E0002B"
            />
            <path
              d="M14.435 1.7038L16.409 3.6788L10.988 9.1008L16.41 14.5228L14.435 16.4978L9.013 11.0758L3.59302 16.4958L1.61798 14.5208L7.03802 9.1008L1.617 3.6808L3.59302 1.7058L9.013 7.12581L14.435 1.7038ZM14.435 0.289795L13.728 0.996796L9.013 5.71181L4.29999 0.998795L3.59302 0.291809L2.88599 0.998795L0.909973 2.9738L0.203003 3.6808L0.909973 4.3878L5.625 9.1008L0.911011 13.8138L0.205017 14.5208L0.911011 15.2278L2.88599 17.2028L3.59302 17.9098L4.29999 17.2028L9.013 12.4898L13.728 17.2048L14.435 17.9118L15.142 17.2048L17.117 15.2298L17.824 14.5228L17.117 13.8158L12.402 9.1008L17.116 4.3858L17.823 3.6788L17.116 2.9718L15.142 0.996796L14.435 0.289795Z"
              fill="black"
            />
          </g>
        )}
      </g>
      <g transform={`translate(${x - r}, 16)`}>
        <circle
          cx={8.25}
          cy={16.5}
          r={3}
          stroke="#101010"
          strokeWidth={2}
          fill={`${physicalRating > 2 ? "#FFE600" : "#AFAFAF"}`}
        />
        <circle
          cx={11.5}
          cy={11.5}
          r={3}
          stroke="#101010"
          strokeWidth={2}
          fill={`${physicalRating > 1 ? "#FFE600" : "#AFAFAF"}`}
        />
        <circle
          cx={16.5}
          cy={8.5}
          r={3}
          stroke="#101010"
          strokeWidth={2}
          fill={`${physicalRating > 0 ? "#FFE600" : "#AFAFAF"}`}
        />
        {/* <text x="24px" y="60" fontSize="10px" textAnchor="middle" fill="white" opacity="0.5">
          {tier + 1}
        </text> */}
      </g>

      <g transform={`translate(${x + r}, 64) rotate(180)`}>
        <circle
          cx={8.25}
          cy={16.5}
          r={3}
          stroke="#101010"
          strokeWidth={2}
          fill={`${cognitiveRating > 2 ? "#00FFFF" : "#AFAFAF"}`}
        />
        <circle
          cx={11.5}
          cy={11.5}
          r={3}
          stroke="#101010"
          strokeWidth={2}
          fill={`${cognitiveRating > 1 ? "#00FFFF" : "#AFAFAF"}`}
        />
        <circle
          cx={16.5}
          cy={8.5}
          r={3}
          stroke="#101010"
          strokeWidth={2}
          fill={`${cognitiveRating > 0 ? "#00FFFF" : "#AFAFAF"}`}
        />
      </g>
    </g>
  );
};
