import React from "react";
import Styles from "./Question.module.scss";

const Question = ({ question, selectedAnswer, onAnswerSelect }) => {
  // Use question.answers directly
  const allAnswers = question.answers || []; // Default to empty array if not defined

  return (
    <div className={Styles.question}>
      <h3>{question.question}</h3>
      <div className={Styles.answers}>
        {allAnswers.map((answer, index) => (
          <label key={index}>
            <input
              type="radio"
              name={`question-${question.id}`}
              value={answer}
              checked={selectedAnswer === answer}
              onChange={() => onAnswerSelect(answer)}
            />
            {answer}
          </label>
        ))}
      </div>
    </div>
  );
};

export default Question;
