import React from "react";
import { useTranslation } from "react-i18next";

import Styles from "./PageNotFound.module.scss";

const PageNotFound = () => {
  const {t} = useTranslation();

  return (
    <div className={Styles.container}>
      <h1>404</h1>
      <p>{t("common.pageNotFound")}</p>
    </div>
  );
};

export default PageNotFound;
