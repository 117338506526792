import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { loginUser, useAuthState, useAuthDispatch } from "../../Context";

import Logo from "../../Assets/Logo.js";
import Container from "../../Components/Container/Container";
import Styles from "./Login.module.scss";

import { CSSTransition } from "react-transition-group";
import Alert from "../../Components/Alert/Alert";
import { Trans, useTranslation } from "react-i18next";

const Login = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);

  /************************************************
   *
   *      QUICK FIX => PLEASE REMOVE LATER !!!
   *
   *************************************************/
   React.useEffect(() => {
    if (document.body.classList.contains("menu-open")) {
      document.body.classList.remove("menu-open");
    }
  }, []);

  const { t } = useTranslation();

  const dispatch = useAuthDispatch();
  const {
    user,
    loading,
    // errorMessage,
    successMessage,
  } = useAuthState(); // read the values of loading and errorMessage from context

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = { email, password };
    try {
      const response = await loginUser(dispatch, payload); // loginUser action makes the request and handles all the neccessary state changes

      if (response?.success) {
        navigate("/verify_email");
        return;
      }

      if (!response?.user) {
        setShowAlert(true);
        return;
      }

      navigate("/"); // navigate to Home on success
    } catch (error) {
      console.log(error);
      setShowAlert(true);
    }
  };

  if (user?.email) return <Navigate to="/" />;

  return (
    <Container fullHeight>
      <h1>{t("login.headline")}</h1>

      <form className={Styles.form} onSubmit={handleSubmit}>
        <CSSTransition
          in={showAlert}
          timeout={200}
          classNames="fade"
          unmountOnExit
        >
          <Alert
            // message={errorMessage}
            message={t("login.errorMessage")}
            color="danger"
            dismissible={true}
            onClick={setShowAlert}
          />
        </CSSTransition>
        <CSSTransition
          in={!!successMessage}
          timeout={200}
          classNames="fade"
          unmountOnExit
        >
          <Alert
            // message={successMessage}
            message={t("forgotPassword.successMessage")}
            color="success"
            // dismissible={true}
            // onClick={setShowSuccessAlert}
          />
        </CSSTransition>
        <input
          type="email"
          name="email"
          value={email}
          placeholder={t("common.inputPlaceholders.email")}
          onChange={(e) => setEmail(e.target.value)}
          disabled={loading}
          autoComplete="on"
          required
        />
        <input
          type="password"
          name="password"
          value={password}
          placeholder={t("common.inputPlaceholders.password")}
          onChange={(e) => setPassword(e.target.value)}
          disabled={loading}
          autoComplete="on"
          required
        />
        <input
          type="submit"
          disabled={loading}
          value={t("common.buttons.login")}
        />
        <p className="text-center">
          <Link to="/forgot_password">{t("login.forgotPassword")}</Link>
        </p>
        <p className="text-center">
          <Trans i18nKey={"login.createAccount"}>
            <Link to="/register"></Link>
          </Trans>
        </p>
      </form>

      <div className={Styles.footer}>
        <Logo />
      </div>
    </Container>
  );
};

export default Login;
