import React from "react";
import WorkoutDateSeparator from "../WorkoutDateSeparator/WorkoutDateSeparator";
import Workout from "../Workout/Workout";
import Styles from "./WorkoutList.module.scss";

const WorkoutList = ({ workoutData, select, selectedWorkout, setSelectedWorkout, crud, setModalDeleteWorkout }) => {
  return (
    <div className={Styles.marginBottom}>
      {workoutData &&
        workoutData.map((workoutGroup) => (
          <div key={workoutGroup.updatedAt}>
            <WorkoutDateSeparator date={workoutGroup.updatedAt} />
            {workoutGroup.workouts.map((workout) => (
              <Workout workout={workout} key={workout.id} select={select} selectedWorkout={selectedWorkout} setSelectedWorkout={setSelectedWorkout} crud={crud} setModalDeleteWorkout={setModalDeleteWorkout} />
            ))}
          </div>
        ))}
    </div>
  );
};

export default WorkoutList;
