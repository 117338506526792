import React from "react";
import Styles from "./Container.module.scss";

const Container = ({ center, fullHeight, fixedButtonAtBottom, children }) => {
  return (
    <div
      className={`${Styles.container} ${center ? Styles.center : ""} ${fixedButtonAtBottom ? Styles.fixedButtonAtBottom : ""} ${
        fullHeight ? Styles.fullHeight : ""
      }`}
    >
      {children}
    </div>
  );
};

export default Container;
