import React, { useState, useEffect } from "react";
import { useAuthState, useAuthDispatch, logout } from "../../Context";
import Layout from "../../Components/Layout/Layout";
import Row from "../../Components/Row/Row";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import { useTranslation } from "react-i18next";
import ROOT_URL from "../../Config/rootUrl";
import Styles from "./Quizzes.module.scss";

const Quizzes = () => {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();
  const { i18n } = useTranslation();

  const [quizzes, setQuizzes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchQuizzes = async () => {
      try {
        const token = userDetails?.token;
        if (!token) return;

        const response = await fetch(`${ROOT_URL}/api/v1/quiz/result/`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        if (data?.error?.name === "TokenExpiredError") {
          logout(dispatch);
        }

        setQuizzes(data.quizzes);
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch quizzes");
        setLoading(false);
      }
    };

    fetchQuizzes();
  }, [userDetails?.token, dispatch]);

  if (loading)
    return (
      <Layout headline="Quizzes">
        <LoadingSpinner />
      </Layout>
    );

  if (error)
    return (
      <Layout headline="Quizzes">
        <p style={{ textAlign: "center" }}>{error}</p>
      </Layout>
    );

    if (!quizzes || quizzes.length < 1)
      return (
        <Layout headline="Quizzes">
          <p style={{ textAlign: "center" }}>No quizzes found</p>
        </Layout>
      );

  return (
    <Layout headline="Quizzes">
      <div className={Styles.marginBottom}>
        <div className={Styles.container}>
          {quizzes.map((quiz) => (
            <div className={Styles.outerContainer} key={quiz.quizId}>
              <div className={Styles.innerContainer}>
                {
                  <Link to={`/quiz/${encodeURIComponent(quiz.quizId)}`} className={Styles.quiz}>
                    <h3>{quiz.quizId.slice(0, -14)}</h3>
                    <Row smallMarginBottom>
                      <div className={`${Styles.quizStat}`}>
                        <h4>Korrekt</h4>
                        <p>{quiz.correctAnswers}</p>
                      </div>
                      <div className={`${Styles.quizStat}`}>
                        <h4>Falsch</h4>
                        <p>{quiz.wrongAnswers}</p>
                      </div>
                      <div className={`${Styles.quizStat}`}>
                        <h4>Leer</h4>
                        <p>{quiz.blankAnswers}</p>
                      </div>
                    </Row>
                    <Row>
                      <div className={`${Styles.quizStat}`}>
                        <h4>Abgeschlossen</h4>
                        <p>{quiz.userHasCompletedTest ? "Ja" : "Nein"}</p>
                      </div>
                      <div className={`${Styles.quizStat}`}>
                        <h4>Bestanden</h4>
                        <p>{quiz.userHasPassedTest ? "Ja" : "Nein"}</p>
                      </div>

                      <div className={`${Styles.quizStat}`}>
                        <h4>Gestartet</h4>
                        <p>
                          {new Intl.DateTimeFormat(i18n.resolvedLanguage, {
                            month: "2-digit",
                            day: "2-digit",
                            year: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          }).format(new Date(quiz.creationDate))}
                        </p>
                      </div>
                    </Row>
                  </Link>
                }
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Quizzes;
