import React from "react";
import { Link } from "react-router-dom";
import Styles from "./FixedButton.module.scss";

const FixedButton = ({ to, text, Icon, disabled, state, handleSubmit }) => {
  return !disabled && to ? (
    <Link
      className={Styles.fixedButton}
      to={{ pathname: to }}
      state={state}
    >
      <Icon /> {text}
    </Link>
  ) : !disabled && !to && handleSubmit ? (
    <button className={Styles.fixedButton} onClick={handleSubmit}>
      <Icon /> {text}
    </button>
  ) : null;
};

export default FixedButton;
