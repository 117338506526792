import React from "react";
import { useAuthState, useAuthDispatch, logout } from "../../Context";
import { useInfiniteQuery, useMutation } from "react-query";
import { Link, useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Layout from "../../Components/Layout/Layout";
import Row from "../../Components/Row/Row";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import { BsTrash } from "react-icons/bs";

import Styles from "./CRUDUsersLicensekeys.module.scss";

import ROOT_URL from "../../Config/rootUrl";

const CRUDUsersLicensekeys = () => {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();
  const [loaded, setLoaded] = React.useState(false);
  const [licensekeyData, setLicensekeyData] = React.useState([]);
  const [modalDeleteLicensekey, setModalDeleteLicensekey] =
    React.useState(null);

  const { id } = useParams();

  const { data, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery(
    ["crud-licensekeys", id],
    async ({ pageParam = 1 }) => {
      const token = userDetails?.token;
      if (!token) return;
      const response = await fetch(
        `${ROOT_URL}/api/v1/crud/users/${id}/licensekeys?page=${pageParam}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseJson = await response.json();
      if (data?.error?.name === "TokenExpiredError") {
        logout(dispatch);
      }
      return responseJson;
    },
    {
      getNextPageParam: (page) =>
        page.currentPage === page.totalPages ? undefined : page.currentPage + 1,
    }
  );

  const dataLength = data?.pages?.reduce(
    (counter, page) => counter + page.entries?.length,
    0
  );

  React.useEffect(() => {
    try {
      const fetchedLicensekeyData = data?.pages
        ?.map((entries) => entries.entries)
        .flat();

      setLoaded(true);
      setLicensekeyData(fetchedLicensekeyData);
    } catch (err) {
      console.log(err);
      return;
    }
  }, [data]);






  const deleteLicensekeyMutation = useMutation(
    async (licensekeyId) =>
      await fetch(`${ROOT_URL}/api/v1/crud/users/${id}/licensekeys/${licensekeyId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${userDetails?.token}`,
        },
      }),
    {
      // After success or failure, refetch the query and set Modal to null
      onSettled: () => {
        setModalDeleteLicensekey(null);
        refetch();
      },
    }
  );







  if (!data || dataLength === undefined || !loaded)
    return (
      <Layout headline={`CRUD USER ID ${id}`}>
        <LoadingSpinner />
      </Layout>
    );

  return (
    <Layout headline={`CRUD USER ID ${id}`}>

      {licensekeyData?.length > 0 ? (
        <InfiniteScroll
          dataLength={dataLength}
          next={fetchNextPage}
          hasMore={hasNextPage}
          loader={dataLength > 0 && <LoadingSpinner positionRelative={true} />}
          className={Styles.container}
        >
          {data.pages.map((group, i, array) => (
            <React.Fragment key={i}></React.Fragment>
          ))}
          <div className={Styles.marginBottom}>
            {licensekeyData?.map((licensekey) => (
              <div key={licensekey.id}>
                <div className={Styles.outerContainer}>
                  <div className={Styles.innerContainer}>
                    <div className={Styles.licensekey}>
                      <h3>{licensekey.key}</h3>
                      <Row>
                        <div className={Styles.licensekeyStat}>
                          <h4>type</h4>
                          <p>{licensekey.type + ""}</p>
                        </div>
                        <div className={Styles.licensekeyStat}>
                          <h4>location</h4>
                          <p>{licensekey.location + ""}</p>
                        </div>
                        <div className={Styles.licensekeyStat}>
                          <h4>consumed</h4>
                          <p>{licensekey.consumed + ""}</p>
                        </div>
                        <button
                          className={`${"small"} ${Styles.delete}`}
                          onClick={() =>
                            setModalDeleteLicensekey({
                              key: licensekey.key + "",
                              type: licensekey.type + "",
                              location: licensekey.location + "",
                              consumed: licensekey.consumed + "",
                              licensekeyId: licensekey.id,
                            })
                          }
                        >
                          Delete
                        </button>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </InfiniteScroll>
      ) : (
        <div className={Styles.noWorkoutContainer}>
          <h1>No License Keys</h1>
          <p>
            <Link to="../crud/users">&lt; Go back to overview</Link>
          </p>
        </div>
      )}

      {modalDeleteLicensekey && (
        <div className={Styles.overlay}>
          <div className={Styles.circle}>
            <BsTrash className={Styles.placeholder} />
          </div>
          <h2>You are about to delete a license key</h2>
          <p>The following license key will be permanently removed:</p>
          <p>
            <span>
              key: {modalDeleteLicensekey.key} <br />
              type: {modalDeleteLicensekey.type} <br />
              location: {modalDeleteLicensekey.location} <br />
              consumed: {modalDeleteLicensekey.consumed} <br />
              id: {modalDeleteLicensekey.licensekeyId} <br />
            </span>
          </p>
          <div className={Styles.buttonGroup}>
            <button onClick={() => setModalDeleteLicensekey(null)}>
              Cancel
            </button>
            <button
              className={Styles.deleteButton}
              onClick={() =>
                deleteLicensekeyMutation.mutate(modalDeleteLicensekey.licensekeyId)
              }
            >
              Delete
            </button>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default CRUDUsersLicensekeys;
