import React from "react";
import { useAuthState, useAuthDispatch, logout } from "../../Context";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

import Layout from "../../Components/Layout/Layout";
import DumbbellIcon from "../../Assets/DumbbellIcon";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import Row from "../../Components/Row/Row";
// import moment from "moment";
import DurationStat from "../../Components/WorkoutStat/DurationStat";
import DualflowScoreStat from "../../Components/WorkoutStat/DualflowScoreStat";
import ScoreStat from "../../Components/WorkoutStat/ScoreStat";
import BodyScoreStat from "../../Components/WorkoutStat/BodyScoreStat";
import BrainScoreStat from "../../Components/WorkoutStat/BrainScoreStat";
import MaxComboStat from "../../Components/WorkoutStat/MaxComboStat";
import HrAverageStat from "../../Components/WorkoutStat/HrAverageStat";
import HrMaxStat from "../../Components/WorkoutStat/HrMaxStat";
import TargetHrStat from "../../Components/WorkoutStat/TargetHrStat";
import Styles from "./WorkoutDetail.module.scss";
import ProgressArcChart from "../../Components/ProgressArcChart/ProgressArcChart";
import WorkoutBurpees from "../../Assets/WorkoutBurpees.svg";
import WorkoutHighTouchRight from "../../Assets/WorkoutHighTouchRight.svg";
import WorkoutPunchRight from "../../Assets/WorkoutPunchRight.svg";
import WorkoutJumps from "../../Assets/WorkoutJumps.svg";
import WorkoutSquats from "../../Assets/WorkoutSquats.svg";
import WorkoutLungeRight from "../../Assets/WorkoutLungeRight.svg";
import WorkoutTripples from "../../Assets/WorkoutTripples.svg";
import WorkoutDetailChart from "../../Components/WorkoutDetailChart/WorkoutDetailChart";

import ROOT_URL from "../../Config/rootUrl";
import { useTranslation } from "react-i18next";

const WorkoutDetail = () => {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();
  const { id } = useParams();
  const [workoutData, setWorkoutData] = React.useState();
  const [exerciseData, setExerciseData] = React.useState();
  const { i18n } = useTranslation();

  const navigate = useNavigate();

  const { data } = useQuery(["workout", id], async () => {
    const token = userDetails?.token;
    if (!token) return;
    const response = await fetch(`${ROOT_URL}/api/v1/workout/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const responseJson = await response.json();
    if (data?.error?.name === "TokenExpiredError") {
      logout(dispatch);
    }
    return responseJson;
  });

  React.useEffect(() => {
    try {
      if (data?.error?.message) {
        navigate("/"); // navigate to home on error -> because error probably from wrong url
      }

      // prepare data for exercises
      const exercises = data?.timelineMarkers?.filter(
        (marker) =>
          marker.name?.includes("exercise") && !marker.name?.includes("pitstop")
      );

      if (exercises?.length > 0) {
        let exercisesArray = [],
          exerciseArray,
          exerciseArrayCorrectlyPerformed;

        data?.workoutPreset?.exercisePool?.map((exercise) => {
          exerciseArray = exercises.filter(
            (ex) => ex.name.includes(exercise.slice(0, -1)) && ex.time !== 0
          );
          exerciseArrayCorrectlyPerformed = exerciseArray.filter(
            (ex) => ex.physicalPrecision > 0
          );

          return exercisesArray.push({
            name: exercise.charAt(0).toUpperCase() + exercise.slice(1),
            totalNumber: exerciseArray.length,
            correctlyPerformed: exerciseArrayCorrectlyPerformed.length,
            correctlyPerformedRate:
              (exerciseArrayCorrectlyPerformed.length / exerciseArray.length) *
                100 || 0,
          });
        });

        setExerciseData(exercisesArray);
      }

      setWorkoutData(data);
    } catch (err) {
      console.log(err);
      return;
    }
  }, [data, navigate]);

  if (!workoutData) return <LoadingSpinner />;

  return (
    <Layout
      headline={workoutData?.workoutPreset?.name}
      HeadlineIcon={DumbbellIcon}
      // subheadline={
      //   workoutData?.updatedAt &&
      //   moment(workoutData.updatedAt).format("dddd, DD.MM.YYYY, hh:mm a")
      // }
      subheadline={
        !!workoutData?.updatedAt &&
        new Intl.DateTimeFormat(i18n.resolvedLanguage, {
          weekday: "long",
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }).format(new Date(workoutData.updatedAt))
      }
    >
      <div style={{ marginBottom: 32 }}>
        <Row>
          <DualflowScoreStat value={workoutData?.dualflowScore} />
          <BodyScoreStat value={workoutData?.bodyScore} />
          <BrainScoreStat value={workoutData?.brainScore} />
        </Row>

        <Row>
          <ScoreStat value={workoutData?.score} />
          <MaxComboStat value={workoutData?.maxCombo} />
          <DurationStat value={workoutData?.measuredDuration} />
        </Row>

        <Row>
          <HrAverageStat value={workoutData?.hrAverage} />
          <HrMaxStat value={workoutData?.hrMax} />
          <TargetHrStat
            type={workoutData.raceConfig?.adaptivityType}
            value={workoutData.raceConfig?.hrTarget}
            maxValue={workoutData.healthData?.hrMax}
          />
        </Row>
      </div>

      {workoutData && (
        <div style={{ marginBottom: 32 }}>
          <WorkoutDetailChart data={workoutData} />
        </div>
      )}

      {/* {exerciseData && (
        <pre style={{ fontSize: 8 }}>
          {JSON.stringify(exerciseData, null, 4)}
        </pre>
      )} */}

      {exerciseData?.length > 0 && (
        <div style={{ marginBottom: 32 }}>
          <h3>Exercises</h3>

          <Row wrap={true} grid={true}>
            {exerciseData.map((exercise) => (
              <div key={exercise.name} className={Styles.exercise}>
                <object
                  className={Styles.svgAnimation}
                  type="image/svg+xml"
                  data={
                    (exercise.name === "Burpees" && WorkoutBurpees) ||
                    (exercise.name === "Punches" && WorkoutPunchRight) ||
                    (exercise.name === "Touches" && WorkoutHighTouchRight) ||
                    (exercise.name === "Jumps" && WorkoutJumps) ||
                    (exercise.name === "Squats" && WorkoutSquats) ||
                    (exercise.name === "Lunges" && WorkoutLungeRight) ||
                    (exercise.name === "Tripples" && WorkoutTripples) ||
                    WorkoutHighTouchRight
                  }
                  aria-label="animation"
                />
                <ProgressArcChart
                  svgWidth={48}
                  arcWidth={2}
                  progressPercentage={exercise.correctlyPerformedRate}
                />
                <p>{exercise.name}</p>
                <p>
                  {exercise.correctlyPerformed}/{exercise.totalNumber}
                </p>
              </div>
            ))}
          </Row>
        </div>
      )}
      {/* <pre style={{ fontSize: 8 }}>{JSON.stringify(workoutData, null, 4)}</pre> */}
    </Layout>
  );
};

export default WorkoutDetail;
