import React from "react";
import { useAuthState, useAuthDispatch, logout } from "../../Context";
import Layout from "../../Components/Layout/Layout";
// import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import ROOT_URL from "../../Config/rootUrl";
import Styles from "./GenerateLicenseKeys.module.scss";

const GenerateLicenseKeys = () => {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();

  const [loading, setLoading] = React.useState(false);
  const [location, setLocation] = React.useState("Select Location");

  const [abbreviation, setAbbreviation] = React.useState("");
  const [quantity, setQuantity] = React.useState(1);
  const [data, setData] = React.useState();

  const [copiedToClipboard, setCopiedToClipboard] = React.useState(false);

  const options = [
    {
      label: "Select Location",
      value: "Select Location",
      value2: "",
    },
    {
      label: "LifeForce Fitness",
      value: "CRAILSHEIM",
      value2: "CRLS",
    },
    {
      label: "Sphery AG",
      value: "ROEMERHOF",
      value2: "ROEM",
    },
    {
      label: "STEP Sports & SPA",
      value: "STUTTGART",
      value2: "STGT",
    },
    {
      label: "Fitness Club Fellbach",
      value: "FELLBACH",
      value2: "FLBC",
    },
    {
      label: "S FIT Kerzers",
      value: "SFIT",
      value2: "SFIT",
    },
    {
      label: "INJOY Fitnessstudio Krems",
      value: "INJOY",
      value2: "INJY",
    },
    {
      label: "Medifit Jena",
      value: "MEDIFIT",
      value2: "MEFI",
    },
    {
      label: "Fit For Life Grafing",
      value: "GRAFING",
      value2: "GRFN",
    },
    {
      label: "Fitnesstreff Niklaus",
      value: "NIKLAUS",
      value2: "NIKL",
    },
    {
      label: "Physio Lounge Werl",
      value: "WERL",
      value2: "WERL",
    },
    {
      label: "Gitty City Stockerau",
      value: "STOCKERAU",
      value2: "STCK",
    },
    {
      label: "Bundesamt BKG",
      value: "BKGD",
      value2: "BKGD",
    },
    {
      label: "Stride Orthopedics",
      value: "KARMIEL",
      value2: "KARL",
    },
    {
      label: "Hospital HZHG",
      value: "HAMBURG",
      value2: "HZHG",
    },
    {
      label: "Ergofit GmbH",
      value: "ERGOFIT",
      value2: "ERGO",
    },
    {
      label: "BAD ZURZACH",
      value: "ZURZACH",
      value2: "ZURZ",
    },
    {
      label: "PLAZA SPORTSCLUB LEONBERG",
      value: "LEONBERG",
      value2: "LEON",
    },
    {
      label: "FITNESSCUBE WARNEMUENDE 1",
      value: "WARNEMUENDE I",
      value2: "WAR1",
    },
    {
      label: "FITNESSCUBE WARNEMUENDE 2",
      value: "WARNEMUENDE II",
      value2: "WAR2",
    },
    {
      label: "SCHULEN",
      value: "SCHULEN",
      value2: "SCLN",
    },
    {
      label: "QUALITY OF LIFE INSTITUTE",
      value: "CHICAGO",
      value2: "CHIC",
    },
    {
      label: "RETURNSPORTS",
      value: "RETURN",
      value2: "MOEN",
    },
  ];

  const handleChangeSelect = (e) => {
    // Workaround to find Value 2 in Options
    const idx = e.target.selectedIndex;
    const option = e.target.querySelectorAll("option")[idx];
    const dataValue2 = option.getAttribute("data-value2");

    setLocation(e.target.value);
    setAbbreviation(dataValue2);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      const token = userDetails?.token;
      if (!token) return;

      const payload = { location, abbreviation, type: "fitIn4Weeks", quantity };

      const response = await fetch(`${ROOT_URL}/api/v1/licensekeys`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (!response.ok) {
        console.log(response);
        console.log(data.error.message);
        return;
      }

      if (data?.error?.name === "TokenExpiredError") {
        logout(dispatch);
      }

      setData(data);
      setLoading(false);
      setLocation("Select Location");
      setAbbreviation("");
      setQuantity(1);
      setCopiedToClipboard(false);
    } catch (error) {
      console.log(error);
    }
  };

  const copyToClipboard = async () => {
    const text = String(data.licensekeys).replace(/,/g, "\n");
    await navigator.clipboard.writeText(text);
    setCopiedToClipboard(true);
  };

  return (
    <Layout headline="Generate License Keys">
      <div className={Styles.container}>
        <form className={Styles.form} onSubmit={handleSubmit}>
          <label htmlFor="location">
            Location*
            <select id="location" value={location} onChange={handleChangeSelect}>
              {options.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  data-value2={option.value2}
                >
                  {option.label}
                </option>
              ))}
            </select>
          </label>

          <label htmlFor="quantity">
            Quantity*
            <input
              type="number"
              id="quantity"
              onChange={(e) => setQuantity(e.target.value)}
              value={quantity}
              min="1"
              max="1000"
            />
          </label>

          <input
            type="submit"
            disabled={loading || !location || !abbreviation}
            value={loading ? "Generating…" : "Generate"}
          />
          {!!data?.success?.message && (
            <div className={Styles.success}>
              <p style={{ marginBottom: "0.5rem" }}>{data.success.message}</p>
              <p style={{ marginBottom: 0 }}>Location: {data.location}</p>
              <p style={{ marginBottom: 0 }}>Abbreviation: {data.abbreviation}</p>
              <p style={{ marginBottom: 0 }}>Type: {data.type}</p>
              <p style={{ marginBottom: 0 }}>Quantity: {data.quantity}</p>
            </div>
          )}
        </form>
      </div>

      {!!data?.licensekeys && (
        <div className={Styles.container}>
          <h3>Generated License Keys</h3>
          <button style={{ width: "100%" }} onClick={() => copyToClipboard()}>Copy</button>
          {copiedToClipboard && (
            <div className={Styles.success}>
              <p style={{ marginTop: "1rem", marginBottom: 0 }}>
                Copied to clipboard!
              </p>
            </div>
          )}
          <pre className={Styles.licenseKeys} id="licensekeys">
            {data.licensekeys.map((key) => (
              <span key={key}>{key}</span>
            ))}
          </pre>
        </div>
      )}

      {/* <div className={Styles.container}>
        <pre style={{ fontSize: 8 }}>{JSON.stringify(data, null, 4)}</pre>
      </div> */}
    </Layout>
  );
};

export default GenerateLicenseKeys;
