import React from "react";
import * as d3 from "d3";
import { useCountUp } from "use-count-up";

const ProgressArcChart = ({ svgWidth, arcWidth, progressPercentage }) => {
  const { value } = useCountUp({
    isCounting: true,
    end: progressPercentage,
    duration: 0.75,
  });

  const svgHeight = svgWidth;
  const arcOuterRadius = svgWidth / 2;
  const arcInnerRadius = svgWidth / 2 - arcWidth;
  const arcGenerator = d3
    .arc()
    .innerRadius(arcInnerRadius)
    .outerRadius(arcOuterRadius)
    .startAngle(0)
    .cornerRadius(5);
  const progressArc = (value) =>
    arcGenerator({
      endAngle: 2 * Math.PI * value,
    });

  return (
    <svg height={svgHeight} width={svgWidth}>
      <g transform={`translate(${svgWidth / 2}, ${svgHeight / 2})`}>
        <path d={progressArc(1)} opacity="0.25" fill="#00d1ff" />
        <path d={progressArc(value / 100)} fill="#00d1ff" />
      </g>
    </svg>
  );
};

export default ProgressArcChart;
