import React from "react";
import { useTranslation } from "react-i18next";
import Styles from "./WorkoutDateSeparator.module.scss";
// import moment from "moment";

const DateSeparator = ({ date }) => {
  const { i18n } = useTranslation();
  return (
    <div className={Styles.dateSeparator}>
      {/* {moment(date).format("dddd, DD.MM.YYYY")} */}
      {new Intl.DateTimeFormat(i18n.resolvedLanguage === "en" ? "en-GB" : i18n.resolvedLanguage, {
        weekday: "long",
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }).format(new Date(date))}
    </div>
  );
};

export default DateSeparator;
