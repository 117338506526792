import React from "react";
import { useAuthState, useAuthDispatch, logout } from "../../Context";
import {
  useQuery,
  useQueryClient,
  useMutation,
  useInfiniteQuery,
} from "react-query";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../Components/Layout/Layout";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import Row from "../../Components/Row/Row";
import { FaUserAlt } from "react-icons/fa";
import FixedButton from "../../Components/FixedButton/FixedButton";
import Challenge from "../../Assets/Challenge.js";
import { CountUp } from "use-count-up";
import ROOT_URL from "../../Config/rootUrl";
import Styles from "./Challenges.module.scss";
import DualflowScoreStat from "../../Components/WorkoutStat/DualflowScoreStat";
import ScoreStat from "../../Components/WorkoutStat/ScoreStat";
import MaxComboStat from "../../Components/WorkoutStat/MaxComboStat";
// import moment from "moment";
import { Trans, useTranslation } from "react-i18next";

const Challenges = () => {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();
  const [loaded, setLoaded] = React.useState(false);
  const [messageData, setMessageData] = React.useState();
  const [selected, setSelected] = React.useState("overview");

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const sentChallengeData = useQuery(["getSentChallenges"], async () => {
    const response = await fetch(`${ROOT_URL}/api/v1/challenge/sent/`, {
      headers: {
        Authorization: `Bearer ${userDetails?.token}`,
      },
    });

    const responseJson = await response.json();
    if (responseJson.data?.error?.name === "TokenExpiredError") {
      logout(dispatch);
    }
    // console.log(responseJson);
    return responseJson;
  });

  const pendingChallengeData = useQuery(["getPendingChallenges"], async () => {
    const response = await fetch(`${ROOT_URL}/api/v1/challenge/pending/`, {
      headers: {
        Authorization: `Bearer ${userDetails?.token}`,
      },
    });

    const responseJson = await response.json();
    if (responseJson.data?.error?.name === "TokenExpiredError") {
      logout(dispatch);
    }
    // console.log(responseJson);
    return responseJson;
  });

  const acceptChallengeMutation = useMutation(
    async (challengeId) =>
      await fetch(`${ROOT_URL}/api/v1/challenge/accept`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${userDetails?.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ challengeId }),
      }),
    {
      // After success or failure, refetch the query
      onSettled: () => {
        queryClient.invalidateQueries(["getPendingChallenges"]);
        queryClient.invalidateQueries(["getChallengesMessages"]);
      },
    }
  );

  const declineChallengeMutation = useMutation(
    async (challengeId) =>
      await fetch(`${ROOT_URL}/api/v1/challenge/decline`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${userDetails?.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ challengeId }),
      }),
    {
      // After success or failure, refetch the query
      onSettled: () => {
        queryClient.invalidateQueries(["getPendingChallenges"]);
        queryClient.invalidateQueries(["getChallengesMessages"]);
      },
    }
  );

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ["getChallengesMessages"],
    async ({ pageParam = 1 }) => {
      const token = userDetails?.token;
      if (!token) return;
      const response = await fetch(
        `${ROOT_URL}/api/v1/message/find_all_challenges_messages/?size=25&page=${pageParam}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseJson = await response.json();
      if (data?.error?.name === "TokenExpiredError") {
        logout(dispatch);
      }
      return responseJson;
    },
    {
      getNextPageParam: (page) =>
        page.currentPage === page.totalPages ? undefined : page.currentPage + 1,
    }
  );

  React.useEffect(() => {
    try {
      const fetchedMessageData = data?.pages
        ?.map((entries) => entries.entries)
        .flat();

      setLoaded(true);
      setMessageData(fetchedMessageData);
    } catch (err) {
      console.log(err);
      return;
    }
  }, [data]);

  const dataLength = data?.pages?.reduce(
    (counter, page) => counter + page.entries?.length,
    0
  );

  React.useEffect(() => {
    selected !== "overview" && navigate(`/challenges-${selected}`);
  }, [selected, navigate]);

  if (
    !pendingChallengeData.data ||
    !sentChallengeData.data ||
    !data ||
    dataLength === undefined ||
    !loaded
  )
    return (
      <Layout headline={t("challenges.headline")}>
        <LoadingSpinner />
      </Layout>
    );

  return (
    <Layout headline={t("challenges.headline")} fixedButtonAtBottom={true}>
      <div className={Styles.challengeSelector}>
        <select
          className={Styles.select}
          multiple={false}
          value={selected}
          onChange={(e) => setSelected(e.target.value)}
        >
          <option value="overview">{t("challenges.overview")}</option>
          <option value="ongoing">{t("challenges.ongoing")}</option>
          <option value="past">{t("challenges.past")}</option>
        </select>
      </div>

      {pendingChallengeData?.data?.length > 0 && (
        <div className={Styles.section}>
          <div className={Styles.header}>
            <h3>{t("challenges.pendingChallenges")}</h3>
          </div>
          <div className={Styles.container}>
            {pendingChallengeData?.data.map((challenge) => (
              <div key={challenge.id} className={Styles.pendingChallenge}>
                <div className={Styles.info}>
                  <div className={Styles.preset}>
                    <p className={Styles.action}>
                      <Trans i18nKey={"challenges.userChallengesYou"}>
                        <span>
                          {{ username: challenge.initiator.username }}
                        </span>
                      </Trans>
                    </p>
                    <h3>
                      {
                        challenge.rounds[0]?.initiatorWorkout?.workoutPreset
                          .name
                      }
                    </h3>
                    <p className={Styles.duration}>
                      <CountUp
                        isCounting
                        end={
                          challenge.rounds[0]?.initiatorWorkout?.raceConfig
                            .duration / 60
                        }
                        duration={0.25}
                        decimalPlaces={0}
                      />{" "}
                      {t("common.minutes", {
                        count:
                          challenge.rounds[0]?.initiatorWorkout?.raceConfig
                            .duration / 60,
                      })}
                    </p>
                  </div>

                  <div className={Styles.user}>
                    <div className={Styles.circle}>
                      {challenge.initiator?.profileImage ? (
                        <img
                          srcSet={`${ROOT_URL}/public/images/${challenge.initiator?.profileImage}-40x40.jpg 40w, ${ROOT_URL}/public/images/${challenge.initiator?.profileImage}-80x80.jpg 80w, ${ROOT_URL}/public/images/${challenge.initiator?.profileImage}-120x120.jpg 120w, ${ROOT_URL}/public/images/${challenge.initiator?.profileImage}-160x160.jpg 160w`}
                          sizes="40px"
                          src={`${ROOT_URL}/public/images/${challenge.initiator?.profileImage}-40x40.jpg`}
                          alt={challenge.initiator?.username}
                        />
                      ) : (
                        <FaUserAlt className={Styles.placeholder} />
                      )}
                    </div>
                  </div>
                </div>

                <hr />
                <Row smallMarginBottom={true}>
                  <div className={Styles.workoutStat}>
                    <DualflowScoreStat
                      value={
                        challenge.rounds[0]?.initiatorWorkout?.dualflowScore
                      }
                    />
                  </div>
                  <div className={Styles.workoutStat}>
                    <ScoreStat
                      value={challenge.rounds[0]?.initiatorWorkout?.score}
                    />
                  </div>
                  <div className={Styles.workoutStat}>
                    <MaxComboStat
                      value={challenge.rounds[0]?.initiatorWorkout?.maxCombo}
                    />
                  </div>
                </Row>

                <Row>
                  <div className={Styles.workoutStat}>
                    <button
                      className={`${Styles.decline} ${"small"}`}
                      onClick={() =>
                        declineChallengeMutation.mutate(challenge.challengeId)
                      }
                    >
                      {t("common.buttons.decline", {
                        context: declineChallengeMutation.isLoading
                          ? "inProgress"
                          : null,
                      })}
                    </button>
                  </div>
                  <div className={Styles.workoutStat}>
                    <Link
                      className={`${Styles.button} ${"small"}`}
                      to={`/workout/${challenge.rounds[0]?.initiatorWorkout?.id}`}
                    >
                      {t("common.buttons.details")}
                    </Link>
                  </div>
                  <div className={Styles.workoutStat}>
                    <button
                      className={`${Styles.accept} ${"small"}`}
                      onClick={() =>
                        acceptChallengeMutation.mutate(challenge.challengeId)
                      }
                    >
                      {t("common.buttons.accept", {
                        context: acceptChallengeMutation.isLoading
                          ? "inProgress"
                          : null,
                      })}
                    </button>
                  </div>
                </Row>
              </div>
            ))}
          </div>
        </div>
      )}

      {sentChallengeData?.data?.length > 0 && (
        <div className={Styles.section}>
          <div className={Styles.header}>
            <h3>{t("challenges.sentChallenges")}</h3>
          </div>
          <div className={Styles.container}>
            {sentChallengeData?.data.map((challenge) => (
              <div key={challenge.id} className={Styles.pendingChallenge}>
                <div className={Styles.info}>
                  <div className={Styles.preset}>
                    <p className={Styles.action}>
                      <Trans i18nKey={"challenges.youChallengeUser"}>
                        <span>
                          {{ username: challenge.opponent?.username }}
                        </span>
                      </Trans>
                    </p>
                    <h3>
                      {
                        challenge.rounds[0]?.initiatorWorkout?.workoutPreset
                          .name
                      }
                    </h3>
                    <p className={Styles.duration}>
                      <CountUp
                        isCounting
                        end={
                          challenge.rounds[0]?.initiatorWorkout?.raceConfig
                            .duration / 60
                        }
                        duration={0.25}
                        decimalPlaces={0}
                      />{" "}
                      {t("common.minutes", {
                        count:
                          challenge.rounds[0]?.initiatorWorkout?.raceConfig
                            .duration / 60,
                      })}
                    </p>
                  </div>

                  <div className={Styles.user}>
                    <div className={Styles.circle}>
                      {challenge.opponent?.profileImage ? (
                        <img
                          srcSet={`${ROOT_URL}/public/images/${challenge.opponent?.profileImage}-40x40.jpg 40w, ${ROOT_URL}/public/images/${challenge.opponent?.profileImage}-80x80.jpg 80w, ${ROOT_URL}/public/images/${challenge.opponent?.profileImage}-120x120.jpg 120w, ${ROOT_URL}/public/images/${challenge.opponent?.profileImage}-160x160.jpg 160w`}
                          sizes="40px"
                          src={`${ROOT_URL}/public/images/${challenge.opponent?.profileImage}-40x40.jpg`}
                          alt={challenge.opponent?.username}
                        />
                      ) : (
                        <FaUserAlt className={Styles.placeholder} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {messageData && messageData.length > 0 && (
        <div className={Styles.section}>
          <div className={Styles.header}>
            <h3>{t("challenges.challengeLog")}</h3>
          </div>
          <InfiniteScroll
            dataLength={dataLength}
            next={fetchNextPage}
            hasMore={hasNextPage}
            loader={
              dataLength > 0 && <LoadingSpinner positionRelative={true} />
            }
            className={Styles.container}
          >
            {data.pages.map((group, i, array) => (
              <React.Fragment key={i}></React.Fragment>
            ))}

            {messageData.map((message) => (
              <div key={message.id} className={Styles.pendingChallenge}>
                <div className={Styles.message}>
                  <div className={Styles.messageContent}>
                    {!!message.body && (
                      <p dangerouslySetInnerHTML={{ __html: message.body }} />
                    )}
                    <p className={Styles.dateSeparator}>
                      {new Intl.DateTimeFormat(i18n.resolvedLanguage === "en" ? "en-GB" : i18n.resolvedLanguage, {
                        weekday: "long",
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      }).format(new Date(message.createdAt))}
                      {/* {moment(message.createdAt).format("dddd, DD.MM.YYYY")} */}
                    </p>
                  </div>

                  <div className={Styles.circle}>
                    {message.sender.profileImage ? (
                      <img
                        srcSet={`${ROOT_URL}/public/images/${message.sender.profileImage}-40x40.jpg 40w, ${ROOT_URL}/public/images/${message.sender.profileImage}-80x80.jpg 80w, ${ROOT_URL}/public/images/${message.sender.profileImage}-120x120.jpg 120w, ${ROOT_URL}/public/images/${message.sender.profileImage}-160x160.jpg 160w`}
                        sizes="40px"
                        src={`${ROOT_URL}/public/images/${message.sender.profileImage}-40x40.jpg`}
                        alt={message.sender.username}
                      />
                    ) : (
                      <FaUserAlt className={Styles.placeholder} />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </div>
      )}

      <FixedButton
        to="/challenges-past-workouts"
        text={t("common.buttons.sendChallenge")}
        Icon={Challenge}
      />
    </Layout>
  );
};
export default Challenges;
